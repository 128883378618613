<div class="business-type">
  <form [formGroup]="form" tabindex="0">
    <div class="row">
      <div *ngIf="errorMessage" class="col-sm-12 form-error-message error-message">
        {{ errorMessage }}
      </div>

      <div class="col-sm-12 mb-3 position-relative ps-0">
        <input
          [formControl]="searchMCC"
          class="form-control"
          id="search-mmc"
          type="text"
          [class.business-type-error]="form?.invalid && form?.touched"
        />
        <label class="form-label" for="search-mmc">{{ label }}</label>
        <app-field-error [control]="searchMCC"></app-field-error>
      </div>

      <div
        *ngIf="(mccOptions && mccOptions.length && searchMCC.value)"
        class="col-sm-12 mb-3 position-relative ps-0"
        id="business-type-results-container"
      >
        <div class="business-type-results-padding">
          <div
            class="business-type-results"
            [ngClass]="{ 'business-type-results-hide-overflow': mccOptions.length <= 5 }"
            (keydown.space)="businessTypeController.preventScroll($event)"
          >
            <div
              tabindex="0"
              (keyup.enter)="selectOption(option)"
              (keyup.space)="selectOption(option)"
              (click)="selectOption(option)"
              *ngFor="let option of mccOptions; let index = index"
              [ngClass]="{ 'business-type-is-selected': mcc.value === option.mcc, 'not-selectable' : option.mcc === null }"
              class="business-type-mcc-container-padding"
            >
              <div class="business-type-mcc-container">
                <div class="business-type-description">{{ option.description }}</div>
                <div class="business-type-industry">{{ option.industryDescription }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-sm-12 mb-3 position-relative right-inner-addon"
        [hidden]="!store.showMccDescription || hideMccDescription"
      >
        <span>
          <app-tg-tooltip
            [tooltipText]="'Provide a description of the service your business provides'"
          ></app-tg-tooltip>
        </span>
        <input
          id="mccAdditionalDetails"
          formControlName="mccAdditionalDetails"
          class="form-control"
          type="text"
          autocomplete="on"
        />

        <label class="form-label" for="mccAdditionalDetails"> Description </label>
        <app-field-error [control]="mccAdditionalDetails"></app-field-error>
      </div>
    </div>
  </form>
</div>
