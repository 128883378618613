<div id="product-detail-image" [ngClass]="{ 'always-show-controls': !showThumbnails }">
  <div class="image">
    <carousel
      [interval]="false"
      [startFromIndex]="0"
      [showIndicators]="false"
      [itemsPerSlide]="1"
      [(activeSlide)]="selectedIndex"
    >
      <slide *ngFor="let slide of largeImageUrls">
        <img [alt]="'Image of ' + alt" class="img-fluid image-main" [src]="slide" />
      </slide>
    </carousel>
  </div>
  <div class="row d-none d-md-flex" *ngIf="showThumbnails">
    <div class="col-sm-12">
      <div class="row">
        <div *ngFor="let imageUrl of thumbImageUrls; let index = index" class="col-md-4 col-lg-3">
          <div class="image">
            <button class="navigation-link thumbnail-button" (click)="imageShown(index)">
              <img
                [alt]="alt"
                class="img-thumbnail image-thumbnail"
                [ngClass]="{ 'is-selected': isSelected(index) }"
                src="{{ imageUrl }}"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
