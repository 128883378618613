import { Injectable } from '@angular/core';
import { makeObservable } from 'mobx';
import { action, observable } from 'mobx-angular';

@Injectable({
  providedIn: 'root',
})
export class TimeoutErrorService {
  @observable isOpen = false;

  constructor() {
    makeObservable(this);
  }

  @action public show(): void {
    this.isOpen = true;
  }

  @action public hide(): void {
    this.isOpen = false;
  }
}
