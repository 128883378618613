<form [formGroup]="form" class="mt-3">
  <div class="row">
    <input [id]="id" formControlName="firstName" class="form-control" type="hidden" />
    <input [id]="id + 1" formControlName="lastName" class="form-control" type="hidden" />
    <input [id]="id + 2" formControlName="companyName" class="form-control" type="hidden" />
  </div>

  <app-tg-address
    formGroupName="personalAddress"
    [address]="shippingLocation.address"
    [isAssisted]="isAssisted"
  ></app-tg-address>

  <div class="row">
    <div *ngIf="errorMessage" class="col-sm-12 form-error-message error-message">
      {{ errorMessage }}
    </div>
  </div>
</form>
