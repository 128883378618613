<app-container *mobxAutorun>
  <app-application-page
    [index]="1"
    [subIndex]="0"
    [validate]="true"
    [text]="messages.softwareSlide"
    [showCart]="true"
    (prev)="prev()"
    (next)="next()"
  >
    <app-software #innerSlide (prev)="prev()" (next)="next()"></app-software>
  </app-application-page>
</app-container>
