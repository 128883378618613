import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private isLoadingSubject = new BehaviorSubject(false);

  isLoading = this.isLoadingSubject.asObservable();

  totalCount = 0;

  setLoading(loading: boolean) {
    if (loading) this.totalCount++;
    else this.totalCount--;
    this.isLoadingSubject.next(this.totalCount > 0);
  }
}
