import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const UI_SWITCH_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
  useExisting: forwardRef(() => InputSwitchV2Component),
  multi: true,
};

@Component({
  selector: 'app-input-switch-v2',
  templateUrl: './input-switch-v2.component.html',
  styleUrls: ['./input-switch-v2.component.scss'],
  providers: [UI_SWITCH_CONTROL_VALUE_ACCESSOR],
})
export class InputSwitchV2Component implements ControlValueAccessor {
  private _checked: boolean;

  private _disabled: boolean;

  @Input() set checked(v: boolean) {
    this._checked = v;
  }

  @Input() set disabled(v: boolean) {
    this._disabled = v;
  }

  @Output() valueChanged = new EventEmitter<boolean>();

  get checked() {
    return this._checked;
  }

  get disabled() {
    return this._disabled;
  }

  onToggle({ target }: Event) {
    if (this.disabled) {
      return;
    }
    this.checked = (target as HTMLInputElement).checked;
    this.valueChanged.emit(this.checked);
  }

  writeValue(obj: any): void {
    if (obj !== this.checked) {
      this.checked = !!obj;
    }
  }

  registerOnChange() {}

  registerOnTouched() {}
}
