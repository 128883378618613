/* eslint-disable @typescript-eslint/indent */
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormCarouselSlide } from '@eventhorizon/components/form-carousel-slide';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { ApplicationService } from '@eventhorizon/services/application.service';
import { when } from 'mobx';
import { allowScrollInBody, removeModalIfPresent } from '@eventhorizon/utils/util';
import { GeneralActionPopUpComponent } from '@eventhorizon/components/general-action-popup/general-action-popup.component';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { ProductService } from '@eventhorizon/services/product.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';
import { commonErrors, constants, IMPALink, MPA_LINKS, statuses } from '@eventhorizon/constants/general.constants';
import { MpaDocuments } from '@eventhorizon/models/documents.model';
import { AcquiringTypes } from '@eventhorizon/models/product.model';
import { DDAInterestStatus } from '@eventhorizon/models/application-status.model';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';
import { ApplicationPageComponent } from '../application-page/application-page.component';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { StatusPopupComponent } from '../status-popup/status-popup.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { PartnerStore } from '@eventhorizon/stores/partner.store';
import { GeneralActionModalComponent } from '@eventhorizon/components/general-action-modal/general-action-modal.component';
import { SignersDropdownComponent } from '@eventhorizon/components/signers-dropdown/signers-dropdown.component';

@Component({
  selector: 'app-tg-terms-and-conditions',
  templateUrl: './base-terms-and-conditions.component.html',
  styleUrls: ['./base-terms-and-conditions.component.scss'],
})
export class BaseTermsAndConditionsComponent extends FormCarouselSlide implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('signerDropdown')
  public signerDropdown: SignersDropdownComponent;

  @ViewChild('preSubmitRef') preSubmitRef: TemplateRef<any>;

  errorMessage: string[];

  isLoading = true;

  fileSrc: Blob;

  documents: MpaDocuments;

  shouldShow = false;

  isICPlus = false;

  swipedLinks = MPA_LINKS.swiped;

  icPlusLinks = MPA_LINKS.icPlus;

  signerDisclaimer = 'The signer of the agreement is also agreeing to serve as the personal guarantor for the business.';

  private bankInfo: any;

  agreementPageConfig: any;

  ddaInterestDisclosure: SafeHtml;

  ddaInterestDisclosureHidden: boolean;

  ddaInterestStatus: MobxFormControl;

  signerInfo: MobxFormControl;

  firstName: string;

  lastName: string;

  email: string;

  options: any;

  contact: any;

  businessOwners: any;

  constructor(
    protected cd: ChangeDetectorRef,
    protected bsModalService: BsModalService,
    private fb: UntypedFormBuilder,
    protected appStore: ApplicationStore,
    protected cartStore: CartStore,
    protected appService: ApplicationService,
    protected productService: ProductService,
    public appPageComponent: ApplicationPageComponent,
    private contentService: ContentServiceService,
    private sanitizer: DomSanitizer,
    public partnerStore: PartnerStore,
  ) {
    super(bsModalService, cd);
    this.initWhens();
    this.bankInfo = this.contentService?.bankInfoConfig;
    this.agreementPageConfig = this.bankInfo?.agreementPageConfig;
  }

  private initWhens(): void {
    when(
      () => this.appStore.isLoaded && !!this.cartStore.cart,
      () => {
        this.isICPlusTransaction();
      },
    );

    when(
      () => !!this.partnerStore.partner,
      () => {
        this.ddaInterestDisclosureHidden = !this.partnerStore.isDDALanguageEnabled;
      },
    );
  }

  private handleFirstAutorun() {
    this.displayStatusModal();
    try {
      this.getMpa(this.appStore.id, this.appStore.hasMultipleDocuments);
    } catch (error) {
      this.errorMessage = ['Unable to load Terms & Conditions at this time.'];
      setTimeout(() => {
        removeModalIfPresent(this.bsModalService);
      }, 1000); //Adds tiemout to allow the modal to be displayed before attempting to remove
      allowScrollInBody();
      this.isLoading = false;
    }
  }

  private displayStatusModal() {
    if (this.appStore.submitAttempts >= constants.ONBOARDING_MAX_SUBMISSIONS) return;
    const initialState = {
      title: this.agreementPageConfig?.modalTitle || 'Generating Agreement',
      subtitle:
        this.agreementPageConfig?.modalText ||
        'Wait a moment while we pull together your merchant processing agreement.',
    };
    this.bsModalService.show(StatusPopupComponent, {
      initialState,
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }

  ngOnInit() {
    this.loadSigners();
    this.buildForm();
    this.cartStore.loadCart().subscribe();
    this.getDdaInterestDisclosure();
    this.handleFirstAutorun();
  }

  ngAfterViewInit() {
    if (this.signerDropdown.form) {
      this.form.setControl('signerInfo', this.signerDropdown.form);
      this.form.addControl('signerInfo', this.signerDropdown.form);
      this.signerDropdown.form.setParent(this.form);
    }
  }

  private buildForm() {
    this.ddaInterestStatus = new MobxFormControl(
      'ddaInterestStatus',
      () =>
        this.appStore.status.ddaInterestId === DDAInterestStatus.UNKNOWN
          ? false
          : this.appStore.status.ddaInterestId === DDAInterestStatus.YES,
      v => (this.appStore.status.ddaInterestId = v ? DDAInterestStatus.YES : DDAInterestStatus.NO),
    );

    this.form = this.fb.group({
      accept: [{ value: '', disabled: true }, Validators.required],
      ddaInterestStatus: this.ddaInterestStatus,
      signerInfo: [],
    });
  }

  handleDataFromSignerForm(data) {
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.form.controls.accept.reset();
  }

  public getMpa(applicationId: string, hasMultipleDocuments: boolean) {
    this.appService.getMpa(applicationId, hasMultipleDocuments).subscribe(
      (response: Blob | MpaDocuments) => {
        this.showMpa(response);
        this.form.get('accept').enable();
        removeModalIfPresent(this.bsModalService);
        allowScrollInBody();
      },
      () => {
        this.errorMessage = ['Unable to load Terms & Conditions at this time.'];
        removeModalIfPresent(this.bsModalService);
        allowScrollInBody();
        this.isLoading = false;
      },
    );
  }

  public showMpa(response: any) {
    this.fileSrc = response;
    this.isLoading = false;
    allowScrollInBody();
  }

  private loadSigners() {
    this.appService.getSigners(this.appStore.id, true).subscribe({
      next: result => {
        this.options = result;
        this.businessOwners = result.businessOwners;
        this.contact = result.contactInfo;
      },
      error: () => {
        this.errorMessage = ['Failed to load signers'];
      },
    });
  }

  private async handleApplicationRetry() {
    await lastValueFrom(this.appStore.loadApplication());
    removeModalIfPresent(this.bsModalService);
    if (this.appStore.submitAttempts < constants.ONBOARDING_MAX_SUBMISSIONS) {
      const initialState = {
        title: 'Oops! Something went wrong!',
        subtitle: 'We encountered an error while trying to submit your application. Please try again.',
        action: 'Try again',
      };
      this.bsModalService.show(GeneralActionPopUpComponent, {
        initialState,
        ariaLabelledBy: 'modal-title modal-subtitle',
      });
    }
  }

  private async submitApplication() {
    try {
      if (
        this.appStore.status &&
        this.appStore.status.internalOrderStatus &&
        (this.appStore.status.internalOrderStatus.id === statuses.InProgress ||
          this.appStore.status.internalOrderStatus.id === statuses.OffRamped)
      ) {
        await lastValueFrom(this.appStore.submitFinalApplication());
      }
      return true;
    } catch (e) {
      this.handleErrors(e);
      await this.handleApplicationRetry();
      return false;
    }
  }

  private handleErrors(e) {
    if (e && e.error && e.error.errors) {
      this.errorMessage = [];
      Object.keys(e.error.errors).forEach(key => {
        if (typeof e.error.errors[key] === 'string') {
          this.errorMessage.push(e.error.errors[key]);
        } else {
          this.errorMessage.push(...e.error.errors[key]);
        }
      });
    } else if (e) {
      this.errorMessage = ['Unable to submit the application at this time.'];
    }
  }

  public async save(): Promise<boolean> {
    this.errorMessage = undefined;

    try {
      await lastValueFrom(this.cartStore.cartCheckout(this.appStore.id));
      await lastValueFrom(
        this.appService.saveTerms(this.appStore.id, this.firstName + ' ' + this.lastName, this.email),
      );
      return await this.submitApplication();
    } catch (e) {
      this.handleErrors(e);
      removeModalIfPresent(this.bsModalService);
      if (e.error.statusCode === 403) {
        this.appPageComponent.hasSubmitError = true;
      } else {
        this.errorMessage = [commonErrors.failedToSaveInfo];
      }
      return false;
    }
  }

  public async preOnNext(): Promise<boolean> {
    const modalRef = this.bsModalService.show(GeneralActionModalComponent, {
      initialState: {
        title: 'Are you sure you\’re ready to submit?',
        content: this.preSubmitRef,
        actionButtons: [
          {
            label: 'Make Changes',
            class: 'xup-secondary-button',
          },
          {         
            label: 'Submit',
            class: 'xup-primary-button',
          },
        ],
      },
      class: 'medium-modal',
    });

    let preSubmitResponse = await firstValueFrom(modalRef.content.onClose);
    if (preSubmitResponse && preSubmitResponse === 'Submit') {
      const initialState = {
        title: 'Submitting Application',
        subtitle: 'Wait a moment while we submit your application and generate your order ID.',
      };
      setTimeout(() => {
        this.bsModalService.show(StatusPopupComponent, {
          initialState,
          backdrop: 'static',
          ariaLabelledBy: 'modal-title modal-subtitle',
        });
      }, 500);
      return this.save();
    } else {
      return false;
    }
  }

  public async onSecondaryAction() {
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }

  private isICPlusTransaction() {
    if (this.appStore.isLoaded && this.cartStore.transactionProducts) {
      this.productService.getAcquiringTypes(this.appStore.partnerId).subscribe((resp: AcquiringTypes[]) => {
        const swipedFiservId = resp.find(type => type.productId === '10040');
        const icplus = resp.find(type => type.productId === '10011');
        this.isICPlus = icplus
          ? !!icplus.paymentTypes.find(type =>
              this.cartStore.transactionProducts.find(product => product.productId === type.productId),
            )
          : false;
        this.shouldShow = swipedFiservId ? true : this.isICPlus;
      });
    }
  }

  public async updateDdaInterestDisclosure(): Promise<void> {
    const newStatus = this.ddaInterestStatus.value ? DDAInterestStatus.YES : DDAInterestStatus.NO;
    await this.appStore.updateDdaInterestDisclosure(this.appStore.id, newStatus);
  }

  private getDdaInterestDisclosure(): void {
    const { ddaInterestDisclosure } = this.contentService.bankInfoConfig;
    this.ddaInterestDisclosure = this.sanitizer.bypassSecurityTrustHtml(ddaInterestDisclosure);
  }

  get mpaLinks(): IMPALink[] {
    return this.isICPlus ? this.icPlusLinks : this.swipedLinks;
  }

  public ngOnDestroy(): void {
    removeModalIfPresent(this.bsModalService);
  }
}
