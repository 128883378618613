<div id="content-page-container" class="content-page" *ngIf="page" [style.background-image]="pageBackground">
  <div id="page-rows" *ngIf="page?.rows">
    <!-- Section Row -->
    <div
      class="row"
      *ngFor="let row of page?.rows"
      [class.no-gutters]="row?.isFullWidth"
      [class.content-page-section]="!row?.isFullWidth"
      [class.has-background]="row?.hasBackgroundColor"
      [style.padding]="row?.padding"
      [style.background-image]="row?.rowBackgroundImage ? 'url(' + row.rowBackgroundImage + ')' : ''"
      [class.hide-background-mobile]="row?.hideBackgroundForMobile"
    >
      <!-- Subheading -->
      <div *ngIf="row?.headerText" class="col-12">
        <h2 class="content-page-subheading-primary text-center">
          {{ row?.headerText }}
        </h2>
      </div>
      <!-- Columns -->
      <div
        class="content-page-column col-12"
        *ngFor="let col of row?.columns"
        [class.content-page-column-padding]="!col.isFullWidth"
        [class.col-lg]="!col.isFullWidth"
        [style.padding]="col.padding"
        [class.col-md-auto]="col.isCard"
        [class.has-borders]="row?.hasColumnBorders"
      >
        <div [class.content-page-card]="col.isCard" [class.has-background]="col.hasBackgroundColor">
          <app-content-loader *ngFor="let content of col.content" [content]="content"></app-content-loader>
        </div>
      </div>
      <div class="col-md-12 text-center">
        <app-tg-cta-button
          *ngIf="(row?.ctaAction || row?.ctaUrl) && row?.ctaDisplayText"
          [ctaAction]="row.ctaAction"
          [ctaDisplayText]="row.ctaDisplayText"
          [url]="row.ctaUrl"
          [removeButtonRadius]="row.removeButtonRadius"
        ></app-tg-cta-button>
      </div>
    </div>
  </div>
</div>
