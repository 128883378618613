<app-container *mobxAutorun>
  <app-application-page
    [index]="3"
    [subIndex]="1"
    [text]="messages.verificationSlide"
    (prev)="prev()"
    (next)="next()"
  >
    <app-tg-verification-quiz #innerSlide (prev)="prev()" (next)="next()"></app-tg-verification-quiz>
  </app-application-page>
</app-container>
