<a
  class="product"
  (click)="selectProduct()"
  (keydown.space)="$event.preventDefault(); selectProduct()"
  (keydown.enter)="selectProduct()"
  tabindex="0"
>
  <div class="product-image-wrapper">
    <img
      [alt]="product?.productName"
      class="product-image"
      [src]="product?.imageUrls && product.imageUrls?.length ? product.imageUrls[0] : defaultImage"
      aria-hidden="false"
    />
  </div>
  <div class="product-name-wrapper">
    <span class="row product-name">{{ product?.name | truncate: 50:'...' }}</span>
    <span *ngIf="product.required" class="row product-required">Required Product</span>
  </div>
  <span class="product-price">
    <span>{{ product?.price | currency }}</span>
  </span>
  <span *ngIf="pricingModel()" class="product-lease">
    or from
    <span class="product-lease-cost">${{ pricingModel().price }}/mo</span>
  </span>
</a>
