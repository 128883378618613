import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TimeoutErrorService } from '@eventhorizon/services/timeout-error.service';
import { HelpCenterPopupComponent } from '@eventhorizon/components/help-center-popup/help-center-popup.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-error-timeout',
  templateUrl: 'error-timeout.component.html',
  styleUrls: ['error-timeout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorTimeoutComponent {
  constructor(protected readonly timeOutSrv: TimeoutErrorService, private readonly bsModalService: BsModalService) {}

  refresh(): void {
    window.location.reload();
  }

  open(): void {
    this.bsModalService.show(HelpCenterPopupComponent, {
      class: 'modal-lg',
      ariaLabelledBy: 'help-center',
    });
  }
}
