<form [formGroup]="form" #formElem>
  <fieldset class="row selectable-card-group mb-3 position-relative">
    <div
      *ngFor="let subcategory of subCategories; let index = index"
      class="col-sm-12 col-md-6 selectable-card subcategory-selectable-card"
      (keyup.enter)="selectSubcategory(subcategory.id)"
      [ngClass]="{ 'is-selected': isSelectedSubcategory(index) }"
      (click)="selectSubcategory(subcategory.id)"
    >
      <input
        type="radio"
        [id]="index"
        formControlName="subCategory"
        [value]="subcategory.id"
        class="selectable-card-input"
      />
      <label
        [for]="index"
        class="selectable-card-label"
        [class.selectable-card-label-error]="form?.invalid && form?.touched"
      >
        <span class="selectable-card-text">{{ subcategory.name }}</span>
        <div class="selectable-card-subtext">{{ subcategory.description }}</div>
      </label>
    </div>

    <div *ngIf="errorMessage" class="col-12 form-error-message">
      <p>{{ errorMessage }}</p>
    </div>
  </fieldset>
</form>
