import { Component } from '@angular/core';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';

@Component({
  selector: 'app-error-server-down',
  templateUrl: './error-server-down.component.html',
  styleUrls: ['./error-server-down.component.scss'],
})
export class ErrorServerDownComponent {
  constructor(public contentService: ContentServiceService) {}
}
