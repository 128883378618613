import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(protected http: HttpClient, @Inject('env') private environment) {}

  public excelToJson(files: any): Observable<ExcelToJsonResponse> {
    return this.postFiles('/api/documents/excelToJson', files);
  }

  public downloadDocument(documentId: number): Observable<Blob> {
    return this.http.get<Blob>(`${this.environment.api.url}/documents/business-location-documents/${documentId}`, {
      responseType: 'blob' as 'json',
    });
  }

  private postFiles(uri: string, files: File[]): Observable<any> {
    if (files.length === 0) {
      return;
    }

    const formData = new FormData();

    for (const file of files) {
      formData.append(file.name, file);
    }

    return this.http.post<ExcelToJsonResponse>(uri, formData);
  }
}

export interface ExcelJsonDocument extends ExcelJsonDocumentRow {
  name: string;
  header: { key: number; value: ExcelJsonDocumentCol };
  rows: { key: number; value: ExcelJsonDocumentRow };
}

export interface ExcelJsonDocumentRow extends ExcelJsonDocumentCol {
  cols: { key: number; value: ExcelJsonDocumentCol };
}

export interface ExcelJsonDocumentCol {
  idx: number;
  value: string;
}

export interface ExcelToJsonResponse {
  excelJsonDocuments: ExcelJsonDocument[];
}
