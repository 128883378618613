<div class="container" *mobxAutorun>
  <div class="content-page" *ngIf="partnerLoaded">
    <!-- Partner section -->
    <div class="row content-page-section has-background">
      <div class="col-lg-6 col-sm-12">
        <div class="content-page-partner">
          <div class="content-page-partner-logo">
            <p class="referred-by-text">Referred by</p>
            <img
              *ngIf="partnerLogo"
              class="content-page-partner-image-logo"
              [src]="partnerLogo"
              alt="{{ partnerName }} logo"
            />
            <h2 *ngIf="!partnerLogo" class="content-page-partner-text-logo">{{ partnerName }}</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="row col-lg-6 col-sm-12 align-self-start">
          <div class="content-page-services col-lg-11 col-sm-12">
            <div class="content-page-border"></div>
            <h1 *ngIf="!isHBABranding && !isPMTABranding" class="content-page-heading">
              Get Started with Key Merchant Services
            </h1>
            <h1 *ngIf="isHBABranding && !isPMTABranding" class="content-page-heading">
              Let's Keep Building Cleveland.<br />Together.
            </h1>
            <h1 *ngIf="!isHBABranding && isPMTABranding" class="content-page-heading">
              We're There For You.<br />Mile After Mile.
            </h1>
            <p *ngIf="!isHBABranding && !isPMTABranding" class="content-page-text-primary">
              KeyBank offers secure, scalable and cost-effective payment solutions for businesses of all types and sizes
              with Key Merchant Services.
            </p>
            <p *ngIf="isHBABranding && !isPMTABranding" class="content-page-text-primary">
              As a member of HBA of Greater Cleveland, you have access to an exclusive merchant processing opportunity
              from your hometown bank including:
            </p>
            <p *ngIf="!isHBABranding && isPMTABranding" class="content-page-text-primary">
              As a member of PMTA, you have access to an exclusive merchant processing opportunity including:
            </p>
          </div>
          <div *ngIf="!isHBABranding && !isPMTABranding" class="content-page-list">
            <p class="content-page-text-strong">Benefits of Key Merchant Services:</p>
            <div class="content-page-list-item">
              <em class="fa fa-credit-card content-page-list-item-icon"></em>
              <div class="content-page-list-details col-lg-8 col-sm-12">
                <p class="content-page-text-strong no-margin">Accept Payments Anywhere</p>
                <p class="content-page-text-secondary no-margin">
                  Process credit and debit card payments with solutions tailored to your industry
                </p>
              </div>
            </div>

            <div class="content-page-list-item">
              <em class="fa fa-sack-dollar content-page-list-item-icon"></em>
              <div class="content-page-list-details col-lg-8 col-sm-12">
                <p class="content-page-text-strong no-margin">Next-day Funding<sup>1</sup></p>
                <p class="content-page-text-secondary no-margin">
                  Improve your cashflow with fast access to funds as soon as the next business day
                </p>
              </div>
            </div>

            <div class="content-page-list-item">
              <em class="fa fa-user-headset content-page-list-item-icon"></em>
              <div class="content-page-list-details col-lg-8 col-sm-12">
                <p class="content-page-text-strong no-margin">Exceptional Support</p>
                <p class="content-page-text-secondary no-margin">
                  Get 24/7 service wherever you need it via phone, email, or chat
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="isHBABranding && !isPMTABranding" class="content-page-list">
            <div class="content-page-list-item">
              <em class="fa fa-envelope-open-dollar content-page-list-item-icon"></em>
              <div class="content-page-list-details col-lg-8 col-sm-12">
                <p class="content-page-text-strong no-margin">$300 Statement Credit<sup>1</sup></p>
                <p class="content-page-text-secondary no-margin">
                  Receive a $300 statement credit<sup>1</sup> and save up to 30%<sup>2</sup> on processing payments.
                </p>
              </div>
            </div>

            <div class="content-page-list-item">
              <em class="fa fa-search-dollar content-page-list-item-icon"></em>
              <div class="content-page-list-details col-lg-8 col-sm-12">
                <p class="content-page-text-strong no-margin">No More Hidden Fees</p>
                <p class="content-page-text-secondary no-margin">Say goodbye to hidden fees and surprise surcharges.</p>
              </div>
            </div>

            <div class="content-page-list-item">
              <em class="fa fa-user-headset content-page-list-item-icon"></em>
              <div class="content-page-list-details col-lg-8 col-sm-12">
                <p class="content-page-text-strong no-margin">Exceptional Support</p>
                <p class="content-page-text-secondary no-margin">
                  Receive responsive customer service 24/7 via phone, email, or chat.
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="!isHBABranding && isPMTABranding" class="content-page-list">
            <div class="content-page-list-item">
              <em class="fa fa-piggy-bank content-page-list-item-icon"></em>
              <div class="content-page-list-details col-lg-8 col-sm-12">
                <p class="content-page-text-strong no-margin">Card Processing Savings</p>
                <p class="content-page-text-secondary no-margin">
                  PMTA members will receive a $300 statement credit<sup>1</sup> and could save up to 30%<sup>2</sup> on
                  card processing.
                </p>
              </div>
            </div>

            <div class="content-page-list-item">
              <em class="fa fa-search-dollar content-page-list-item-icon"></em>
              <div class="content-page-list-details col-lg-8 col-sm-12">
                <p class="content-page-text-strong no-margin">No More Hidden Fees</p>
                <p class="content-page-text-secondary no-margin">Say goodbye to hidden fees and surprise surcharges.</p>
              </div>
            </div>

            <div class="content-page-list-item">
              <em class="fa fa-user-headset content-page-list-item-icon"></em>
              <div class="content-page-list-details col-lg-8 col-sm-12">
                <p class="content-page-text-strong no-margin">Exceptional Support</p>
                <p class="content-page-text-secondary no-margin">
                  Dependable customer service, available to you 24/7 via phone, email, or chat.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row col-lg-6 col-sm-12">
          <div class="content-page-card col-lg-9 col-sm-12 mx-auto">
            <ng-container [ngSwitch]="contactFormStatus">
              <ng-container *ngSwitchCase="'initial'">
                <div class="content-page-card-section">
                  <p class="content-page-card-heading">Work with an expert</p>
                  <p *ngIf="!isHBABranding && !isPMTABranding" class="content-page-text-primary">
                    Work with a client specialist to answer any questions and set you up with a custom solution for your
                    business needs.
                  </p>
                  <p *ngIf="isHBABranding && !isPMTABranding" class="content-page-text-primary">
                    Connect with a client specialist to learn how to take advantage of HBA of Greater Cleveland
                    processing rates.
                  </p>
                  <p *ngIf="!isHBABranding && isPMTABranding" class="content-page-text-primary">
                    Connect with a client specialist to learn how to take advantage of PMTA processing rates.
                  </p>
                  <button (click)="showContactForm()" class="primary-button">get in touch</button>
                </div>
                <div
                  *ngIf="!isHBABranding && !isPMTABranding && !disablePartnerSelfOnboarding"
                  class="content-page-card-section"
                >
                  <p class="content-page-card-heading">FIND MY SOLUTION</p>
                  <p class="content-page-text-primary">
                    Discover the best solution for your business and complete your merchant account application in
                    minutes.
                  </p>
                  <button [routerLink]="['/contact-info/']" class="primary-button">get started</button>
                </div>
              </ng-container>
              <div *ngSwitchCase="'show'">
                <div class="content-page-card-section" *ngIf="!loading">
                  <app-referred-merchant-contact-form
                    [errorMessage]="errorMessage"
                    [partnerType]="partnerType"
                    (resetForm)="resetContactFormStatus()"
                    [disableCaptcha]="disableCaptcha"
                    (submitForm)="submitContactForm()"
                  ></app-referred-merchant-contact-form>
                </div>
              </div>
              <div *ngIf="loading" class="col-12 text-center">
                <div class="pb-4 pt-4">Submitting call back request</div>
                <div class="lds-dual-ring"></div>
              </div>
              <div class="content-page-card-section" *ngSwitchCase="'success'">
                <i class="fa fa-check content-page-card-icon"></i>
                <p class="content-page-card-heading">Thank you!</p>
                <p class="content-page-text-primary">
                  A Key Merchant Services team member will contact you within 24 hours to help determine the best
                  product and pricing options for your business.
                </p>
                <p class="content-page-text-primary">
                  In the meantime, if you have any questions please call us at <br />
                  <a
                    class="content-page-text-strong accent-color"
                    href="tel:{{ contentService?.bankInfoConfig?.phoneNumber }}"
                    >{{ contentService?.bankInfoConfig?.phoneNumber }}</a
                  >.
                </p>
                <p *ngIf="contentService?.bankInfoConfig?.weekdayPhoneHours" class="content-page-text-primary">
                  Our team is available {{ contentService?.bankInfoConfig?.weekdayPhoneHours }} for calls
                  <span *ngIf="contentService?.bankInfoConfig?.chatHours">
                    and {{ contentService?.bankInfoConfig?.chatHours }} via chat.
                  </span>
                </p>
                <button (click)="resetContactFormStatus()" class="primary-button">Return</button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- Description section -->
    <div class="row content-page-section">
      <div class="row align-items-center col-12">
        <app-tg-slide-carousel
          *ngIf="partnerContent"
          [itemsPerSlide]="partnerContent.carousel?.itemsPerSlide"
          [slides]="partnerContent.carousel?.slides"
          [linkClickable]="true"
          [showLinkArrow]="false"
          [showDescription]="false"
        ></app-tg-slide-carousel>
      </div>
    </div>

    <div *ngIf="!isHBABranding && !isPMTABranding" class="row content-page-footer">
      <p class="content-page-text-primary no-margin">
        <sup>1</sup>Subject to applicable cut-off times. Next day deposits are only available to a KeyBank checking or
        savings account.
      </p>
    </div>
    <div *ngIf="isHBABranding || isPMTABranding" class="row content-page-footer">
      <p class="content-page-text-primary no-margin fst-italic">
        <sup>1</sup>$300 offer is applied as a statement credit to your new Merchant Services account 60 days after
        account activation. Limit one $300 offer per new merchant relationship. Activation is defined as processing and
        settling $20.01 to your primary merchant DDA. Activation starts the day of the first KeyBank Merchant Services
        transaction. If no processing fees or conversion costs are incurred, credit will remain and be applied after
        initial fees or conversion costs are incurred. This offer is nontransferable and not valid with any other
        offers. The terms of this offer are subject to change or cancellation at any time, for any reason. Exclude
        Clover Web & Phone Payments & Clover Go.
      </p>
    </div>
    <div *ngIf="isHBABranding || isPMTABranding" class="row content-page-footer">
      <p class="content-page-text-primary no-margin fst-italic">
        <sup>2</sup>Actual savings will vary based on average transaction size, transaction volume, and types of cards
        accepted. Banking products and services are provided by KeyBank National Association. All credit, loans, lines
        of credit, and merchant services are subject to credit approval, lines of credit and merchant services are
        subject to credit approval, terms of service and any applicable collateral requirements.
      </p>
    </div>
    <div *ngIf="isHBABranding || isPMTABranding" class="row content-page-footer">
      <p class="content-page-text-primary no-margin fst-italic">
        Key.com is a federally registered service mark of KeyCorp. © 2022. KeyCorp. All rights reserved. KeyBank is
        Member FDIC. 220531-1594779
      </p>
    </div>
  </div>
</div>
