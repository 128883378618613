import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @Input() label?: string = 'Search';

  @Input() hideLabel?: boolean = false;

  @Input() removeDebounce?: boolean = false;

  @Input() searchTooltip?: string;

  @Input() tooltipPlacement?: string;

  @Output() valueChanged = new EventEmitter();

  searchControl: UntypedFormControl = new UntypedFormControl();

  private sub: Subscription;

  ngOnInit() {
    if (this.removeDebounce) {
      this.sub = this.searchControl.valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
        this.valueChanged.emit(val);
      });
    } else {
      this.sub = this.searchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(val => {
        this.valueChanged.emit(val);
      });
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  clearSearch() {
    this.searchControl.reset('');
  }
}
