<app-container *mobxAutorun>
  <app-application-page
    [index]="3"
    [subIndex]="0"
    [text]="messages.shippingLocationSlide"
    (prev)="prev()"
    (next)="next()"
  >
    <app-tg-product-shipping-locations #innerSlide (prev)="prev()" (next)="next()"></app-tg-product-shipping-locations>
  </app-application-page>
</app-container>
