<div class="search-input-group">
  <ng-container *ngIf="searchTooltip">
    <input
      id="searchInput"
      type="text"
      class="form-control search-input"
      [formControl]="searchControl"
      [tooltip]="searchTooltip"
      [placement]="tooltipPlacement"
    />
  </ng-container>
  <ng-container *ngIf="!searchTooltip">
    <input id="searchInput" type="text" class="form-control search-input" [formControl]="searchControl" />
  </ng-container>
  <label *ngIf="!hideLabel" class="form-label" for="searchInput">{{ label }}</label>
  <i class="fal fa-search search-input-icon"></i>
  <button
    data-testid="clear-search-btn"
    type="button"
    *ngIf="searchControl.value"
    class="btn search-input-clear"
    (click)="clearSearch()"
  >
    <i class="fal fa-times"></i>
  </button>
</div>
