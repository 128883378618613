<div class="tool-tip">
  <i
    *ngIf="!hasContent"
    class="far fa-circle-question tool-tip-icon"
    [tooltip]="tooltipText"
    placement="top"
    container="body"
    [containerClass]="isInsideAnotherContainer ? 'dark-tooltip' : 'clear-tooltip'"
  ></i>
  <span
    #content
    [tooltip]="tooltipText"
    triggers="mouseenter:mouseleave"
    placement="top"
    [container]="isBodyContainer ? 'body' : ''"
    [containerClass]="isInsideAnotherContainer ? 'dark-tooltip' : 'clear-tooltip'"
  >
    <ng-content></ng-content>
  </span>
</div>
