import { Component } from '@angular/core';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';

@Component({
  selector: 'app-call-back',
  templateUrl: './call-back.component.html',
  styleUrls: ['./call-back.component.scss'],
})
export class CallbackComponent {
  constructor(public contentService: ContentServiceService) {}

  public learnMore() {
    window.open(this.contentService.bankInfoConfig.returnLink);
  }
}
