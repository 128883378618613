import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePageDriverComponent } from '@app/pages/base-page-driver/base-page-driver.component';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { routes } from '@eventhorizon/data/routes.data';
import { messages } from '@eventhorizon/data/messages.data';

@Component({
  selector: 'app-locations-screen',
  templateUrl: './locations-screen.component.html',
  styleUrls: ['./locations-screen.component.scss'],
})
export class LocationsScreenComponent extends BasePageDriverComponent {
  public text = messages.locationsSlide;

  public get isInitialState() {
    return this.store?.businessLocations?.length === 1 
      && this.store?.businessLocations[0]?.progressCode === 0
      && this.store?.businessLocations[0]?.name === null;
  }

  constructor(
    public store: ApplicationStore,
    protected route: ActivatedRoute,
    protected router: Router,
    protected rs: RoutingPathService,
  ) {
    super(rs, router, route);

    rs.deactivateRoute(routes.LOCATION_INFO);
    rs.deactivateRoute(routes.LOCATION_PRODUCTS);
    rs.deactivateRoute(routes.LOCATION_ADDONS);

    if (this.isInitialState) {
      this.text.smallHeadline = this.text.smallHeadlineInitialState;
    }
  }
}
