<app-accordion>
  <app-accordion-group [(isOpen)]="accordionConfig.showYourApp" class="mb-2">
    <span accordion-heading>
      <div class="accordion-header btn btn-link">
        {{ HEADINGS.APPLICATION }}
      </div>
    </span>
    <app-your-app
      *ngIf="accordionConfig.showYourApp"
      (itemClickAction)="itemClick($event)"
      [applicationId]="applicationId"
    ></app-your-app>
  </app-accordion-group>

  <app-accordion-group [(isOpen)]="accordionConfig.showYourSolution" class="mb-2">
    <span accordion-heading>
      <div class="accordion-header btn btn-link">
        {{ HEADINGS.YOUR_PRODUCTS }}
      </div>
    </span>
    <app-your-solution *ngIf="accordionConfig.showYourSolution" [applicationId]="applicationId"></app-your-solution>
  </app-accordion-group>

  <app-accordion-group *ngIf="!hideVerification" [(isOpen)]="accordionConfig.showBussinessVerification">
    <span accordion-heading>
      <div id="business-verification-header" class="accordion-header btn btn-link">
        {{ HEADINGS.BUSS_VERIFICATION }}
      </div>
    </span>
    <app-bussiness-verification
      [applicationId]="applicationId"
      *ngIf="accordionConfig.showBussinessVerification"
    ></app-bussiness-verification>
  </app-accordion-group>
</app-accordion>
