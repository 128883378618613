<div class="row no-gutters w-100 multiple-selection-dropdown">
  <div class="col-12 btn-group" dropdown [autoClose]="false" [isOpen]="true">
    <button dropdownToggle type="button" class="primary-button dropdown-toggle">
      <input
        type="text"
        class="multiple-selection-dropdown-search"
        (keyup)="search()"
        [(ngModel)]="searchString"
        placeholder="Type to Search"
      />
      <span class="caret"></span>
    </button>
    <br />
    <ul *dropdownMenu class="dropdown-menu stay-open" role="menu" aria-labelledby="button-basic">
      <li
        role="menuitem"
        *ngFor="let option of filteredDropdownOptions"
        (click)="optionClick.emit(option.id)"
        class="multiple-selection-dropdown-option p-2"
      >
        <input type="checkbox" [id]="'dropdown-' + option.id" [checked]="isOptionSelected(option)" />
        <label [for]="'dropdown-' + option.id" class="ms-3">{{ option.value }}</label>
      </li>
    </ul>
  </div>
  <div class="col-12 no-gutters mt-3">
    <app-chips [elements]="selectedOptions" (valueChanged)="removeElement.emit($event)"></app-chips>
  </div>
</div>
