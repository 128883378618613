<form [formGroup]="form" #formElem>
  <fieldset class="row">
    <div *ngFor="let category of categories; let index = index" class="col-sm-12 col-md-6 col-lg-4">
      <div
        class="category-selectable-card"
        id="category-selectable-card-{{ category.id }}"
        (keyup.enter)="select(category.id)"
        [ngClass]="{ 'is-selected': isSelected(index) }"
        (click)="select(category.id)"
      >
        <input
          type="radio"
          [id]="index"
          formControlName="category"
          [value]="category.id"
          class="selectable-card-input"
        />
        <label
          [for]="index"
          class="selectable-card-label"
          [class.selectable-card-label-error]="form?.invalid && form?.touched"
        >
          <i class="{{ category.fa ? category.fa : 'fal fa-star' }} selectable-card-icon"></i>
          <span class="selectable-card-text">{{ category.name }}</span>
          <div class="selectable-card-subtext">{{ category.description }}</div>
        </label>
      </div>
    </div>

    <div *ngIf="errorMessage" class="col-12 form-error-message">
      <p>{{ errorMessage }}</p>
    </div>
  </fieldset>
</form>
