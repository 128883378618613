import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgForm, UntypedFormBuilder, Validators } from '@angular/forms';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { FormCarouselSlide } from '@eventhorizon/components/form-carousel-slide';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { OrganizationService } from '@eventhorizon/services/organization.service';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { IReactionDisposer, reaction } from 'mobx';
import { LocationsService } from '@eventhorizon/services/locations.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddressComponent } from '@eventhorizon/components/address/address.component';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';
import { masks } from '@eventhorizon/data/masks.data';
import { organizationTypes } from '@eventhorizon/data/organization-types.data';
import { brandDatePicker } from '@eventhorizon/data/date-picker-config';
import { Address } from '@eventhorizon/models/address.model';
import { formatErrorAsList } from '@eventhorizon/utils/format-errors';
import { onDateFieldKeyChangePatchValue, formatPhone, randomComponentId } from '@eventhorizon/utils/util';
import { CommonValidator } from '@eventhorizon/validation/common.validator';
import { BusinessNameValidator } from '@eventhorizon/validation/business-name.validator';
import { debounceTime, firstValueFrom, lastValueFrom, Subscription } from 'rxjs';
import { BaseBusinessAddressesComponent } from '@eventhorizon/components/base-business-addresses/base-business-addresses.component';
import { PaymentInfoController } from '@eventhorizon/controllers/payment-info.controller';
import { PaymentInfoComponent } from '../payment-info/payment-info.component';
import { PaymentInfoErrorService } from '@eventhorizon/services/payment-info-error.service';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { HighTicketValueValidator } from '@eventhorizon/validation/high-ticket-value.validator';
import { progressCodes } from '@eventhorizon/data/progress-codes.data';
import { GeneralActionModalComponent } from '@eventhorizon/components/general-action-modal/general-action-modal.component';

@Component({
  selector: 'app-tg-business-info',
  templateUrl: './base-business-info.component.html',
  styleUrls: ['./base-business-info.component.scss'],
})
export class BaseBusinessInfoComponent extends FormCarouselSlide implements OnInit, AfterViewInit, OnDestroy {
  public id = randomComponentId();

  public today = new Date();

  public masks = masks;

  public brandDatePicker = brandDatePicker;

  public onDateFieldKeyChangePatchValue = onDateFieldKeyChangePatchValue;

  public errorMessage: string[] = [];

  public isLoading = false;

  public showLocationsAddresses = false;

  public legalName: MobxFormControl;

  public taxName: MobxFormControl;

  public businessPhone: MobxFormControl;

  public businessEmail: MobxFormControl;

  public website: MobxFormControl;

  public startDate: MobxFormControl;

  public numBOs: MobxFormControl;

  public tin: MobxFormControl;

  public minDateOfIncorporation: Date;

  public websiteRequired: boolean = null;

  @Input() public displayLocationDBA: boolean = true;

  @ViewChild('legalAddress')
  public legalAddress: AddressComponent;

  @ViewChild('locationAddresses')
  public locationAddresses: BaseBusinessAddressesComponent;

  @ViewChild('paymentInfo')
  public paymentInfo: PaymentInfoComponent;

  @ViewChild('regForm', { static: false }) regForm: NgForm;

  public selectedAddressTab = 0;

  public autoPopulatedFields = new Set(['legalName', 'businessPhone', 'businessEmail', 'website', 'startDate']);

  @ViewChild('focus') public focus: ElementRef = undefined;

  @ViewChild('preSubmitRef') preSubmitRef: TemplateRef<any>;

  public bankInfoConfig: Record<string, object | string>;

  private disposers: IReactionDisposer[] = [];

  protected formChangeSubscription: Subscription;

  isValid;

  constructor(
    public store: ApplicationStore,
    public cd: ChangeDetectorRef,
    protected fb: UntypedFormBuilder,
    protected bsModalService: BsModalService,
    protected organizationService: OrganizationService,
    protected routingService: RoutingPathService,
    protected locationsService: LocationsService,
    public contentService: ContentServiceService,
    protected paymentChannelStore: PaymentInfoController,
    private paymentInfoErrorService: PaymentInfoErrorService,
    protected cartStore: CartStore,
  ) {
    super(bsModalService, cd);
    this.showLocationsAddresses = routingService.isRouteActive('locations');
    this.disposers.push(
      reaction(
        () => !!this.store.isLoaded,
        () => {
          this.setBusinessLocations();
        },
      ),
      reaction(
        () => cartStore.cart && cartStore.cart.products,
        () => {
          this.paymentChannelStore.getReferralRequiresAmexMemberId();
          if (this.paymentChannelStore.referralRequiresAmexMemberId || this.paymentChannelStore.hasAmexDirect) {
            this.validateAmexDirect();
          }
        },
      ),

      reaction(
        () => this.store.mcc,
        (mcc: string) => {
          this.paymentInfo.mcc = mcc;
          this.form.get('averageTicketValue').setValidators(this.paymentChannelStore.getAverageTicketValidators());
        },
      ),
    );
  }

  public get showAmexAnnualSales(): boolean {
    return this.store.showAmexAnnualSales;
  }

  public get showAmexMemberId(): boolean {
    return this.store.showAmexMemberId || this.paymentChannelStore.referralRequiresAmexMemberId;
  }

  ngOnInit() {
    this.bankInfoConfig = this.contentService.bankInfoConfig || null;

    this.buildForm();
    this.hideBasedOnTinType();
    this.setBusinessLocations();
  }

  ngAfterViewInit() {
    if (this.locationAddresses) {
      this.form.setControl('locationAddresses', this.locationAddresses?.form);
    }
    this.form.setControl('legalAddress', this.legalAddress.form);
    this.form.setControl('paymentInfo', this.paymentInfo.form);

    this.form.get('averageTicketValue').setValidators(this.paymentChannelStore.getAverageTicketValidators());
    this.form.get('amexAnnualVolume').setValidators([HighTicketValueValidator.higherThanZero('amex-cc-low')]);

    this.cd.detectChanges();
  }

  private isSoleProprietor(): boolean {
    if (
      !this.store.businessInfo ||
      !this.store.businessInfo.organizationType ||
      this.store.businessInfo.organizationType.code !== organizationTypes.soleProprietor.code
    ) {
      return false;
    }

    return true;
  }

  public isNextDisabled(): boolean {
    return !this.form.valid || !this.isValid;
  }

  public onOpen() {
    super.onOpen();
  }

  public initialFocus() {
    if (this.focus) {
      this.focus.nativeElement.focus();
    }
  }

  private validateAmexDirect() {
    this.paymentInfo.amexMemberId.setEnabled(true);
    this.form
      .get('amexMemberId')
      .setValidators(this.paymentChannelStore.getAmexMemberValidators(true, false, this.form.controls.amexMemberId));
  }

  public buildForm() {
    const s = this.store;
    const businessSalesControls = this.paymentChannelStore.buildForm(true);

    this.legalName = new MobxFormControl(
      'legalName',
      () => s.businessInfo.legalName,
      v => {
        s.businessInfo.legalName = v;
        s.businessInfo.taxFilingName = v;
      },
      BusinessNameValidator.legalName(),
    );

    this.businessPhone = new MobxFormControl(
      'businessPhone',
      () => formatPhone(s.businessInfo.phone),
      v => (s.businessInfo.phone = v),
      CommonValidator.phone(),
    );
    this.businessEmail = new MobxFormControl(
      'businessEmail',
      () => s.businessInfo.email,
      v => (s.businessInfo.email = v),
      CommonValidator.email(),
    );
    this.website = new MobxFormControl(
      'website',
      () => s.businessInfo.website,
      v => (s.businessInfo.website = v),
      Validators.compose([Validators.required, CommonValidator.website]),
    );

    this.minDateOfIncorporation = new Date();
    this.minDateOfIncorporation.setFullYear(this.minDateOfIncorporation.getFullYear() - 200);
    this.startDate = new MobxFormControl(
      'startDate',
      () => s.businessInfo.incDate,
      v => {
        s.businessInfo.incDate = v;
        const timestamp = Date.parse(v);
        if (!isNaN(timestamp)) s.businessInfo.startDate = new Date(timestamp);
      },
      CommonValidator.dateInPastWithin200Years(),
    );

    this.tin = new MobxFormControl(
      'tin',
      () => s.businessInfo.taxId,
      v => {
        s.businessInfo.taxId = v;
        s.businessInfo.taxIdConfirmation = v;
      },
      CommonValidator.tinAlt(),
    );

    this.form = this.fb.group({
      legalName: this.legalName,
      businessPhone: this.businessPhone,
      businessEmail: this.businessEmail,
      website: this.website,
      startDate: this.startDate,
      tin: this.tin,
      legalAddress: ['', []],
      ...businessSalesControls,
    });

    this.initializeWebsiteRequirements();

    this.formChangeSubscription = this.form.valueChanges
      .pipe(debounceTime(0))
      .subscribe(
        (change: {
          annualCardVolume: number;
          averageTicketValue: number;
          highTicketValue: number;
          totalAnnualSales: number;
          amexMemberId: number;
        }) => {
          this.paymentInfoErrorService.handlePaymentInformationErrors(change, this.form);
          if (this.regForm.form.controls.averageTicketValue.errors) {
            this.isValid = false;
          } else {
            this.isValid = true;
          }

          this.cd.detectChanges();
        },
      );
  }

  public initializeWebsiteRequirements() {
    if (this.store?.businessInfo?.website) {
      this.websiteRequired = true;
    } else {
      // upon first visit on the bsn info page, set website required to null so user can deliberately choose
      // if website is empty pass the progress of this point, the user has chosen to not have a website
      this.websiteRequired = this.store?.status?.progressCode <= progressCodes.BUSINESS_INFO ? null : false;
      this.form.get('website').disable();
    }
  }

  public setWebsiteRequirements(required: boolean) {
    this.websiteRequired = required;
    const websiteControl = this.form.get('website');
    if (this.websiteRequired) {
      websiteControl.enable();
    } else {
      websiteControl.setValue(null);
      websiteControl.disable();
    }
  }

  public async onSecondaryAction() {
    if (this.form.valid) {
      await this.save();
    }
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }

  public async save(): Promise<boolean> {
    this.errorMessage = undefined;

    // The onboarding page doesn't ask for the tax name, but it is required
    if (this.store.businessInfo.legalName) {
      this.store.businessInfo.taxFilingName = this.store.businessInfo.legalName;
    } else if (this.store.businessInfo.dbaName) {
      this.store.businessInfo.taxFilingName = this.store.businessInfo.dbaName;
    }

    if (this.websiteRequired === false && this.store.businessLocations.find(x => x.transactionDistribution?.internet)) {

      const modalRef = this.bsModalService.show(GeneralActionModalComponent, {
        initialState: {
          title: 'This change requires action.',
          content: this.preSubmitRef,
          actionButtons: [
            {
              label: 'Never Mind',
              class: 'xup-secondary-button',
            },
            {         
              label: 'Proceed',
              class: 'xup-primary-button',
            },
          ],
        },
        class: 'medium-modal',
      });
  
      let preSubmitResponse = await firstValueFrom(modalRef.content.onClose);
      if (preSubmitResponse && preSubmitResponse === 'Proceed') {
        this.store.businessLocations.forEach(x => {
          const channels = x.transactionDistribution;
          if (channels?.internet) {
            for (let channel of Object.keys(channels)) {
              channels[channel] = 0;
            }
          }
        });
      } else {
        return false;
      }
    }

    try {
      await lastValueFrom(
        this.store.updateSubmerchant(
          this.locationsService.currentLocationId,
          this.store.businessLocations[this.locationsService.currentLocationIndex],
        ),
      );
      await lastValueFrom(this.store.saveBusinessInformation());
      return true;
    } catch (error) {
      this.errorMessage = formatErrorAsList(error);
      return false;
    }
  }

  private fillBusinessLocations() {
    if (this.store.businessLocations.length > 1) {
      this.store.businessLocations.slice(1).forEach(location => {
        location.address = this.store.businessLocations[0].address;
      });
    }
  }

  public async preOnNext(): Promise<boolean> {
    this.fillBusinessLocations();
    return this.save();
  }

  hideBasedOnTinType(): boolean {
    if (this.store.businessInfo.tinType === 0 && !this.store.businessInfo.taxId) {
      this.form.controls.tin.disable();
      this.form.controls.legalName.disable();
      return true;
    }
  }

  public updateFormValidity() {
    Object.keys(this.form.controls).forEach(key => {
      if (this.autoPopulatedFields.has(key)) {
        this.form.controls[key].markAsTouched();
        this.form.controls[key].markAsDirty();
      }
    });
  }

  public setBusinessLocations() {
    if (
      this.store.businessLocations &&
      this.store.businessLocations.length > 0 &&
      !this.isSoleProprietor() &&
      this.showLocationsAddresses
    ) {
      this.store.businessLocations.forEach(businessLocation => {
        if (businessLocation.address === null) businessLocation.address = new Address();
      });
    }
  }

  ngOnDestroy(): void {
    for (const disposer of this.disposers) {
      if (disposer) disposer();
    }
  }
}
