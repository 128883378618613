<div class="raf-notification-modal pt-3 pb-2">
  <h1 class="mb-4">
    <i class="fa-solid fa-circle-exclamation danger"></i> Rates and fees have been updated since your application was
    saved.
  </h1>
  <p *ngIf="!hasError" class="mb-5">Please review the rates and fees in your cart to see any changes made.</p>
  <p *ngIf="hasError" class="mb-5 danger">Something went wrong.</p>
  <button [disabled]="isLoading || hasError" class="primary-button" (click)="confirmChanges()">
    <span *ngIf="!isLoading">OK</span>
    <i class="fas fa-spinner-third fa-spin" *ngIf="isLoading"></i>
  </button>
</div>
