import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ProductDetailPopupComponent } from '@app/pages/product-detail/product-detail-popup/product-detail-popup.component';
import { SoftwareDetailsModalComponent } from '@app/pages/software-screen/software/software-details-modal/software-details-modal.component';
import { CarouselSlide } from '@eventhorizon/components/carousel-slide';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';
import { routes } from '@eventhorizon/data/routes.data';
import { Cart } from '@eventhorizon/models/cart.model';
import { Product } from '@eventhorizon/models/product.model';
import { ProductService } from '@eventhorizon/services/product.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map, take } from 'rxjs';

@Component({
  selector: 'app-tg-location-addons',
  templateUrl: './location-addons.component.html',
  styleUrls: ['./location-addons.component.scss'],
})
export class LocationAddonsComponent extends CarouselSlide implements OnChanges {

  @Input()
  public cart: Cart;

  @Output() 
  public openCart = new EventEmitter();

  public isLoading: boolean = true;

  public showPinpadSection: boolean = false;

  public showSoftwareSection: boolean = false;

  protected softwares: Product[] = [];

  protected pinpads: Product[] = [];

  constructor(
    protected cd: ChangeDetectorRef,
    protected productService: ProductService,
    protected cartStore: CartStore,
    protected bsModalService: BsModalService,
    public applicationStore: ApplicationStore,
    public router: Router,
  ) {
    super(cd);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cart.currentValue) {

      // if there is no errors or sofware, skip ahead
      if (this.cart.errors.length === 0 && !this.cartStore.hasSoftware(this.cart)) this.onNext();

      // when we have more software offering types, we need to have a way to identify between each
      // for now, any software errors will be for clover software
      const softwareError =  this.cart?.errors.find(e => e.errortype.toLocaleLowerCase().includes('software'));
      this.showSoftwareSection = !!softwareError || this.cartStore.hasSoftware(this.cart);
      if (this.showSoftwareSection) {
        this.loadSoftware('clover-software');
      }

      // keep the pinpad section if already true, prevents section from disappearaing after adding pinpad to cart
      this.showPinpadSection = this.showPinpadSection || this.cart?.errors.some(x => x.errortype.toLowerCase().includes('pinpad'));
      if (this.showPinpadSection) {
        this.loadPinpad();
      }

      if (!this.showPinpadSection && !this.showSoftwareSection) {
        this.router.navigate([routes.LOCATION_PRODUCTS], { queryParamsHandling: 'preserve' });
      }
    }
  }

  private loadSoftware(offeringType: string) {
    const locationId = this.cart?.locationId;
    if (!locationId) {
      console.error('Unable to load software');
      this.isLoading = false;
      return;
    }

    this.productService.getSoftwareProducts(offeringType, this.applicationStore.id, locationId).subscribe(result => {
      this.softwares = result;
    });
  }

  private loadPinpad() {
    this.productService.getProductsByOfferingType('pinpad').subscribe(result => {
      this.pinpads = result;
    });
  }

  public parseSoftwarePricingDescription(product: Product) {
    return `$${product.price.toFixed(2)}/mo, +${Number(product.pricePerAdditionalDevice).toFixed(2)}/mo for each additional device`;
  }

  public parsePinpadPricingDescription(product: Product) {
    let installment = product.pricingModel.find(p => p.paymentType === 'Installment');
    return installment ? `Starting from $${installment.minimumPrice.toFixed(2)}/mo` : '';
  }

  public isProductSelected(product: Product) {
    return this.cart?.products.some(x => x.productId === product.productId);
  }

  showDetailsModal(product: Product) {
    const modalRef: BsModalRef = this.bsModalService.show(SoftwareDetailsModalComponent, {
      ariaLabelledBy: 'modal-title',
      initialState: {
        title: product.name || product.productName,
        price: product.price,
        description: product.productLongDescription,
        priceDescription: product.pricePerAdditionalDevice,
        disableButton: this.isProductSelected(product),
        cart: this.cart,
        productId: product.productId,
      },
    });

    modalRef?.onHidden
      .pipe(
        map(() => {
          const cartUpdated: boolean = modalRef.content?.cartUpdated;
          if (cartUpdated) {
            this.cart = modalRef.content?.cart;
            this.openCart.emit();
          }
        }),
        take(1),
      )
      .subscribe();
  }

  showPinpadDetailsModal(product: Product) {
    const modalRef = this.bsModalService.show(ProductDetailPopupComponent, {
      class: 'product-modal',
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
      initialState: {
        cart: this.cart,
        productId: product.productId,
      },
    });
    modalRef?.onHidden
      .pipe(
        map(() => {
          if (modalRef.content?.showCart) {
            this.cart = modalRef.content?.cart;
            this.openCart.emit();
          } 
        }),
        take(1),
      )
      .subscribe();
  }

  public isNextDisabled(): boolean {
    return !!this.cart?.errors?.length;
  }

  public onSecondaryAction() {
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }
}