<app-container *mobxAutorun>
  <app-application-page
    [index]="2"
    [subIndex]="2"
    [text]="messages.bankInformationSlide"
    [showCart]="true"
    (prev)="prev()"
    (next)="next()"
  >
    <app-tg-bank-info #innerSlide (prev)="prev()" (next)="next()"></app-tg-bank-info>
  </app-application-page>
</app-container>
