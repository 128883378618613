import { ProductDetailImagesComponent } from '@eventhorizon/components/product-detail-images/product-detail-images.component';
import { RatesFeesComponent } from '@eventhorizon/components/transactions/rates-fees-component/rates-fees.component';
import { TransactionsComponent } from '@eventhorizon/components/transactions/transactions.component';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { RouterModule } from '@angular/router';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha-2';
import { MerchantSummaryComponent } from '@eventhorizon/components/merchant-summary/merchant-summary.component';
import { YourAppComponent } from '@eventhorizon/components/merchant-summary/your-app/your-app.component';
import { YourSolutionComponent } from '@eventhorizon/components/merchant-summary/your-solution/your-solution.component';
import { BusinessVerificationComponent } from '@eventhorizon/components/merchant-summary/business-verification/business-verification.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CurrencyPipe, DatePipe, CommonModule } from '@angular/common';
import { GeneralActionPopUpComponent } from '@eventhorizon/components/general-action-popup/general-action-popup.component';
import { GeneralActionModalComponent } from '@eventhorizon/components/general-action-modal/general-action-modal.component';
import { SecurityPipe } from '@eventhorizon/pipes/security.pipe';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { RadioCardComponent } from '@eventhorizon/components/radio-card/radio-card.component';
import { CartSummaryComponent } from '@eventhorizon/components/cart/cart-summary/cart-summary.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AlertModule } from 'ngx-bootstrap/alert';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { AddressComponent } from '@eventhorizon/components/address/address.component';
import { FieldErrorComponent } from '@eventhorizon/components/field-error/field-error.component';
import { InputSpinnerComponent } from '@eventhorizon/components/input-spinner/input-spinner.component';
import { TooltipComponent } from '@eventhorizon/components/tooltip/tooltip.component';
import { BaseOwnerComponent } from '@eventhorizon/components/base-owner/base-owner.component';
import { BankerPopupComponent } from '@eventhorizon/components/banker-popup/banker-popup.component';
import { OfframpComponent } from '@eventhorizon/components/off-ramp/off-ramp.component';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';
import { YodleePopupComponent } from '@eventhorizon/components/yodlee-popup/yodlee-popup.component';
import { BusinessTypeComponent } from '@eventhorizon/components/business-type/business-type.component';
import { CartPopupComponent } from '@eventhorizon/components/cart/cart-popup/cart-popup.component';
import { CartComponent } from '@eventhorizon/components/cart/cart.component';
import { CartIconComponent } from '@eventhorizon/components/cart/cart-icon/cart-icon.component';
import { CartProductComponent } from '@eventhorizon/components/cart/cart-product/cart-product.component';
import { ShippingLocationComponent } from '@eventhorizon/components/shipping-location/shipping-location.component';
import { SignersDropdownComponent } from '@eventhorizon/components/signers-dropdown/signers-dropdown.component';
import { DisableControlDirective } from './directives/disable-control.directive';
import { TruncatePipe } from './pipes/truncate.pipe';
import { BosInformationComponent } from '@eventhorizon/components/merchant-summary/business-verification/bos-information/bos-information.component';
import { HelpCenterPopupComponent } from '@eventhorizon/components/help-center-popup/help-center-popup.component';
import { InputSwitchComponent } from '@eventhorizon/components/input-switch/input-switch.component';
import { SearchBarComponent } from '@eventhorizon/components/search-bar/search-bar.component';
import { ZipCodePipe } from './pipes/zip-code.pipe';
import { WindowProvider } from './injection-keys/injection-keys';
import { DateFilterComponent } from '@eventhorizon/components/date-filter/date-filter.component';
import { BusinessLocationComponent } from './components/business-location/business-location.component';
import { BaseBusinessAddressesComponent } from './components/base-business-addresses/base-business-addresses.component';
import { InputSwitchV2Component } from '@eventhorizon/components/input-switch-v2/input-switch-v2.component';
import { DropdownFieldComponent } from './components/dropdown-field/dropdown-field.component';
import { AccordionComponent } from '@eventhorizon/components/accordion/accordion.component';
import { AccordionGroupComponent } from '@eventhorizon/components/accordion/accordion-group/accordion-group.component';
import { RatesAndFeesNotificationComponent } from '@eventhorizon/components/rates-and-fees-notification/rates-and-fees-notification.component';
import { LocationBusinessTypeComponent } from '@eventhorizon/components/location-business-type/location-business-type.component';
import { MaskitoDirective } from '@maskito/angular';
import { CalloutNotificationComponent } from './components/callout-notification/callout-notification.component';
import { LocationsListComponent } from './components/locations-list/locations-list.component';
import { CartProductsComponent } from './components/cart/cart-products/cart-products.component';
import { SendApplicationModalComponent } from './components/send-application-modal/send-application-modal.component';
import { BadgeComponent } from './components/badge/badge.component';
@NgModule({
  declarations: [
    BusinessLocationComponent,
    BaseBusinessAddressesComponent,
    BaseOwnerComponent,
    AddressComponent,
    FieldErrorComponent,
    InputSpinnerComponent,
    TooltipComponent,
    BankerPopupComponent,
    SavePopupComponent,
    YodleePopupComponent,
    BusinessTypeComponent,
    CartIconComponent,
    CartComponent,
    CartPopupComponent,
    OfframpComponent,
    CartProductComponent,
    CalloutNotificationComponent,
    ShippingLocationComponent,
    SignersDropdownComponent,
    DisableControlDirective,
    MerchantSummaryComponent,
    YourAppComponent,
    YourSolutionComponent,
    BusinessVerificationComponent,
    ProductDetailImagesComponent,
    SecurityPipe,
    GeneralActionPopUpComponent,
    GeneralActionModalComponent,
    TruncatePipe,
    RadioCardComponent,
    CartSummaryComponent,
    BosInformationComponent,
    HelpCenterPopupComponent,
    RatesFeesComponent,
    TransactionsComponent,
    InputSwitchComponent,
    ZipCodePipe,
    DateFilterComponent,
    SearchBarComponent,
    InputSwitchV2Component,
    DropdownFieldComponent,
    AccordionComponent,
    AccordionGroupComponent,
    RatesAndFeesNotificationComponent,
    LocationBusinessTypeComponent,
    LocationsListComponent,
    CartProductsComponent,
    SendApplicationModalComponent,
    BadgeComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    RouterModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    AccordionModule.forRoot(),
    NgxDatatableModule,
    AlertModule.forRoot(),
    NgxChartsModule,
    CollapseModule,
    MaskitoDirective,
  ],
  exports: [
    BusinessLocationComponent,
    BaseBusinessAddressesComponent,
    AddressComponent,
    BaseOwnerComponent,
    BankerPopupComponent,
    FieldErrorComponent,
    InputSpinnerComponent,
    MerchantSummaryComponent,
    TooltipComponent,
    SavePopupComponent,
    YodleePopupComponent,
    BusinessTypeComponent,
    ShippingLocationComponent,
    SignersDropdownComponent,
    RecaptchaModule,
    RecaptchaFormsModule,
    DisableControlDirective,
    GeneralActionPopUpComponent,
    GeneralActionModalComponent,
    CartSummaryComponent,
    BosInformationComponent,
    HelpCenterPopupComponent,
    CartComponent,
    CartProductComponent,
    CalloutNotificationComponent,
    ProductDetailImagesComponent,
    RadioCardComponent,
    CartIconComponent,
    TransactionsComponent,
    RatesFeesComponent,
    InputSwitchComponent,
    DateFilterComponent,
    SearchBarComponent,
    TruncatePipe,
    InputSwitchV2Component,
    DropdownFieldComponent,
    AccordionComponent,
    AccordionGroupComponent,
    RatesAndFeesNotificationComponent,
    LocationBusinessTypeComponent,
    LocationsListComponent,
    CartProductsComponent,
    SendApplicationModalComponent,
    BadgeComponent,
  ],
  providers: [CurrencyPipe, DatePipe, WindowProvider],
  bootstrap: [],
})
export class EventhorizonModule {
  public static forRoot(environment: any, otherProviders: [] = []): ModuleWithProviders<EventhorizonModule> {
    return {
      ngModule: EventhorizonModule,
      providers: [
        {
          provide: 'env',
          useValue: environment,
        },
        ...otherProviders,
      ],
    };
  }
}
