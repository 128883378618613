<div class="row" *ngIf="!showRates && isSwiped">
  <div class="col-12 rates-fees-examples">
    <div class="row" *ngIf="swipedExampleFees.swipe">
      <div class="col-6 offset-md-1 p-0">
        <i class="far fa-circle-plus"></i>&nbsp;{{ swipedExampleFees.swipe.productName }}
      </div>
      <div class="col-6 col-md-5 text-end fw-bold p-0">
        {{ swipedExampleFees.swipe.rateDefault }}% +
        {{ getFeeValue(swipedExampleFees.swipe) | currency: 'USD':'symbol-narrow':'1.2-10' }}
      </div>
    </div>
    <div class="row" *ngIf="swipedExampleFees.nonSwipe">
      <div class="col-6 offset-md-1 p-0">
        <i class="far fa-circle-plus"></i>&nbsp;{{ swipedExampleFees.nonSwipe.productName }}
      </div>
      <div class="col-6 col-md-5 text-end fw-bold p-0">
        {{ swipedExampleFees.nonSwipe.rateDefault }}% +
        {{ getFeeValue(swipedExampleFees.nonSwipe) | currency: 'USD':'symbol-narrow':'1.2-10' }}
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="!showRates && !isSwiped">
  <div class="col-12 rates-fees-examples">
    <div *ngIf="interchangePExampleFees?.visaDebit">
      <div class="row">
        <div class="col-6 offset-md-1 p-0">
          <i class="far fa-circle-plus"></i>&nbsp;{{ interchangePExampleFees.visaDebit?.productName }}
        </div>
        <div class="col-6 col-md-5 text-end fw-bold p-0">
          {{ interchangePExampleFees.visaDebit?.rateDefault }}% +
          {{ getFeeValue(interchangePExampleFees.visaDebit) | currency: 'USD':'symbol-narrow':'1.2-10' }}
        </div>
      </div>
    </div>
    <div *ngIf="interchangePExampleFees?.visaCredit">
      <div class="row">
        <div class="col-6 offset-md-1 p-0">
          <i class="far fa-circle-plus"></i>&nbsp;{{ interchangePExampleFees.visaCredit?.productName }}
        </div>
        <div class="col-6 col-md-5 text-end fw-bold p-0">
          {{ interchangePExampleFees.visaCredit?.rateDefault }}% +
          {{ getFeeValue(interchangePExampleFees.visaCredit) | currency: 'USD':'symbol-narrow':'1.2-10' }}
        </div>
      </div>
    </div>
  </div>
</div>
