export const constants = {
  annualVolumeCheck: 1000000,
  START_PROGRESS: 0,
  CONTACT_INFO_PROGRESS: 0,
  INDUSTRY_PROGRESS: 10,
  INDUSTRY_SUBCATEGORY_PROGRESS: 15,
  BUSINESS_TYPE_PROGRESS: 15,
  PAYMENT_TYPES_PROGRESS: 20,
  PAYMENT_INFO_PROGRESS: 26,
  BUSINESS_PURCHASE_TYPE_PROGRESS: 26,
  BUSINESS_SIZE_PROGRESS: 30,
  LOCATIONS_PROGRESS: 30,
  PACKAGE_SELECTION_PROGRESS: 35,
  SOFTWARE_SELECTION_PROGRESS: 37,
  ADDITIONAL_PACKAGE_SELECTION_PROGRESS: 37,
  CHECKOUT_PROGRESS: 40,
  BUSINESS_TAXES_PROGRESS: 40,
  BUSINESS_INFO_PROGRESS: 50,
  BUSINESS_ADDRESS_PROGRESS: 60,
  BUSINESS_OWNERS_PROGRESS: 70,
  BANK_PROGRESS: 80,
  SHIPPING: 82,
  VERIFY_QUIZ_PROGRESS: 83,
  VERIFY_PROGRESS: 90,
  TERMS_PROGRESS: 90,
  CONFIRMATION_PROGRESS: 100,
  ANNUAL_CC_VOL_MIN: 1000,
  ANNUAL_CC_VOL_MAX: 1000000000,
  HEADER_TOP_POSITION: 0,
  MAX_OWNERS: 4,
  GROSS_ANNUAL_VOLUME_MAX: 2000000,
  ASC: 'asc',
  ONBOARDING_MAX_SUBMISSIONS: 3,
};

export const statuses = {
  InProgress: 1,
  Abandoned: 2,
  Pending: 3,
  Complete: 4,
  Declined: 5,
  OffRamped: 6,
  Canceled: 7,
};

export const mandatoryProductsIds: string[] = [
  '10041', // Swiped/Non Swiped card
  '10600', // security essentials
  '10601', // security essentials
];

export const defaultImage = '/assets/images/clover_monochromatic_black_rgb.png';
export const phonePaymentIds: string[] = ['74842'];

export const purchaseTypePriority = { P: 1, default: Infinity };

export const continuePage = '/checkout';

export const paymentTerms = {
  INSTALLMENT: '3 Installments',
  LEASE_24: '24 Month lease',
  LEASE_36: '36 Month lease',
  OWNED: 'Owned',
  PAY_FULL: 'Pay in Full',
  RENTAL: 'Monthly rent',
  PURCHASED: 'Purchased',
  EXISTING: 'Existing',
};

export const transactionTooltipTexts = {
  SWIPED:
    'A “swipe” rate and transaction fee is applied to swiped or chipped credit & debit cards. A “non-swiped” rate and transaction fee is applied to transactions entered manually, over the phone, or online.',
  INTERCHANGE:
    'This pricing model applies a fixed markup to processing rates set by the card brands. It separates the components of your processing costs allowing for transparent reporting and optimized rates.',
};

export const productTypes = {
  SOLUTION_FEE: 'SOLUTION_FEE',
  MONTHLY_FEE: 'MO_FEE',
  SOFTWARE: 'SOFTWARE',
  ACQUIRING: 'ACQUIRING',
  NET_FEE: 'NET_FEE',
};

export const purchaseType = {
  PURCHASE: 'P',
  OWNED: 'O',
  IP3: 'IP3',
  R: 'R',
};

export const activityLogTypes = {
  ALL_APPLICATIONS: 'all-applications',
  SINGLE_APPLICATION: 'application',
};

export const cartEventsTypes = {
  DISABLED_PRODUCT: 'DisabledProduct',
};

export const SELECTED_STATUS = 'selectedMerchantStatus';

export const PRODUCT_RELATED_SECTIONS = ['products', 'software', 'ratesAndFees'];

export const LOCATION_GENERAL_DESCRIPTION = 'Add products and other location specific details';

export const assistedOnBoardingSectionsConfig: OnBoardingSectionConfig[] = [
  {
    sectionName: 'business-info',
    title: 'Business Information',
    description: 'Enter business information for merchant',
    backButtonExists: false,
    allowDisabledNext: true,
    active: true,
    progress: 60,
  },
  {
    sectionName: 'business-owners',
    title: 'Owners & Controllers',
    description: 'Enter ownership information for merchant',
    backButtonExists: true,
    allowDisabledNext: true,
    active: true,
    progress: 80,
  },
];

export const assistedOnboardingSectionsPerLocationConfig: OnBoardingSectionConfig[] = [
  {
    sectionName: 'location-info',
    title: 'Information',
    description: '',
    backButtonExists: true,
    allowDisabledNext: true,
    active: true,
  },
  {
    sectionName: 'products',
    title: 'Products',
    description: 'Choose merchant products, manage payment methods and price',
    backButtonExists: true,
    allowDisabledNext: true,
    active: true,
  },
  {
    sectionName: 'software',
    title: 'Software',
    description: 'Choose register software used by merchant (Requires product selection) ',
    backButtonExists: true,
    allowDisabledNext: true,
    active: false,
  },
  {
    sectionName: 'ratesAndFees',
    title: 'Processing Rates & Fees',
    description: 'Choose processing rates, fees, and payment methods',
    backButtonExists: false,
    allowDisabledNext: true,
    active: true,
  },
];

export interface OnBoardingSectionConfig {
  active: boolean;
  sectionName: string;
  title: string;
  customTitle?: string;
  locationIndex?: number;
  description: string;
  backButtonExists: boolean;
  allowDisabledNext: boolean;
  progress?: number;
}

export const defaultPartnerContentName = 'keybank_default_partner';

export const commonErrors = {
  failedToSaveInfo: 'Failed to save information.',
  errorEncountered:
    'We have encountered an error processing your request.\n Please try again later or contact us if the issue persists.',
};

export const SITE_NAMES = {
  XUP: 'XUP',
  KeyBank: 'KeyBank',
  XupHUB: 'XupHUB',
};

export const BUSINESS_VERIFICATION_STATUS_COLORS = {
  HIGH_RISK: '#CC0000',
  MEDIUM_RISK: '#F7BD4F',
  LOW_RISK: '#01BA88',
};

export const TAX_CLASSIFICATION_OPTIONS = [
  {
    value: 'CORPORATION',
    display: 'Corporation',
  },
  {
    value: 'PARTNERSHIP',
    display: 'Partnership',
  },
  {
    value: 'DISREGARDED_ENTITY',
    display: 'Disregarded Entity',
  },
];

export const ANSWER_TYPES = {
  FREE_FORM: 'freeForm',
  SINGLE: 'singleRadio',
  SINGLE_DROPDOWN: 'singleDropdown',
  MULTIPLE: 'mutliCheckbox',
  MULTIPLE_DROPDOWN: 'multiDropdown',
};

export const BOS_INFORMATION_TITLES = {
  formStatus: 'Form Status',
  mcaStatus: 'MCA Status',
  ciuBusinessStatus: 'CIU Business Status',
  ciuIndividualStatus: 'CIU Individual Status',
  accountOpeningStatus: 'Account Opening Status',
};

export const MPA_LINKS: IMPALinks = {
  swiped: [
    {
      label: 'View your Payments Acceptance Guide',
      href: 'https://merchants.fiserv.com/content/dam/firstdata/au/en/documents/Your_Payments_Acceptance_Guide.pdf',
    },
    {
      label: 'View your Interchange Qualification Matrix',
      href: 'https://prod-mp-fiserv.fiserv.com/res/docs/permanent/templates/14/IQM%2024.1%20US%20and%20US%20Territory.pdf',
    },
    {
      label: 'View your Pass Through Fee Schedule',
      href: 'https://prod-mp-fiserv.fiserv.com/res/docs/permanent/templates/14/24.1%20Pass%20Through%20Fee%20Schedule.pdf',
    },
  ],
  icPlus: [
    {
      label: 'View your Payments Acceptance Guide',
      href: 'https://merchants.fiserv.com/content/dam/firstdata/au/en/documents/Your_Payments_Acceptance_Guide.pdf',
    },
    {
      label: 'View your Interchange Qualification Matrix',
      href: 'https://prod-mp-fiserv.fiserv.com/res/docs/permanent/templates/14/IQM%2024.1%20US%20and%20US%20Territory.pdf',
    },
    {
      label: 'View your Pass Through Fee Schedule',
      href: 'https://prod-mp-fiserv.fiserv.com/res/docs/permanent/templates/14/24.1%20Pass%20Through%20Fee%20Schedule.pdf',
    },
    {
      label: 'View Your Interchange Rate Schedule',
      href: 'https://prod-mp-fiserv.fiserv.com/res/docs/permanent/templates/14/RSA.S24.IC.pdf',
    },
  ],
};

export interface IMPALinks {
  swiped: IMPALink[];
  icPlus: IMPALink[];
}
export interface IMPALink {
  label: string;
  href: string;
}

export const BANK_YODLEE_TOOLTIP = 'Instantly link your account with a secure sign in to your online banking profile.';

export const USERS_FILTERS = {
  ADMIN_VIEWER: 'payfac-admin,payfac-viewer',
  ST_USERS:
    'xup-superuser,bank-admin,manager,standard,read-only,sony-report-viewer,user-manager,partner-admin,sony-report-admin',
};

export const USER_ROLES = {
  XUP_SUPERUSER: 'xup-superuser',
  BANK_ADMIN: 'bank-admin',
  MANAGER: 'manager',
  STANDARD: 'standard',
  READ_ONLY: 'read-only',
  USER_MANAGER: 'user-manager',
  MERCHANT: 'merchant',
  PAYFAC_ADMIN: 'payfac-admin',
  PAYFAC_VIEWER: 'payfac-viewer',
  PARTNER_ADMIN: 'partner-admin',
  SONY_REPORT_ADMIN: 'sony-report-admin',
  SONY_REPORT_VIEWER: 'sony-report-viewer',
};

export const ANNUAL_CREDITDEBIT_PROCESSING_VOLUME_CONTACT_INFO = [
  {
    label: 'Under $1M',
    value: 0,
  },
  {
    label: '$1M-$5M',
    value: 1,
  },
  {
    label: '$5M-$10M',
    value: 2,
  },
  {
    label: '$10M+',
    value: 3,
  },
];

export const CALL_LANGUAGE_OPTIONS = [
  {
    label: 'English',
    value: 0,
  },
  {
    label: 'Español',
    value: 1,
  },
];

export const CALL_TIME_OPTIONS = ['8am-11am', '11am-2pm', '2pm-5pm'];

export const US_TIMEZONES_OPTIONS = [
  {
    name: 'Eastern Time (ET)',
    value: 0,
    abbreviations: ['EST', 'EDT'],
  },
  {
    name: 'Central Time (CT)',
    value: 1,
    abbreviations: ['CST', 'CDT'],
  },
  {
    name: 'Mountain Time (MT)',
    value: 2,
    abbreviations: ['MST', 'MDT'],
  },
  {
    name: 'Pacific Time (PT)',
    value: 3,
    abbreviations: ['PST', 'PDT'],
  },
  {
    name: 'Alaska Time (AKT)',
    value: 4,
    abbreviations: ['AKST', 'AKDT'],
  },
  {
    name: 'Hawaii Time (HT)',
    value: 5,
    abbreviations: ['HST', 'HDT'],
  },
];

export const merchantEmailTypes = {
  CREATE_APPLICATION: 'AssistedOnboardingCreate',
  UPDATE_APPLICATION: 'AssistedOnboardingUpdate',
  SAVE_AND_RETURN: 'SaveAndReturn',
};
