<div class="d-flex flex-column gap-2">
  <app-callout-notification [notification]="'Any ecommerce websites where transactions occur should be added as an additional location. This does not include payment of invoices digitally.'"></app-callout-notification>
  <div class="locations-summary">
    <div class="summary-header">
      <div class="summary-header-text">
        <h3 class="mb-2 p-0">All business locations</h3>
        <p>You may add up to 10 locations for your business.</p>
      </div>
      <button
        type="button"
        class="xup-secondary-button"
        [disabled]="isAddDisabled || isLoading"
        [class.xup-button-loading]="isLoading"
        (click)="handleAdd()"
      >
        <label>Add New Location <i class="fa fa-plus ps-2"></i></label>
        <i class="fas fa-spinner-third fa-spin loading-icon" *ngIf="isLoading"></i>
      </button>
    </div>  
    <div class="summary-start" *ngIf="isInitialState; else list">
      <h2 class="add-primary-label p-0">Add Primary Location</h2>
      <button class="xup-primary-button" (click)="handleStartClick()">
        Let's Start
        <i class="fa fa-arrow-right ps-2"></i>
      </button>
    </div>
  </div>
</div>

<ng-template #list>
  <lib-locations-list
    [isLoading]="isLoading" 
    [locations]="locations"
    [showLocationIcon]="true"
    [showPrimaryIndicator]="true"
    [showStatusLabel]="true"
    [showMcc]="true"
    [showEditButton]="true"
    (edit)="handleEdit($event)"
    [showDeleteButton]="true"
    (delete)="handleDelete($event)"
    [showCartHeader]="true"
    [showCartPaymentColumn]="true"
  ></lib-locations-list>
</ng-template>

