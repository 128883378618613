import { Component } from '@angular/core';
import { ContentBase } from '../base-content/content.base';

@Component({
  selector: 'app-tg-interactive-image',
  templateUrl: './interactive-image.component.html',
  styleUrls: ['./interactive-image.component.scss'],
})
export class InteractiveImageComponent extends ContentBase {
  isMobileCardLayout = true;

  backgroundImage = '';

  buttonList = [];

  imageAlt = '';

  setContent(content) {
    this.isMobileCardLayout = content.mobileCardLayout;
    this.backgroundImage = content.backgroundImageUrl;
    this.buttonList = content.interactiveButtons;
    this.imageAlt = content.title;
  }

  openInfo(element: HTMLElement) {
    let interactiveTiles = Array.from(
      document.getElementsByClassName('interactive-tile') as HTMLCollectionOf<HTMLElement>,
    );
    for (let tile of interactiveTiles) {
      tile.style.visibility = 'hidden';
      tile.style.opacity = '0';
    }

    element.style.visibility = 'visible';
    element.style.opacity = '1';
  }

  closeInfo(element: HTMLElement) {
    element.style.visibility = 'hidden';
    element.style.opacity = '0';
  }
}

export default InteractiveImageComponent;
