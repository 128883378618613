import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'securityPipe' })
export class SecurityPipe implements PipeTransform {
  transform(value: string): string {

    if (!value) {
      return '';
    }
    value = value.split('*').join('●');
    return value
      .split('')
      .map((letter, index) => index < value.length - 4 ? '●' : letter)
      .join('');
  }
}
