import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, length: number = 10, trail: string = ''): any {
    const limit = value.substr(0, length).lastIndexOf(' ');
    const truncated = value.length > length ? value.substring(0, limit) + trail : value;
    return truncated;
  }
}
