import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { messages } from '@eventhorizon/data/messages.data';
import { BasePageDriverComponent } from '@app/pages/base-page-driver/base-page-driver.component';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { IStepTrackerConfig } from '../step-tracker/step-tracker.component';
import { LocationsService } from '@eventhorizon/services/locations.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GeneralActionModalComponent } from '@eventhorizon/components/general-action-modal/general-action-modal.component';
import { firstValueFrom } from 'rxjs';
import { ROUTES } from '@eventhorizon/constants/routes.constants';

@Component({
  selector: 'app-location-info-screen',
  templateUrl: './location-info-screen.component.html',
  styleUrls: ['./location-info-screen.component.scss'],
})
export class LocationInfoScreenComponent extends BasePageDriverComponent {
  public text = messages.locationInfoSlide;

  @ViewChild('unsavedChangesRef') unsavedChangesRef: TemplateRef<any>;

  public stepTrackerConfig: IStepTrackerConfig = {
    steps: ['Information', 'Products'],
    activeIndex: 0,
  };

  constructor(
    public store: ApplicationStore,
    protected route: ActivatedRoute,
    protected router: Router,
    protected rs: RoutingPathService,
    private locationService: LocationsService,
    protected bsModalService: BsModalService,
  ) {
    super(rs, router, route);
    // needed so it always refreshes with the right current location
    this.locationService.reloadLocation();
    if (this.locationService.currentLocation) {
      if (this.locationService.currentLocation.isPrimary) {
        this.text.smallHeadline = 'Tell us about your primary business location.';
      } else {
        this.text.smallHeadline = 'Tell us about your business location.';
      }
    }
  }

  async prev() {
    const modalRef = this.bsModalService.show(GeneralActionModalComponent, {
      initialState: {
        title: 'You have unsaved changes.',
        content: this.unsavedChangesRef,
        actionButtons: [
          {
            label: 'Cancel',
            class: 'xup-secondary-button',
          },
          {
            label: 'Proceed',
            class: 'xup-primary-button',
          },
        ],
      },
      class: 'medium-modal',
    });

    let modalResponse = await firstValueFrom(modalRef.content.onClose);
    if (modalResponse && modalResponse === 'Proceed') {
      this.router.navigateByUrl(ROUTES.LOCATIONS);
    }
  }
}
