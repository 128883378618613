<footer *ngIf="config" class="page-main-footer container-fluid">
  <div class="d-none d-md-flex ">
    <div class="col-sm-12 col-md-6 col-lg-5 justify-content-start">
      <div *ngIf="config?.logo" class="d-inline-block align-middle">
        <img [src]="config?.logo?.url" [alt]="config?.logo?.description" />
      </div>
      <div class="d-inline-block ms-2 align-middle">
        <div *ngIf="config?.logoHeadline">{{ config?.logoHeadline }}</div>
        <div *ngIf="config?.privacyPolicy">
          <a [href]="config?.privacyPolicy?.url" target="_blank">{{ config?.privacyPolicy?.displayText }}</a>
        </div>
        <div *ngIf="config?.privacyPolicy?.copyright">
          <span>{{ config?.privacyPolicy?.copyright }}</span>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-7 page-main-footer-nav">
      <div class="row">
        <div class="col-12 text-end page-main-footer-nav-upper">
          <div class="d-inline-block footer-option-container" *ngFor="let link of config?.topLinks">
            <div [ngClass]="link?.url || link?.action ? 'is-link' : ''" (click)="doAction(link)">
              {{ link?.displayText }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-end page-main-footer-nav-lower">
          <div
            class="d-inline-flex align-items-center footer-option-container"
            *ngFor="let link of config?.bottomLinks"
          >
            <div [ngClass]="link?.url || link?.action ? 'is-link' : ''" (click)="doAction(link)">
              {{ link?.displayText }}
            </div>
            <i *ngIf="link?.iconClass" [class]="'fas ' + link?.iconClass"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex d-md-none row">
    <div *ngIf="config?.logo" class="col-12 text-center align-middle">
      <img [src]="config?.logo?.url" [alt]="config?.logo?.description" />
    </div>
    <div class="col-12 text-center align-middle mt-2">
      <div *ngIf="config?.logoHeadline">{{ config?.logoHeadline }}</div>
      <div *ngIf="config?.privacyPolicy">
        <a [href]="config?.privacyPolicy?.url" target="_blank">{{ config?.privacyPolicy?.displayText }}</a>
      </div>
    </div>
    <div class="col-12 page-main-footer-nav border-top">
      <div class="row">
        <div class="col-12 text-center page-main-footer-nav-upper">
          <div class="d-block" *ngFor="let link of config?.topLinks">
            <div [ngClass]="link?.url || link?.action ? 'is-link' : ''" (click)="doAction(link)">
              {{ link?.displayText }}
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center" *ngFor="let link of config?.bottomLinks">
            <div [ngClass]="link?.url || link?.action ? 'is-link' : ''" (click)="doAction(link)">
              {{ link?.displayText }}
            </div>
            <i *ngIf="link?.iconClass" [class]="'fas ' + link?.iconClass"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

</footer>
