import { HttpClient, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

export async function downloadFile(httpClient: HttpClient,
  params: HttpParams,
  url: string,
  filename: string,
) {
  const a = document.createElement('a');

  const req = new HttpRequest('GET', url, {
    method: 'GET',
    responseType: 'blob',
    params,
  });
  let response;
  try {
    response = (await lastValueFrom(httpClient.request(req))) as unknown as HttpResponse<Blob>;
  } catch (error) {
    return Promise.reject(error);
  }
  if (!response?.body) return;
  const href = URL.createObjectURL(response.body);
  a.download = filename;
  a.href = href;
  a.target = '_blank';
  a.click();
  URL.revokeObjectURL(href);
}
