<div class="modal-header row p-0 mb-4">
  <div class="col-10 ps-0">
    <h1 *ngIf="title" class="modal-header-title">{{ title }}</h1>
  </div>
  <div class="col-2 pe-0 d-flex justify-content-end">
    <button class="btn" (click)="close()">
      <i class="far fa-xmark-large"></i>
    </button>
  </div>
</div>
<div class="modal-body p-0">
  <ng-container *ngTemplateOutlet="content; context: templateContext"></ng-container>
</div>
<div class="modal-footer d-flex justify-content-end mt-4" *ngIf="actionButtons.length > 0">
  <button
    type="button"
    *ngFor="let action of actionButtons"
    class="ms-3 xup-button-small"
    [class]="action.class"
    (click)="close(action.label)"
  >
    {{ action.label }}
  </button>
</div>
