<div class="panel">
  <div class="modal-header">
    <button class="btn pull-right" (click)="close()" *ngIf="showClose">
      <i class="far fa-xmark-large"></i>
    </button>
  </div>
  <div class="modal-body">
    <header class="row mb-4">
      <div class="col-12">
        <h1 id="modal-title" class="text-center" *ngIf="title">
          {{ title }}
        </h1>
      </div>
      <div class="col-12">
        <h6 id="modal-subtitle" class="modal-title text-center" *ngIf="subtitle">{{ subtitle }}</h6>
      </div>
    </header>
    <section class="row">
      <div class="col-12">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </section>
  </div>
  <div class="modal-footer justify-content-center" *ngIf="action">
    <button type="button" class="primary-button justify-self-center" (click)="close(true)">{{ action }}</button>
  </div>
</div>
