<div 
    class="dropdown-container form-control form-select" 
    dropdown 
    placement="bottom right" 
    [class.disabled]="field.disabled"
    [class.error]="field.invalid && field.touched"
>
    <a
        #mainButton
        [id]="id"
        dropdownToggle
        class="dropdown-toggle"
        role="button"
        aria-label="dropdown toggle" 
        aria-haspopup="listbox"
        [attr.aria-expanded]="dropdown?.isOpen ? 'true' : 'false'"
        tabindex="0"
        (blur)="field.markAsTouched()"
        (keydown.enter)="toggleDropdown()"
        (keydown.space)="toggleDropdown()"
    >
        {{ formControlValue }} <span class="caret brand-caret"></span>
    </a>
    <ul 
        *dropdownMenu 
        class="dropdown-menu dropdown-menu-right" 
        role="menu" 
        [attr.aria-labelledby]="id"
    >
        <li *ngFor="let option of options; let index = index" role="menuitem">
            <button 
                class="dropdown-item" 
                [ngClass]="{ selected: field.value === option.value }" 
                (click)="selectOption(option.value)"
            >
                {{ option.name }}
            </button>
        </li>
    </ul>
</div>

<label [for]="id" class="form-label" [class.error]="field.invalid && field.touched"> {{ label }} </label>

<app-field-error [control]="field"></app-field-error>
