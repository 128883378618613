<app-container *mobxAutorun>
  <app-application-page
    [index]="0"
    [subIndex]="0"
    [text]="messages.industrySelectionSlide"
    (prev)="prev()"
    (next)="next()"
  >
    <app-tg-industry-selector
      #innerSlide
      [disableNextOnError]="true"
      (prev)="prev()"
      (next)="next()"
    ></app-tg-industry-selector>
  </app-application-page>
</app-container>
