<section class="w-100 position-relative" [ngClass]="{ 'mobile-card': isMobileCardLayout }">
  <img class="interactive-main-img" [src]="backgroundImage" [alt]="imageAlt" />

  <ng-container *ngFor="let button of buttonList; let i = index">
    <div
      class="interactive-button"
      [style.left]="button.left"
      [style.top]="button.top"
      [style.bottom]="button.bottom"
      [style.right]="button.right"
      #interactiveBtn
      (click)="openInfo(interactiveTile)"
    >
      <i class="btn-icon fas fa-plus"></i>
      <label class="interactive-button-label">{{ button.label }}</label>
    </div>

    <div
      class="interactive-tile"
      [style.left]="button.left"
      [style.top]="button.top"
      [style.bottom]="button.bottom"
      [style.right]="button.right"
      #interactiveTile
    >
      <i class="close-icon fas fa-xmark" (click)="closeInfo(interactiveTile)"></i>
      <img class="interactive-tile-img" [src]="button.headerImage" [alt]="button.label" />
      <div class="interactive-tile-inner">
        <img class="interactive-tile-thumbnail" [src]="button.thumbnailImage" [alt]="button.label + ' thumbnail'" />
        <div class="interactive-tile-content-container">
          <h2 class="interactive-tile-header">{{ button.label }}</h2>
          <p class="interactive-tile-content">{{ button.description }}</p>
        </div>
      </div>
      <a *ngIf="button.ctaUrl" class="interactive-tile-cta" [href]="button.ctaUrl"
        >{{ button.ctaLabel }} <i class="ms-1 fas fa-arrow-right"></i
      ></a>
    </div>
  </ng-container>
</section>
