<div class="row" *ngIf="!isLoading">
  <ng-container *ngIf="{ value: selectedAddress$ | async } as selectedAddress">
    <!--primary location address-->
    <div class="col-12 mb-4">
      <div class="product-shipping-header">Ship to primary location address</div>
      <div
        class="address-options product-shipping-address"
        tabindex="0"
        *ngIf="!locationsController.isAddressEmpty('locationAddress', addresses)"
        (keyup.enter)="selectAddress('locationAddress')"
        (click)="selectAddress('locationAddress')"
      >
        <div
          class="address-options-check"
          [ngClass]="{ 'address-options-check-selected': selectedAddress.value === 'locationAddress' }"
        ></div>
        <div class="ms-2 product-shipping-value">
          {{ locationsController.getAddressFormattedFromMap('locationAddress', addresses) }}
        </div>
      </div>
    </div>
    <!--legal business address-->
    <div class="col-12 mb-4">
      <div class="product-shipping-header">Ship to legal business address</div>
      <div
        class="address-options product-shipping-address"
        tabindex="0"
        *ngIf="!locationsController.isAddressEmpty('legalAddress', addresses)"
        (keyup.enter)="selectAddress('legalAddress')"
        (click)="selectAddress('legalAddress')"
      >
        <div
          class="address-options-check"
          [ngClass]="{ 'address-options-check-selected': selectedAddress.value === 'legalAddress' }"
        ></div>
        <div class="ms-2 product-shipping-value">
          {{ locationsController.getAddressFormattedFromMap('legalAddress', addresses) }}
        </div>
      </div>
    </div>
    <!--owners address-->
    <div class="col-12 mb-4">
      <div class="product-shipping-header">Ship to owner address</div>
      <div
        class="address-options product-shipping-address"
        tabindex="0"
        *ngFor="let ownerIndex of ownerIndexes"
        (keyup.enter)="selectAddress('Owner ' + (ownerIndex + 1), ownerIndex)"
        (click)="selectAddress('Owner ' + (ownerIndex + 1), ownerIndex)"
      >
        <div
          *ngIf="!locationsController.isAddressEmpty('Owner ' + (ownerIndex + 1), addresses)"
          class="address-options-check"
          [ngClass]="{ 'address-options-check-selected': selectedAddress.value === 'Owner ' + (ownerIndex + 1) }"
        ></div>
        <div class="ms-2 product-shipping-address product-shipping-value">
          <p class="product-shipping-name">{{ locationsController.ownerNames[ownerIndex] + ' ' + '-' }}&nbsp;</p>
          {{ locationsController.getAddressFormattedFromMap('Owner ' + (ownerIndex + 1), addresses) }}
        </div>
      </div>
    </div>
    <div class="col-12 mb-4 mx-auto">
      <div class="product-shipping-header">Ship to other address</div>
      <div class="address-options" tabindex="0" (keyup.enter)="selectAddress('None')" (click)="selectAddress('None')">
        <div
          class="address-options-check"
          [ngClass]="{ 'address-options-check-selected': selectedAddress.value === 'None' }"
        ></div>
        <div class="ms-2 product-shipping-value">
          {{ 'Other' }}
        </div>
      </div>
      <form [formGroup]="form" #formElem [ngClass]="{ 'd-none': !locationsController.showShippingForm }">
        <input id="primary-location-selected" type="hidden" formControlName="primarySelected" />
        <div *ngIf="store.businessLocations[0]">
          <div>
            <app-shipping-location
              #shippingLocation
              [shippingLocation]="store.businessLocations[0].productShippingAddress"
              [shippingLocations]="[store.businessLocations[0].productShippingAddress]"
            ></app-shipping-location>
          </div>
        </div>
      </form>
      <p
        class="form-error-message mt-2"
        role="alert"
        *ngIf="form?.touched && !locationsController.selectedAddress.value"
      >
        Required
      </p>
    </div>
  </ng-container>
</div>
<div class="row">
  <div class="col-md-3"></div>
  <div *ngIf="errorMessage && errorMessage.length > 0" class="col-md-6 form-error-message error-message">
    Failed to check out
    <ul>
      <li *ngFor="let error of errorMessage">{{ error }}</li>
    </ul>
  </div>
  <div class="col-md-3"></div>
</div>
