import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-general-action-modal',
  templateUrl: './general-action-modal.component.html',
  styleUrls: ['./general-action-modal.component.scss'],
})
export class GeneralActionModalComponent implements OnDestroy, OnInit {
  title?: string;

  content?: TemplateRef<any>;

  actionButtons? = [];

  actionTaken: string;

  templateContext?;

  onClose: Subject<any>;

  constructor(private modalRef: BsModalRef) {}

  ngOnInit() {
    this.onClose = new Subject();
  }

  ngOnDestroy() {
    this.close();
  }

  close(action?): void {
    this.actionTaken = action;
    this.onClose.next(this.actionTaken);
    this.modalRef.hide();
  }
}