<div class="panel card panel-default" [ngClass]="class">
  <div
    attr.id="panel-heading-{{ id }}"
    class="accordion-group-tg"
    [ngClass]="isDisabled ? 'panel-disabled' : 'panel-enabled'"
    (click)="toggleItem()"
  >
    <div role="button" class="accordion-toggle" [attr.aria-expanded]="isOpen">
      <ng-content select="[accordion-heading]"></ng-content>
    </div>
  </div>
  <div
    attr.id="panel-content-{{ id }}"
    attr.aria-labelledby="panel-heading-{{ id }}"
    [attr.hidden]="isOpen ? null : 'hidden'"
    [attr.aria-hidden]="isOpen ? null : 'hidden'"
    class="panel-body card-block card-body"
    [ngClass]="{ 'text-muted': isDisabled }"
  >
    <ng-content></ng-content>
  </div>
</div>
