<div class="location-business-type">
  <input id="id" type="text" [formControl]="searchMCC" autocomplete="off"
    class="form-control" />
  <label for="id" class="form-label">MCC Description</label>
  <app-field-error [control]="searchMCC"></app-field-error>
  <div class="position-relative" *ngIf="mccOptions?.length && !selectionMatchesSearch">
    <span class="location-business-type-results-label">Results</span>
    <div class="location-business-type-results">
      <div *ngFor="let option of mccOptions" 
        class="location-business-type-results-entry"
        [ngClass]="{'selected': isOptionSelected(option)}" 
        tabindex="0" 
        (click)="selectOption(option)"
        (keyup.Enter)="selectOption(option)">
        <div class="entry-description">{{option.description}}</div>
        <div>{{ option.industryDescription }}</div>
      </div>
    </div>
  </div>
</div>