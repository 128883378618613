<div class="status-line status-line-container mb-2">
  <div class="status-line-content">
    <div class="status-line-section-title">
      <span class="status-line-item">{{ title }}</span>
      <span class="caret" *ngIf="!pass" (click)="toggleDetails()">
        <i class="{{ displayDetails ? 'fas fa-caret-down' : 'fas fa-caret-right' }}"></i>
      </span>
    </div>
    <div class="status-line-actions">
      <a (click)="redirectTo()"><i class="edit-icon far fa-pen-to-square"></i></a>
      <span class="{{ pass ? 'status-line-pass' : 'status-line-fail' }}">
        <i class="{{ pass ? 'far fa-check-circle' : 'far fa-circle-exclamation' }}"></i>
      </span>
    </div>
  </div>
  <div *ngIf="displayDetails">
    <div class="status-line-details" *ngFor="let indicator of indicators">
      <div class="status-line-details-message">
        <span>{{ indicator.description }}</span>
      </div>
      <div class="status-line-details-actions">
        <a (click)="redirectTo()">Resolve</a>
      </div>
    </div>
  </div>
</div>
