<app-container *mobxAutorun>
    <app-application-page
        [index]="1"
        [validate]="false"
        [text]="messages.checkoutSlide"
        (prev)="prev()"
        (next)="next()"
    >
        <app-tg-checkout #innerSlide (prev)="prev()" (next)="next()"></app-tg-checkout>
    </app-application-page>
</app-container>
