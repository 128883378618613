import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvalidApplicationService {
  private _formSubject = new BehaviorSubject<FormGroup | null>(null);

  public form$ = this._formSubject.asObservable();

  setForm(form: FormGroup): void {
    this._formSubject.next(form);
  }
}
