import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  @Input()
  public type: string;

  @Input()
  public label: string;

  @Input()
  public showIcon: boolean;

  @Input()
  public rounded: boolean;
}
