<ng-container *mobxAutorun>
    <div class="container-fluid" data-test="timeout-screen" *ngIf="timeOutSrv.isOpen">
        <div class="row pt-5">
            <div class="col">
                <button type="button" class="xup-minimal-button xup-button-small" (click)="timeOutSrv.hide()">
                    <i class="fa-solid fa-angle-left me-2"></i>
                    Back
                </button>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-md-8 offset-md-2 col-xl-4 offset-xl-4 text-center">
                <div class="row">
                    <div class="col-12 mb-3">
                        <i class="fa-solid fa-triangle-exclamation"></i>
                    </div>
                    <div class="col-12">
                        <h1 class="mb-1">We were unable to load the service</h1>
                        <p>Your application progress was saved. Please try again in a few minutes. If the issue persists,
                            please
                            contact
                            us.</p>
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                        <button type="button" class="xup-secondary-button me-3" (click)="open()">Contact Us</button>
                        <button type="button" class="xup-primary-button" (click)="refresh()">Try again</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
