<app-container *mobxAutorun>
  <app-application-page
    [index]="0"
    [subIndex]="0"
    [text]="messages.industrySubcategorySelectionSlide"
    [smallHeadlineOverride]="store.category ? 'What type of ' + store.category.name + ' business do you run?' : ''"
    (prev)="prev()"
    (next)="next()"
  >
    <app-tg-industry-subcategory-selector
      #innerSlide
      (prev)="prev()"
      (next)="next()"
      step="subcategory"
      [disableNextOnError]="true"
    ></app-tg-industry-subcategory-selector>
  </app-application-page>
</app-container>
