import { ChangeDetectorRef, Component, Input, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { ApplicationService } from '@eventhorizon/services/application.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { reaction, IReactionDisposer } from 'mobx';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { StatusLineComponent } from './status-line/status-line.component';
import { CarouselSlide } from '@eventhorizon/components/carousel-slide';
import { RatesAndFeesNotificationComponent } from '@eventhorizon/components/rates-and-fees-notification/rates-and-fees-notification.component';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { CartService } from '@eventhorizon/services/cart.service';
import { lastValueFrom, map, take } from 'rxjs';

@Component({
  selector: 'app-tg-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
})
export class VerificationComponent extends CarouselSlide implements OnDestroy {
  @ViewChildren(StatusLineComponent)
  protected statusLines: QueryList<StatusLineComponent>;

  @Input() public accordionConfig = {
    showYourNeeds: false,
    showYourSolution: false,
    showYourApp: false,
    showBussinessVerification: false,
  };

  public isLoading = true;

  public errorMessage: string;

  public allowToContinue: boolean;

  public needsToConfirmRatesAndFeesChange: boolean = false;

  public applicationId: string;

  public summaryConfig = {
    hideBusinessVerification: true,
  };

  private readonly applicationDisposer: IReactionDisposer;

  private readonly cartDisposer: IReactionDisposer;

  constructor(
    protected cd: ChangeDetectorRef,
    protected store: ApplicationStore,
    protected applicationService: ApplicationService,
    protected router: Router,
    protected bsModalService: BsModalService,
    protected cartStore: CartStore,
    protected cartService: CartService,
  ) {
    super(cd);
    this.applicationDisposer = reaction(
      () => !!this.store.isLoaded,
      () => {
        this.onOpen();
      },
    );
    this.cartDisposer = reaction(
      () => this.cartStore.cart,
      () => {
        this.checkIfRatesAndFeesChange();
      },
    );
  }

  ngOnDestroy() {
    this.applicationDisposer();
    this.cartDisposer();
  }

  public async onOpen() {
    if (!this.store.isLoaded) {
      return;
    }
    super.onOpen();
    this.errorMessage = undefined;
    if (this.store.id) {
      this.applicationId = this.store.id;
      await lastValueFrom(this.store.saveThreatMetrix());
      this.allowToContinue = true;
      this.isLoading = false;
    }
  }

  public checkIfRatesAndFeesChange() {
    if (!this.cartStore.cart) {
      return;
    }
    this.cartService.checkRatesAndFeesChange(this.cartStore?.cart?.id).then(res => {
      if (res.cartFeesMatchFiservFees === false) {
        this.needsToConfirmRatesAndFeesChange = true;
        const modalRef = this.bsModalService.show(RatesAndFeesNotificationComponent, {
          class: 'modal-lg',
          backdrop: 'static',
          keyboard: false,
          initialState: { applicationID: this.store.id },
        });

        modalRef.onHide
          .pipe(
            map(() => {
              this.needsToConfirmRatesAndFeesChange = false;
            }),
            take(1),
          )
          .subscribe();
      }
    });
  }

  public isNextDisabled() {
    return !this.allowToContinue || this.needsToConfirmRatesAndFeesChange;
  }

  public async onSecondaryAction() {
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }

  public isItemClickEnabled(): boolean {
    return this.store.businessLocations.length <= 1;
  }
}
