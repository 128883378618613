import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-prefill-start-dialog-component',
  templateUrl: './prefill-start-dialog.component.html',
  styleUrls: ['./prefill-start-dialog.component.scss'],
})
export class PrefillStartDialogComponent {
  constructor(private bsModalRef: BsModalRef) {}

  close() {
    this.bsModalRef.hide();
  }
}
