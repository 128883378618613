<div>
  <div
    *ngFor="let filter of filterService.filters"
    [class]="getClass(filter.id)"
    (click)="changeFilter(filter.id)"
    [attr.data-label]="'date-filter-' + filter.title"
  >
    <span>{{ filter.title }}</span>
  </div>
  <div class="date-filter date-filter-range-container">
    <form [formGroup]="form">
      <div class="input-group">
        <input
          #drp="bsDaterangepicker"
          [bsValue]="form.controls.dateRange.value"
          [readOnly]="true"
          class="form-control date-filter-range"
          type="text"
          formControlName="dateRange"
          placeholder="Custom Date Range"
          bsDaterangepicker
          placement="left"
          container="div.date-filter-range-container"
          [maxDate]="today"
          [bsConfig]="brandDatePicker"
          (bsValueChange)="filterCustomRange($event)"
          triggers="none"
        />
        <button
          class="btn date-filter-range-btnclear"
          type="button"
          (click)="drp.toggle()"
          data-label="date-filter-custom"
        >
          <i class="fal fa-calendar"></i>
        </button>
      </div>
    </form>
  </div>
</div>
