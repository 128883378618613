<div class="terms-text-container">
  <div class="mb-4">
    <app-callout-notification [notification]="signerDisclaimer"></app-callout-notification>
  </div>

  <ngx-extended-pdf-viewer
    *ngIf="!isLoading"
    [src]="fileSrc"
    height="400px"
    [sidebarVisible]="false"
    [showDrawEditor]="false"
    [showStampEditor]="false"
    [showHighlightEditor]="false"
    [showTextEditor]="false"
    [showRotateButton]="false"
    [showPrintButton]="false"
    [showOpenFileButton]="false"
    [filenameForDownload]="'MPA'"
    [attr.aria-busy]="isLoading"
  ></ngx-extended-pdf-viewer>
</div>

<form [formGroup]="form" #formElem>
  <div class="row mb-4 mt-5">
    <div class="col-12 px-0">
      <app-signers-dropdown
        #signerDropdown
        [options]="options"
        [contact]="contact"
        [businessOwners]="businessOwners"
        formArrayName="signerInfo"
        (dataEvent)="handleDataFromSignerForm($event)"
      ></app-signers-dropdown>
    </div>
  </div>
  
  <div *ngIf="shouldShow" class="row ic-plus-links-container">
    <div class="ic-plus-links-title col-12 mb-2 px-0">
      Review your <span class="fw-required">important documents</span> below. The signed MPA and additional documents will be sent to the selected signer's email.
    </div>
    <div *ngFor="let link of mpaLinks" class="col-12 pt-3 px-0">
      <a class="navigation-link ic-plus-links" [href]="link.href" target="_blank">{{ link.label }} <i class="far fa-arrow-right ps-2"></i></a>
    </div>
  </div>

  <div class="row pb-2">
    <div class="col-12 px-0">
      <div class="xup-checkbox">
        <input
          class="form-option-input"
          [class.form-option-input-error]="form?.invalid && form?.touched"
          type="checkbox"
          id="accept"
          formControlName="accept"
          value="accept"
          role="checkbox"
          tabindex="0"
          aria-labelledby="accept"
          aria-checked="false"
          required
        />
        <label class="form-option-label ps-3 pe-0 flex-grow-1" for="accept">
          <span class="fw-required">Required - </span> I certify that I am the individual listed
          within the "Signer" field, am authorized to contract on behalf of the this business, have read and agree to
          the above Terms & Conditions, and consent to receiving documents electronically.
        </label>
      </div>
      <div class="xup-checkbox" *ngIf="!ddaInterestDisclosureHidden">
        <input
          class="form-option-input"
          id="contact-info-disclosures"
          type="checkbox"
          formControlName="ddaInterestStatus"
          aria-labelledby="contactInfoDisclosures"
          (change)="updateDdaInterestDisclosure()"
        />
        <label
          for="contact-info-disclosures"
          class="form-option-label ps-3 pe-0 flex-grow-1 blue-link"
          [innerHTML]="ddaInterestDisclosure"
        ></label>
      </div>
    </div>
    <div *ngIf="errorMessage" class="col-12 form-error-message error-message">
      <p *ngFor="let error of errorMessage">
        {{ error }}
      </p>
    </div>
  </div>
</form>

<ng-template #preSubmitRef>
  <p class="pre-submit-description">
    You won't be able to make changes to your application after submission and the information provided will be used in the assessment of the application.
  </p>
</ng-template>
