<div *ngIf="isLoading; else loaded">
  <div class="lds-dual-ring"></div>
</div>

<ng-template #loaded>
  <div class="m-2" *ngIf="bosInformation">
    <h6>
      <strong>
        BOS/MCA Status
        <p *ngIf="bosInformation.formId">formID: {{ bosInformation.formId }}</p>
      </strong>
    </h6>
    <div class="logs-container">
      <ng-container *ngFor="let key of getBOSinformationIterableKeys">
        <div *ngIf="BOS_INFORMATION_TITLES[key]">
          <p>
            <i class="fas fa-circle-exclamation text-danger p-1"></i>
            <small>{{ BOS_INFORMATION_TITLES[key] }}: </small>
            <small>{{ bosInformation[key] || 'N/A' }}</small>
          </p>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="errorMessage" class="text-center">
    <p>{{ errorMessage }}</p>
  </div>
</ng-template>