<div class="row">
  <h3 class="physical-location pt-1 mb-3">Business Information</h3>
  <div class="col-md-12 mx-auto p-0">
    <form [formGroup]="form">
      <div class="row" [hidden]="hideBasedOnTinType()">
        <div class="col mb-3 p-0 position-relative">
          <input
            [id]="id + 5"
            formControlName="legalName"
            class="form-control"
            type="text"
            autocomplete="on"
            [maskito]="masks.companyName"
          />
          <label class="form-label" [for]="id + 5"> Legal Business Name </label>

          <app-field-error [control]="form.controls.legalName"></app-field-error>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 mb-3 p-0 position-relative">
          <span class="calendar-icon">
            <i class="fal fa-calendar"></i>
          </span>
          <input
            [id]="id + 8"
            formControlName="startDate"
            class="form-control calendar-input"
            type="text"
            autocomplete="on"
            bsDatepicker
            [minDate]="minDateOfIncorporation"
            [maxDate]="today"
            [maskito]="masks.date"
            #incorDate
            [bsConfig]="brandDatePicker"
            (keyup)="onDateFieldKeyChangePatchValue($event, form)"
          />
          <label class="form-label" [for]="id + 8"> Date of Incorporation </label>
          <app-field-error [control]="form.controls.startDate"></app-field-error>
        </div>

        <div class="col-sm-6 mb-3 pb-0 position-relative">
          <input
            [id]="id + 9"
            formControlName="businessPhone"
            class="form-control"
            type="text"
            autocomplete="tel"
            [maskito]="masks.phone"
          />
          <label class="form-label" [for]="id + 9"> Phone </label>

          <app-field-error [control]="form.controls.businessPhone"></app-field-error>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 mb-3 p-0 position-relative">
          <input [id]="id + 11" formControlName="businessEmail" class="form-control" type="text" autocomplete="email" />
          <label class="form-label" [for]="id + 11"> Email </label>

          <app-field-error [control]="form.controls.businessEmail"></app-field-error>
        </div>
        <div class="position-relative business-taxes-tin col-sm-12 col-md-6 mb-3 pb-0">
          <input
            [id]="id + 3"
            formControlName="tin"
            class="form-control"
            type="text"
            [maskito]="masks.taxIdAlt"
            autocomplete="on"
          />
          <label class="form-label" [for]="id + 3"> Tax Identification Number (TIN) </label>

          <app-field-error [control]="form.controls.tin"></app-field-error>
        </div>
      </div>

      <!-- Business Address -->
      <div class="col-12 p-0">
        <div class="row pb-2">
          <h3 class="physical-location pt-1 mb-2">Legal Business Address</h3>
        </div>
        <div class="row">
          <div class="col-12 p-0">
            <app-tg-address
              #legalAddress
              formGroupName="legalAddress"
              [(address)]="store.businessAddresses.legalAddress"
            ></app-tg-address>
          </div>
        </div>
      </div>

      <!-- ecommerce radio -->
      <div class="col-12 p-0 pb-2">
        <div class="row pb-2">
          <h3 class="online-platform pt-1 mb-2">Business Online Platform</h3>
        </div>
        <div class="online-platform-cards mb-2">
          <button class="radio-card" [class.selected]="websiteRequired === true" (click)="setWebsiteRequirements(true)">
            <div class="radio-card-circle">
              <i class="fa-solid fa-check"></i>
            </div>
            <div class="radio-card-text">
              <strong>I provide e-commerce services</strong>
              <div>Take payments through a website</div>
            </div>
          </button>
          <button class="radio-card" [class.selected]="websiteRequired === false" (click)="setWebsiteRequirements(false)">
            <div class="radio-card-circle">
              <i class="fa-solid fa-check"></i>
            </div>
            <div class="radio-card-text">
              <strong>I do not provide e-commerce services</strong>
              <div>Take payments only in physical locations</div>
            </div>
          </button>
        </div>
        <div *ngIf="websiteRequired" class="row">
          <div class="py-3 ps-0 website-text">Provide the website that serves as your e-commerce platform. 
            <app-tg-tooltip 
              [tooltipText]="'In order to take payments through a digital storefront you must provide us with a valid website address 
              where these transactions will take occur. Online payments of invoices is not considered a digital storefront.'">
            </app-tg-tooltip>
          </div>
          <div class="col-sm-6 mb-3 pb-0 ps-0 position-relative">
          <input [id]="id + 10" formControlName="website" class="form-control" type="text" autocomplete="url" />
          <label class="form-label" [for]="id + 10"> Website </label>
          <app-field-error [control]="form.controls.website"></app-field-error>
        </div>
        </div>
      </div>

      <!--Sales information-->
      <app-tg-payment-info #paymentInfo #regForm></app-tg-payment-info>

      <div class="row" *ngIf="bankInfoConfig?.businessInfoDisclosures?.text" id="bank-info-disclosures">
        <div class="col-sm-12">
          <div [ngClass]="['info-message', bankInfoConfig?.bankName]">
            <i [ngClass]="['info-message-icon', bankInfoConfig?.businessInfoDisclosures?.icon]" alt="Information Icon">
            </i>
            <p class="info-message-text" [innerHTML]="bankInfoConfig?.businessInfoDisclosures?.text"></p>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          *ngIf="errorMessage && errorMessage.length > 0"
          class="col-md-6 form-error-message error-message"
          id="error-messages"
        >
          Failed to save application
          <ul>
            <li *ngFor="let error of errorMessage">{{ error }}</li>
          </ul>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #preSubmitRef>
  <p class="pre-submit-description">
    Please note that by selecting the &#8220;I do not provide e-commerce services&#8221; option, you will need to reconfigure the Payment Channels
    section of all business locations where you indicated previously that you took payments via e-commerce.
  </p>
</ng-template>
