<form [formGroup]="form" #formElem *ngIf="form">
  <div class="tg-paymenttype-container">
    <div *ngFor="let pc of categories">
      <div
        class="tg-paymenttype-category"
        id="{{ pc.controlName }}-category-card"
        [ngClass]="{ 'is-selected': +form.controls[pc.controlName].value > 0 }"
      >
        <app-tg-tooltip [tooltipText]="pc.subtext">
          <div class="tg-paymenttype-icon"><i [class]="'fal ' + pc.icon"></i></div>
          <div class="tg-paymenttype-heading" id="{{ pc.controlName }}-label">{{ pc.heading }}</div>
          <div class="tg-paymenttype-percent">
            <app-tg-input-spinner
              [form]="form"
              [mask]="masks.percentage"
              [controlName]="pc.controlName"
              [control]="form.controls[pc.controlName]"
              (changeQuantity)="onPercentageChange($event, pc.controlName)"
              [quantity]="10"
              [decrementDisabled]="+form.controls[pc.controlName].value <= 0"
              [incrementDisabled]="+form.controls[pc.controlName].value >= 100 || getTotalPercentage() >= 100"
              [type]="'percent'"
              [step]="5"
              [maxLength]="3"
              [useInputTimeout]="false"
              customClass="paymenttype"
            >
            </app-tg-input-spinner>
          </div>
        </app-tg-tooltip>
        <div class="tg-paymenttype-subtext">of total card sales</div>
      </div>
    </div>
  </div>
  <input type="hidden" [id]="id" formControlName="totalChannels" />
  <div *ngIf="hideTotalChannelsError()" class="text-end">
    <app-field-error [control]="totalChannels"></app-field-error>
  </div>
  <div *ngIf="errorMessage" class="col-sm-12 form-error-message error-message">
    {{ errorMessage }}
  </div>
</form>
