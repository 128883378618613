import { Component, OnInit } from '@angular/core';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss'],
})
export class Error404Component implements OnInit {
  constructor(private contentService: ContentServiceService, public sanitizer: DomSanitizer) {}

  public bankName: string;

  ngOnInit(): void {
    this.bankName = this.contentService?.bankInfoConfig?.name;
  }

  safeBankUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl((url || '').trim());
  }
}
