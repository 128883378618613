<header class="page-main-header">
  <nav *ngIf="config" class="navbar col-sm-12">
    <div class="row">
      <div class="nav-logo d-inline-flex me-2" *ngFor="let logo of config?.logos">
        <div>
          <a
            class="navbar-brand"
            [href]="logo?.linkUrl ? logo?.linkUrl : contentService?.bankInfoConfig?.returnLink"
            target="_blank"
          >
            <img [src]="logo?.url" class="nav-logo-item" [alt]="logo?.description" />
          </a>
        </div>
        <div *ngIf="partnerLogo && showPartnerLogo" id="navbar-brand-logo">
          <div>
            <span class="navbar-brand-logo-text">Referred by</span>
            <img [src]="partnerLogo" alt="Partner Referral Logo" />
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-lg-inline-block">
      <ul class="navbar-nav">
        <li
          class="nav-item"
          *ngFor="let option of config?.navigation"
          [ngClass]="{ 'nav-item-highlighted': option?.isHighlighted }"
        >
          <a class="nav-link" (click)="doAction(option)">
            <span class="nav-link-label" *ngIf="option?.displayText"
              >{{ option.displayText }} &nbsp;<i [class]="'fas ' + option?.iconClass"></i>
            </span>
          </a>
        </li>
      </ul>
    </div>
    <div class="d-inline-block d-lg-none">
      <ul class="navbar-nav">
        <li
          class="nav-item"
          *ngFor="let option of config?.navigation"
          [ngClass]="{ 'nav-item-highlighted': option?.isHighlighted }"
        >
          <a class="nav-link" (click)="doAction(option)">
            <span class="nav-link-label" *ngIf="option?.displayText"
              >{{ option.displayText }} &nbsp;<i [class]="'fas ' + option?.iconClass"></i>
            </span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</header>
