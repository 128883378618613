<span class="switch" [class.checked]="checked" [class.disabled]="disabled" [class.switch-large]="size === 'large'"
    [class.switch-medium]="size === 'medium'" [class.switch-small]="size === 'small'"
    [class.switch-labeled]="!!labelOn || !!labelOff">
    <input type="checkbox" id="enabled" name="enabled" [checked]="checked" style="display: none;" aria-invalid="false">
    <small>
    </small>
    <span class="switch-text" *ngIf="!!labelOn || !!labelOff">
        <span class="on" [innerHtml]="labelOn"></span>
        <span class="off" [innerHtml]="labelOff"></span>
    </span>
</span>