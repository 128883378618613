import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ProductService } from '@eventhorizon/services/product.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IReactionDisposer, reaction } from 'mobx';
import { RoutingPathService } from '@eventhorizon/services/routing-path.service';
import { BusinessTypeController } from '@eventhorizon/controllers/business-type.controller';
import { BsModalService } from 'ngx-bootstrap/modal';
import { lastValueFrom, Subject } from 'rxjs';
import { MobxFormControl } from '../mobx-form-control/mobx-form-control';
import { SavePopupComponent } from '../save-popup/save-popup.component';
import { FormCarouselSlide } from '../form-carousel-slide';
import { Category } from '@eventhorizon/models/category.model';
import { MCCSearchResult } from '@eventhorizon/models/product.model';
import { commonErrors } from '@eventhorizon/constants/general.constants';
import { IndustryController } from '@eventhorizon/controllers/industry.controller';

@Component({
  selector: 'app-tg-business-type',
  templateUrl: './business-type.component.html',
  styleUrls: ['./business-type.component.scss'],
})
export class BusinessTypeComponent
  extends FormCarouselSlide<FormGroup<IBusinessTypeForm>>
  implements OnInit, OnDestroy {
  public category: MobxFormControl;

  public industry: MobxFormControl<string>;

  public mccAdditionalDetails: MobxFormControl<string>;

  public mcc: MobxFormControl<string>;

  public mccDescription: MobxFormControl<string>;

  public errorMessage: string;

  public searchMCC: FormControl<string> = new FormControl<string>('');

  public categories: Category[];

  public isLoading = false;

  public mccOptions: MCCSearchResult[] = [];

  private destroy: Subject<void> = new Subject();

  private disposers: IReactionDisposer[] = [];

  private noProducts = {
    mcc: null,
    description: 'No results found',
    industryDescription: 'Please try another search term',
  };

  @Input() label = 'Start typing to search';

  @Input() hideMccDescription = false;

  constructor(
    public store: ApplicationStore,
    public cd: ChangeDetectorRef,
    protected fb: FormBuilder,
    protected bsModalService: BsModalService,
    protected productService: ProductService,
    protected routingPathService: RoutingPathService,
    public businessTypeController: BusinessTypeController,
    private industryController: IndustryController,
  ) {
    super(bsModalService, cd);
  }

  ngOnInit(): void {
    this.buildForm();
    this.searchMCC.valueChanges
      .pipe(takeUntil(this.destroy), distinctUntilChanged(), debounceTime(500))
      .subscribe(this.search.bind(this));
  }

  public onOpen() {
    super.onOpen();
    if (this.store.mcc) {
      this.search(this.store.mcc);
    }
  
    this.cd.detectChanges();
  }

  selectOption(option: MCCSearchResult): void {
    if (option.mcc) {
      this.mcc.setValue(option.mcc);
      this.mccDescription.setValue(option.description);
      this.industry.setValue(option.industryDescription);
    }
  }

  public async preOnPrev(): Promise<boolean> {
    const hasSubCategories = !!(await this.industryController.industrySubCategories());
    if (!hasSubCategories) {
      this.routingPathService.deactivateRoute('industry-subcategory');
    }
    return true;
  }

  public async save(): Promise<boolean> {
    this.errorMessage = undefined;
    if (this.mccAdditionalDetails.disabled) {
      this.store.mccAdditionalDetails = '';
    }
    try {
      await lastValueFrom(this.store.saveBusinessType());
      return true;
    } catch {
      this.errorMessage = commonErrors.failedToSaveInfo;
      return false;
    }
  }

  public async preOnNext(): Promise<boolean> {
    return this.save();
  }

  public async onSecondaryAction() {
    if (this.form.valid) {
      await this.save();
    }
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }

  protected search(term: string) {
    if (!term) {
      this.mccOptions = [];
      this.form.reset({ mcc: null });
    } else {
      this.productService
        .getMCC(term)
        .pipe(takeUntil(this.destroy))
        .subscribe((results: MCCSearchResult[]) => {
          if (results.length) {
            this.mccOptions = results;
            return;
          }

          this.mccOptions = [this.noProducts];
        });
    }
  }

  protected buildForm() {
    const s = this.store;

    this.mcc = new MobxFormControl<string>(
      'mcc',
      () => s.mcc,
      (v: string) => (s.mcc = v),
      Validators.required,
    );
    this.mccDescription = new MobxFormControl<string>(
      'mccDescription',
      () => s.mccDescription,
      (v: string) => {
        s.mccDescription = v;
        this.searchMCC.setValue(v, { emitEvent: false });
      },
      Validators.required,
    );
    this.mccAdditionalDetails = new MobxFormControl<string>(
      'mccAdditionalDetails',
      () => s.mccAdditionalDetails,
      (v: string) => (s.mccAdditionalDetails = v),
      this.hideMccDescription ? null : Validators.required,
    );
    this.industry = new MobxFormControl<string>(
      'industry',
      () => s.industry,
      (v: string) => (s.industry = v),
      Validators.required,
    );

    this.searchMCC.setValidators(Validators.required);

    this.setControls();

    this.mccAdditionalDetails.setEnabled(
      this.hideMccDescription ? this.hideMccDescription : this.store.showMccDescription,
    );
    this.disposers.push(
      reaction(
        () => !!this.store.isLoaded && this.store.mcc,
        (mcc: string) => {
          this.search(mcc);
        },
      ),

      reaction(
        () => !!this.store.isLoaded && this.store.showMccDescription,
        (show: boolean) => {
          this.mccAdditionalDetails.setEnabled(this.hideMccDescription ? this.hideMccDescription : show);
        },
      ),

      reaction(
        () => !!this.store.isLoaded && this.store.mccDescription,
        (mccDescription: string) => {
          if (!mccDescription) return;
          this.searchMCC.setValue(mccDescription, { emitEvent: false });
        },
      ),
    );
  }

  public setControls() {
    this.form = this.fb.group({
      mcc: this.mcc,
      mccDescription: this.mccDescription,
      mccAdditionalDetails: this.mccAdditionalDetails,
    });
  }

  ngOnDestroy(): void {
    for (const disposer of this.disposers) {
      if (disposer) disposer();
    }
    this.destroy.next();
    this.destroy.complete();
  }
}

export interface IBusinessTypeForm {
  mcc: FormControl<string>;
  mccDescription: FormControl<string>;
  mccAdditionalDetails: FormControl<string>;
}
