import { AfterViewInit, Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 'app-tg-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TooltipComponent implements AfterViewInit {

  @ViewChild(TooltipDirective) tooltip: TooltipDirective;

  @ViewChild('content', { read: ElementRef, static: true }) set content(ele: ElementRef) {
    this.setHasContent(ele);
    this.restartTooltip();
  }

  @Input() tooltipText = '';

  @Input() isBodyContainer = true;

  @Input() isInsideAnotherContainer = false;

  hasContent = false;

  ngAfterViewInit(): void {
    this.restartTooltip();
  }

  private restartTooltip() {
    this.tooltip?.ngOnInit();
  }

  private setHasContent(ele: ElementRef) {
    this.hasContent = !!ele.nativeElement.innerHTML;
  }
}
