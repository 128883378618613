<div [style.text-align]="textBlock?.alignment ? textBlock?.alignment : 'left'">
    <h1 *ngIf="textBlock?.heading"
        class="content-page-heading"
        [ngClass]="textBlock?.headingColor">
        {{textBlock?.heading}}
    </h1>
    <h2 *ngIf="textBlock?.subheading"
        class="content-page-text-strong">
        {{textBlock?.subheading}}
    </h2>
    <app-tg-markdown *ngIf="textBlock?.text"
        class="content-page-text-primary" [data]='textBlock?.text'>
    </app-tg-markdown>
</div>
