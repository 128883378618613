<div id="mainContent" tabindex="0" class="main-container container has-header resume-container">
  <section *ngIf="!tokenExpired && !twoFAShow" class="text-center">
    <header class="col-12">
      <div class="resume-heading-container">
        <h1 class="resume-heading">Complete Your Application</h1>
        <h3 class="resume-subheading">
          You have just a few more steps to submit your order and complete your merchant application.
        </h3>
      </div>
    </header>
    <div class="row">
      <article class="col-12 col-md-4 resume-description-block">
        <i class="far fa-lock-keyhole"></i>
        <p>
          Your application is secured via two-factor verification for an extra layer of protection. Continue to verify
          your identity and resume your application.
        </p>
      </article>
      <article class="col-12 col-md-4 resume-description-block">
        <i class="far fa-copy"></i>
        <p>
          Review your order and fill in any missing information on your application. You will be redirected to the right
          next step based on your progress so far.
        </p>
      </article>
      <article class="col-12 col-md-4 resume-description-block">
        <i class="far fa-square-check"></i>
        <p>
          Sign and submit your merchant processing agreement to start taking payments as soon as today with an approved
          account. <sup>1</sup>
        </p>
      </article>
    </div>
    <footer class="d-flex justify-content-center mt-5">
      <button data-testid="resume-btn" class="xup-primary-button" (click)="twoFactorInit()">
        Resume Application
      </button>
    </footer>
  </section>

  <app-two-factor-auth
    *ngIf="!tokenExpired && twoFAShow"
    [accessToken]="accessToken"
    (resumeApp)="resume()"
    (requestLink)="requestAppLink()"
  ></app-two-factor-auth>

  <div *ngIf="tokenExpired" class="row container-inner">
    <div class="col-12 expired">
      <p class="pb-4">
        <img [src]="logo" alt="Bank Logo" />
      </p>
      <div class="expired-title">Check Your Email</div>
      <div class="expired-link">Your session has timed out.</div>
      <div class="expired-link">
        To resume your application, please return to the same email used prior and request a new link.
      </div>
    </div>
  </div>
</div>

<div *ngIf="!tokenExpired && !twoFAShow" class="row container-inner resume-disclosure p-0">
  <p class="col-12 px-0">
    <sup>1</sup> The ability to begin taking payments the same day as your application is dependent on credit and
    underwriting approval.
  </p>
</div>
