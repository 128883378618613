/* eslint-disable @angular-eslint/directive-selector */
import { NgControl } from '@angular/forms';
import { Directive, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[disableControl]',
})
export class DisableControlDirective implements OnChanges {
  @Input() disableControl: boolean;
  
  constructor(private ngControl: NgControl) {}

  ngOnChanges(changes: any) {
    if (changes?.disableControl) {
      const action = this.disableControl ? 'disable' : 'enable';

      this.ngControl.control[action]();
    }
  }
}