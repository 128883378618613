<section class="row">
  <div *ngIf="isLoading" class="col-12 d-flex justify-content-center">
    <div class="lds-dual-ring"></div>
  </div>
  <!--Error Link Expired-->
  <div *ngIf="showErrorMessage()" class="col-12 two-factor-container d-flex flex-column align-items-center">
    <p class="pb-4">
      <img [src]="logo" alt="Bank Logo" />
    </p>
    <p class="two-factor-error-message-heading">{{ this.errorMessageHeading }}</p>
    <p class="two-factor-error-message">{{ this.errorMessage }}</p>
    <button class="xup-primary-button mt-5" (click)="onRequestLink()">Request Another Link</button>

    <div *ngIf="showRequestErrorMessage()" class="two-factor-request-link-error">
      {{ this.requestAppLinkError }}
    </div>
  </div>

  <!-- Request Link Confirmation-->
  <div *ngIf="requestSent" class="col-12 two-factor-container">
    <p class="pb-4">
      <img [src]="logo" alt="Bank Logo" />
    </p>
    <p class="two-factor-error-message-heading">Check Your Email</p>
    <p class="two-factor-error-message">Your new Resume Application link has been sent.</p>
    <p class="two-factor-error-message">
      If you do not receive this link to your email, please contact help&#64;xuppay.com.
    </p>
  </div>

  <div *ngIf="showTypeSelection()" class="col-12 two-factor-container">
    <header class="row two-factor-heading-container">
      <div class="col-12">
        <h1 class="two-factor-heading">Choose a way to verify</h1>
        <h3 class="two-factor-subheading">How would you like to get your verification code?</h3>
      </div>
    </header>
    <section class="col-12">
      <div
        tabindex="0"
        *ngFor="let type of twoFactorTypes"
        class="row two-factor-type"
        [ngClass]="{ 'two-factor-type-selected': isSelected(type.id) }"
        (keydown.enter)="selectType(type.id)"
        (click)="selectType(type.id)"
      >
        <div class="col-2 col-sm-1 col-md-2 col-lg-1 two-factor-type-icon">
          <i [class]="type.iconClass"></i>
        </div>
        <div class="col-8 col-sm-10 col-md-8 col-lg-10 text-start">
          <p class="two-factor-type-name">{{ type.name }}<span *ngIf="type.id === 'sms'">*</span></p>
          <p class="two-factor-type-description">
            {{ type.description }}<span class="fw-bold">{{ applicantEmailPhone[type.infoName] }}</span>
          </p>
        </div>
        <div
          class="col-2 col-sm-1 col-md-2 col-lg-1 two-factor-type-icon"
          [ngClass]="{ 'two-factor-type-icon-selected': isSelected(type.id) }"
        >
          <i [ngClass]="{ 'far fa-circle': !isSelected(type.id), 'fas fa-check-circle': isSelected(type.id) }"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="two-factor-type-phone-disclaimer">
            * By providing your cell phone number you consent to receive a one-time verification code via an automatic
            telephone dialing system. Message and data rates may apply.
          </p>
        </div>
      </div>
    </section>
    <footer class="col-12">
      <button class="xup-primary-button mt-5" [disabled]="!this.form.valid" (click)="submitTypeForCode()">
        Send Code
      </button>
    </footer>
  </div>
  <div *ngIf="showCodeInsertion()" class="col-12 two-factor-container">
    <header class="row two-factor-heading-container">
      <div class="col-12">
        <h1 class="two-factor-heading">Enter Your Code</h1>
        <h3 class="two-factor-subheading">
          To continue, enter the verification code we sent to
          <span class="fw-bold">{{ typeValueFromSelected }}</span> <br />Your code will be valid for 10 minutes.
        </h3>
      </div>
    </header>
    <section class="col-12 two-factor-code">
      <form [formGroup]="codeForm" (submit)="submitCode()">
        <div class="mb-3 position-relative two-factor-code-input">
          <input
            #focus
            [id]="id"
            type="text"
            maxlength="6"
            class="form-control"
            [maskito]="mask"
            formControlName="twoFACode"
          />
          <label class="form-label" [for]="id">6-digit code</label>
          <app-field-error [control]="codeForm.controls.twoFACode"></app-field-error>
          <div *ngIf="codeErrorMessage !== ''">
            <p class="form-error-message" role="alert">
              {{ codeErrorMessage }}
            </p>
          </div>
        </div>
      </form>
    </section>
    <section class="col-12">
      <p class="two-factor-code-resend">
        Didn't get it?
        <button type="button" class="btn btn-link two-factor-code-resend-btn" (click)="back()">
          Resend Code <i class="far fa-angle-right"></i>
        </button>
      </p>
    </section>
    <footer class="col-12">
      <button class="xup-primary-button mt-5" [disabled]="!this.codeForm.valid" (click)="submitCode()">
        Verify
      </button>
    </footer>
  </div>
</section>
