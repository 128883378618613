<label
  [for]="id"
  class="align-items-center radio-card p-2 no-gutters"
  [class.is-selected]="formControlRef.value === value"
>
  <div class="d-flex align-items-center">
    <input
      type="checkbox"
      [id]="id"
      [name]="radioName"
      [checked]="formControlRef.value === value"
      (change)="setValue($event)"
    />
  </div>
  <div [id]="id + 1" class="text-start text-content px-2">
    <p class="title">{{ title }}</p>
    <p class="subtitle">{{ subtitle }}</p>
  </div>
  <div [id]="id + 2" class="text-end price" [class.item]="subtitle" *ngIf="displayPrice">
    <p class="fw-bold">{{ price | currency }}</p>
  </div>
</label>
