<div class="panel">
  <div class="modal-body">
    <h1 id="modal-title" class="text-center" *ngIf="title">
      {{ title }}
    </h1>
    <h6 id="modal-subtitle" class="modal-title text-center" *ngIf="subtitle">{{ subtitle }}</h6>
    <div class="d-flex justify-content-center">
      <div class="lds-dual-ring mt-4"></div>
    </div>
  </div>
</div>
