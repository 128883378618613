import { Injectable, QueryList } from '@angular/core';
import { MobxFormControl } from '@eventhorizon/components/mobx-form-control/mobx-form-control';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { LocationsService } from '@eventhorizon/services/locations.service';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { clearAddress, copyAddress, isAddressEmpty, Address } from '@eventhorizon/models/address.model';
import { BusinessOwner } from '@eventhorizon/models/business-owner.model';
import { ShippingLocations } from '@eventhorizon/models/shipping-locations.model';
import { ShippingLocationComponent } from '../../public-api';
import { IShippingLocationForm } from '@eventhorizon/components/shipping-location/shipping-location.component';
import { isNumber } from '@eventhorizon/utils/util';

@Injectable({
  providedIn: 'root',
})
export class ProductShippingLocationsController {
  public primarySelected: MobxFormControl<string>;

  public isPrimarySelected = true;

  private selectedAddress: BehaviorSubject<string> = new BehaviorSubject('');

  public selectedAddress$: Observable<string> = this.selectedAddress.asObservable();

  public showShippingForm = false;

  public ownerNames = [];

  public constructor(
    private fb: FormBuilder,
    public store: ApplicationStore,
    private locationsService: LocationsService,
  ) {}

  public buildForm(): FormGroup<IProductShippingLocationForm> {
    this.primarySelected = new MobxFormControl<string>(
      'primarySelected',
      () => (this.isPrimarySelected ? 'true' : ''),
      (v: string) => {
        this.isPrimarySelected = !!v;
      },
      Validators.required,
    );

    return this.fb.group({
      primarySelected: this.primarySelected,
    });
  }

  public buildPropertyForm(
    shippingLocations: QueryList<ShippingLocationComponent>,
    form: FormGroup<IProductShippingLocationForm>,
  ): void {
    this.isPrimarySelected = false;
    shippingLocations.map(sa => {
      form.setControl('shippingLocation', sa.form);
      const sl = this.store.businessLocations[0].productShippingAddress;
      if (sl) this.isPrimarySelected = this.isPrimarySelected;
      shippingLocations.toArray()[0].onOpen();
      form.setControl('shippingLocation', shippingLocations.toArray()[0].form);
    });
  }

  private copyStoredAddresses(addresses: Map<string, Address>): number[] {
    const ownerIndexes: number[] = [];
    const legalAddress = new Address();
    const locationAddress = new Address();
    const ownerNames = [];
    copyAddress(this.store.businessAddresses.legalAddress, legalAddress);
    this.setSelectedIfChecks('legalAddress', legalAddress);

    if (this.hasNull(legalAddress)) {
      addresses.set('legalAddress', new Address());
    } else {
      addresses.set('legalAddress', legalAddress);
    }

    if (this.store.businessLocations[0]) {
      this.store.businessLocations.forEach(location => {
        if (location.isPrimary) {
          copyAddress(location.address, locationAddress);
        }
      });

      if (this.hasNull(locationAddress)) {
        addresses.set('locationAddress', new Address());
      } else {
        addresses.set('locationAddress', locationAddress);
      }

      this.setSelectedIfChecks('locationAddress', locationAddress);

      this.store.owners.forEach((owner, index) => {
        const ownerAddress = new Address();
        copyAddress(owner.address, ownerAddress);

        if (this.hasNull(ownerAddress)) {
          addresses.set(`Owner ${index + 1}`, new Address());
        } else {
          addresses.set(`Owner ${index + 1}`, ownerAddress);
        }

        ownerIndexes.push(index);
        ownerNames.push(owner.firstName + ' ' + owner.lastName);
        if (!this.selectedAddress.value) this.setSelectedIfChecks(`Owner ${index + 1}`, ownerAddress, owner);
      });

      if (!this.selectedAddress.value) this.setSelectedIfChecks('None');
    }
    return ownerIndexes;
  }

  public loadInfo(addresses: Map<string, Address>) {
    const ownerIndexes = this.copyStoredAddresses(addresses);
    return ownerIndexes;
  }

  public loadOwnerNames(owners) {
    const ownerNames = [];

    owners.forEach(owner => {
      ownerNames.push(owner.firstName + ' ' + owner.lastName);
      this.ownerNames = ownerNames;
    });
    return ownerNames;
  }

  private setSelectedIfChecks(key: string, addressToCheck?: Address, owner?: BusinessOwner): void {
    if (isAddressEmpty(this.store.businessLocations[0].productShippingAddress?.address)) return;

    const shippingLocation = this.store.businessLocations[0].productShippingAddress;
    const storedAddress = this.getAddressFormatted(shippingLocation.address);
    const addressToCheckFormatted = this.getAddressFormatted(addressToCheck);
    if (key === 'None' && this.selectedAddress.value === '' && !isAddressEmpty(shippingLocation.address)) {
      this.selectedAddress.next(key);
      this.showShippingForm = true;
      return;
    }

    if (owner && this.checkName(shippingLocation, owner) && storedAddress === addressToCheckFormatted) {
      this.selectedAddress.next(key);
    } else if (storedAddress === addressToCheckFormatted) {
      if (!this.selectedAddress.value.includes('Owner')) {
        this.selectedAddress.next(key);
      }
    }
  }

  public isLocationAddressSameAsLegalAddress(legalAddress: Address, locationAddress: Address): boolean {
    // Both formatted Address are set to lowercase to minimize the chance of a false negative due to capitalization.
    return (
      this.getAddressFormatted(legalAddress).toLowerCase() === this.getAddressFormatted(locationAddress).toLowerCase()
    );
  }

  public isAddressEmpty(name: string, addresses: Map<string, Address>): boolean {
    const address = addresses.get(name);
    return isAddressEmpty(address);
  }

  public hasNull(address: Address): boolean {
    for (const key in address) {
      if (address[key] === null || address[key] === undefined) {
        if (key !== 'address2') {
          return true;
        }
      }
    }
    return false;
  }

  public getAddressFormatted(address: Address): string {
    if (!address || isAddressEmpty(address)) return '';
    let singleLineAddress = address.address1;
    if (address.address2 && address.address2 !== '') {
      singleLineAddress = `${address.address1}, ${address.address2}`;
    }
    return `${singleLineAddress}, ${address.city}, ${address.state} ${address.zip}`;
  }

  private checkName(a: ShippingLocations, b: BusinessOwner): boolean {
    return a.firstName === b.firstName && a.lastName === b.lastName;
  }

  async saveStoreChanges() {
    await lastValueFrom(
      this.store.updateSubmerchant(
        this.locationsService.currentLocationId,
        this.store.businessLocations[this.locationsService.currentLocationIndex],
      ),
    );
  }

  public resetController() {
    this.isPrimarySelected = true;
    this.selectedAddress.next('');
    this.showShippingForm = false;
  }

  public getAddressFormattedFromMap(name: string, addresses: Map<string, Address>): string {
    const address = addresses.get(name);
    return this.getAddressFormatted(address);
  }

  public selectAddress(
    name: string,
    shippingLocations: QueryList<ShippingLocationComponent>,
    addresses: Map<string, Address>,
    index?: number,
  ) {
    const shippingLocation = shippingLocations.toArray()[0];
    this.selectedAddress.next(name);
    if (shippingLocation.form.controls.companyName.value === '') {
      shippingLocation.form.controls.companyName.setValue(this.store.businessInfo.dbaName);
    }
    if (name.includes('Owner') && isNumber(index) && index >= 0) {
      shippingLocation.form.controls.firstName.setValue(this.store.owners[index].firstName);
      shippingLocation.form.controls.lastName.setValue(this.store.owners[index].lastName);
    } else {
      shippingLocation.form.controls.firstName.setValue(this.store.applicantInfo.name);
      shippingLocation.form.controls.lastName.setValue(this.store.applicantInfo.lastName);
    }
    if (name !== 'None') {
      this.showShippingForm = false;
      copyAddress(addresses.get(name), shippingLocation.shippingLocation.address);
      shippingLocation.form.patchValue({ personalAddress: addresses.get(name) });
    } else {
      clearAddress(shippingLocation.shippingLocation.address);
      this.showShippingForm = true;
    }
  }

  public isAddressSelected(name: string): boolean {
    return this.selectedAddress.value === name;
  }
}

export interface IProductShippingLocationForm {
  primarySelected: FormControl<string>;
  shippingLocation?: FormGroup<IShippingLocationForm>;
}
