import { Component, Input } from '@angular/core';
import { AuditLogService } from '@eventhorizon/services/audit-log.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-rates-and-fees-notification',
  templateUrl: './rates-and-fees-notification.component.html',
  styleUrls: ['./rates-and-fees-notification.component.scss'],
})
export class RatesAndFeesNotificationComponent {
  public hasError: boolean = false;

  public isLoading: boolean = false;

  @Input() applicationID: string;

  constructor(private modalRef: BsModalRef, private auditLogService: AuditLogService) {}

  public async confirmChanges() {
    this.isLoading = true;
    try {
      await lastValueFrom(this.auditLogService.confirmRatesAndFeesChange(this.applicationID));
      this.modalRef.hide();
    } catch (error) {
      this.hasError = true;
    }
  }
}
