import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnersService } from '@eventhorizon/services/partners.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { IReactionDisposer, reaction } from 'mobx';
import { lastValueFrom, throwError } from 'rxjs';
import { CartStore } from '@eventhorizon/stores/cart.store';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';
import { LocationsService } from '@eventhorizon/services/locations.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CampaignInformation } from '@eventhorizon/models/referral.model';
import { PartnerProductsCarouselConfig } from '@eventhorizon/models/bank-info-config.model';
import { commonErrors, defaultPartnerContentName } from '@eventhorizon/constants/general.constants';
import { ROUTES } from '@eventhorizon/constants/routes.constants';
import { ChatHandlerService } from '@eventhorizon/services/miramar/chatbox.service';
import { InactivePartnerPopupComponent } from './inactive-partner-popup/inactive-partner-popup.component';
import { PartnerStore } from '@eventhorizon/stores/partner.store';

@Component({
  selector: 'app-referred-merchant-screen',
  templateUrl: './referred-merchant-screen.component.html',
  styleUrls: ['./referred-merchant-screen.component.scss'],
})
export class ReferredMerchantScreenComponent implements OnInit, OnDestroy {
  private partnerId: string;

  private partnerSessionName: string = 'partner_info';

  public errorMessage: string;

  public contactFormStatus: 'initial' | 'show' | 'success' = 'initial';

  public loading: boolean = false;

  public partnerName: string;

  public partnerLogo: string;

  public partnerLoaded: boolean = false;

  public partnerType: string = '';

  public isHBABranding: boolean = false;

  public isPMTABranding: boolean = false;

  public disablePartnerSelfOnboarding: boolean = false;

  public disableCaptcha: boolean = false;

  private dispose: IReactionDisposer;

  public partnerContent: PartnerProductsCarouselConfig;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private partnersService: PartnersService,
    private cartStore: CartStore,
    private store: ApplicationStore,
    private partnerStore: PartnerStore,
    private locationsService: LocationsService,
    public contentService: ContentServiceService,
    private bsModalService: BsModalService,
    private chatService: ChatHandlerService,
  ) {
    this.dispose = reaction(
      () => this.store.isLoaded,
      async () => {
        await this.initialPartnerLoading();
      },
    );
  }

  private async initialPartnerLoading() {
    if (!this.store.isLoaded) {
      this.hideSplashScreen();
      return;
    }
    if (!this.locationsService.currentLocation) {
      this.locationsService.initLocationsService();
    }
    let partnerSlug = this.route.snapshot.paramMap.get('slug');
    partnerSlug = decodeURIComponent(partnerSlug);
    partnerSlug = partnerSlug.includes('?') ? partnerSlug.substring(0, partnerSlug.indexOf('?')) : partnerSlug;
   
    await this.loadPartner(partnerSlug);
  }

  private saveReferral(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.store.partnerReferenceId) {
        resolve();
        return;
      }

      this.partnersService.saveReferral(this.partnerId, this.store.id).subscribe(
        async () => {
          await lastValueFrom(this.cartStore.loadCart(true));
          resolve();
        },
        error => {
          if (error.error.statusCode === 400) {
            const inactivePartnerMessage = !!error.error.errors.length ? error.error.errors[0] : '';
            const initialState = { inactivePartnerMessage: inactivePartnerMessage };
            this.bsModalService.show(InactivePartnerPopupComponent, { initialState: initialState });
            resolve();
          }
          reject();
        },
      );
    });
  }

  hideSplashScreen() {
    setTimeout(() => {
      let splash = document.getElementById('splashScreen');
      if (splash) {
        splash.style.opacity = '0';
        setTimeout(() => {
          splash.remove();
        }, 500);
      }
    }, 1000);
  }

  async ngOnInit() {
    await this.initialPartnerLoading();
  }

  async loadPartner(partnerSlug: string) {
    if (!partnerSlug) {
      await this.redirectToGetStarted();
      return;
    }

    try {
      const partnersArray = await lastValueFrom(this.partnersService.getPartnerBySlug(partnerSlug));
      if (!partnersArray.length) throwError('Partner not found');
      const partner = partnersArray[0];
      this.partnerId = partner.id;
      this.isHBABranding = this.partnerId.toUpperCase() == 'D1755819-40F0-4F65-A3CD-B5BEB6ACDBD8';
      this.isPMTABranding = this.partnerId.toUpperCase() == '15E30F5A-1226-4FD8-BA58-65FE18C9AFDB';
      const partnerData = await this.partnerStore.getPartnerById(partner.id);
      this.partnerName = partnerData.businessName;
      this.partnerLogo = partnerData.imageBase64 ? `data:image/png;base64,${partnerData.imageBase64}` : null;
      this.disableCaptcha = partnerData.disableCaptcha;
      this.disablePartnerSelfOnboarding = partnerData.disableSelfOnboarding;
      this.chatService.setDisplayMode(partnerData.disableChat ? 'none' : 'block');
      this.partnerType = partnerData.partnerType;

      const storageInfo = {
        slug: partnerSlug,
        logo: this.partnerLogo,
      };
      sessionStorage.setItem(this.partnerSessionName, JSON.stringify(storageInfo));

      await this.saveReferral();
      await this.productsCarouselContent(partnerSlug);
      this.saveCampaignInfo();
    } catch {
      await this.redirectToGetStarted();
    } finally {
      this.partnerLoaded = true;
      this.hideSplashScreen();
    }
  }

  private saveCampaignInfo() {
    let url = new URL(decodeURIComponent(window.location.origin + this.router.url));
    const campaignInformation: CampaignInformation = {
      externalCustomerId: url.searchParams.get('externalCustomerId'),
      sourceType: url.searchParams.get('sourceType'),
      sourceId: url.searchParams.get('sourceId'),
      utmMedium: url.searchParams.get('utm_medium'),
      utmSource: url.searchParams.get('utm_source'),
      utmCampaign: url.searchParams.get('utm_campaign'),
      utmTerm: url.searchParams.get('utm_term'),
    };
    this.store.updateCampaignInformation(campaignInformation);
  }

  public handleClick() {
    this.router.navigate([ROUTES.CONTACT_INFO]);
  }

  public showContactForm() {
    this.contactFormStatus = 'show';
  }

  public async submitContactForm(): Promise<boolean> {
    this.errorMessage = '';
    try {
      this.loading = true;
      this.store.callBackRequested = true;
      await lastValueFrom(
        this.store.updateSubmerchant(this.store.businessLocations[0].id, this.store.businessLocations[0]),
      );
      await lastValueFrom(
        this.store.save([this.store.saveSections.CONTACT_INFO_SAVE, this.store.saveSections.CALLBACK_REQUESTED]),
      );
      this.contactFormStatus = 'success';
      this.loading = false;
      return true;
    } catch {
      this.errorMessage = commonErrors.failedToSaveInfo;
      this.loading = false;
      return false;
    }
  }

  async redirectToGetStarted() {
    await this.router.navigate([ROUTES.INTRO_PAGE]);
  }

  public resetContactFormStatus(): void {
    this.contactFormStatus = 'initial';
  }

  private async productsCarouselContent(partnerSlug) {
    try {
      this.partnerContent = await lastValueFrom(this.contentService.getPartnerCarouselContent(partnerSlug));
    } catch (error) {
      this.partnerContent = await lastValueFrom(
        this.contentService.getPartnerCarouselContent(defaultPartnerContentName),
      );
    }
  }

  ngOnDestroy() {
    this.dispose();
  }
}
