import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { IPartnerSummaryDetails } from '@eventhorizon/models/partner-summary';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { PartnerStore } from '@eventhorizon/stores/partner.store';

@Injectable({ providedIn: 'root' })
export class ContactInfoResolver implements Resolve<IPartnerSummaryDetails> {
  constructor(private partnerStore: PartnerStore, private store: ApplicationStore) {}

  resolve(): Promise<IPartnerSummaryDetails> {
    return this.getPartnerInfo();
  }

  public async getPartnerInfo(): Promise<IPartnerSummaryDetails> {
    if (!this.store.partnerId) {
      return;
    }

    try {
      return await this.partnerStore.getPartnerById(this.store.partnerId);
    } catch (error) {
      console.error(error);
      return;
    }
  }
}
