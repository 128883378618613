<app-container *mobxAutorun>
  <div class="e50x">
    <div class="e50x-code">Site Under Maintenance</div>
    <div class="e50x-title">We apologize for the inconvenience but our site is being worked on.</div>
    <div class="e50x-link">
      You can still contact us at
      <a class="e50x-link-back" href="mailto:{{contentService?.bankInfoConfig?.emailAddress}}"
        >{{contentService?.bankInfoConfig?.emailAddress}}</a
      >. We'll be back up soon!
    </div>
  </div>
</app-container>
