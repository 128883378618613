<ngx-slick-carousel *ngIf="loadedScript && loadedConfigs" class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
        <div class="content-carousel-content">
            <div *ngIf="slide.linkUrl && linkClickable; else noLink" class="content-carousel-image-container">
                <a [href]="slide.linkUrl" target="_blank" class="content-carousel-image-container"> <img
                        [src]="slide?.imageUrl + '?w=700'" class="content-carousel-image"
                        [alt]="'image of ' + slide?.title"> </a>
                <a [href]="slide.linkUrl" class="content-page-link content-carousel-link-arrow" target="_blank"
                    *ngIf="slide.linkUrl" [ariaLabel]="'Arrow link for' + slide?.title"> <i *ngIf="showLinkArrow" class="far fa-arrow-right"></i></a>
            </div>
            <ng-template #noLink>
                <div (click)="clickAction.emit(slide)" class='content-carousel-image-container'><img [src]="(slide?.imageUrl || slide) "
                        class="content-carousel-image" [alt]="'image of ' + slide?.title"></div>
            </ng-template>
            <div *ngIf='slide?.title' class="content-page-text-strong content-carousel-heading">
                {{slide?.title}}
            </div>
            <div *ngIf='slide?.description && showDescription' class="content-page-text-secondary content-carousel-text">
                {{slide?.description}}
            </div>
        </div>
    </div>
</ngx-slick-carousel>
<div class="content-carousel-nav">
    <a [id]="prevId" class="prev content-carousel">
        <i class="fa fa-chevron-left content-carousel-icon"></i>
    </a>
    <div [id]="dotsId" class="content-carousel-dots-container">

    </div>
    <a [id]="nextId" class="next">
        <i class="fa fa-chevron-right content-carousel-icon"></i>
    </a>
</div>
