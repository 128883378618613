<div class="row" [formGroup]="form">
  <div class="d-flex align-items-center p-0">
    <div class="flex-grow-1">
      <div class="dropdown-container form-control form-select" type="button" dropdown>
        <button dropdownToggle class="dropdown-toggle" aria-label="dropdown toggle">
          {{ selectedValue }} <span class="caret brand-caret"></span>
        </button>
        <div>
          <ul class="dropdown-menu col-12" *dropdownMenu>
            <li role="option" *ngIf="!removeNoSigner">
              <button
                class="signer-dropdown-option-item"
                (click)="sendDataToTermsForm('no-signer')"
                [ngClass]="{ selected: selectedValue === noSignerOption() }"
              >
                {{ noSignerOption() }}
              </button>
            </li>
            <li *ngIf="isAssisted" class="dropdown-header signer-dropdown-option-header">CONTACT</li>
            <li role="option" *ngIf="isAssisted">
              <button
                class="signer-dropdown-option-item"
                (click)="sendDataToTermsForm(contact)"
                [ngClass]="{ selected: selectedValue === formatOptionDescription(contact) }"
              >
                {{ formatOptionDescription(contact) }}
              </button>
            </li>
            <li *ngIf="businessOwners?.length > 0" class="dropdown-header signer-dropdown-option-header">SIGNERS</li>
            <li role="option" *ngFor="let owner of businessOwners">
              <button
                class="signer-dropdown-option-item"
                (click)="sendDataToTermsForm(owner)"
                [ngClass]="{ selected: selectedValue === formatOptionDescription(owner) }"
              >
                {{ formatOptionDescription(owner) }}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <label class="form-label signer-dropdown-label">{{ showDesignatedLabel() }}</label>
    </div>
    <div class="px-3" *ngIf="!hiddenTooltip">
      <i
        class="far fa-circle-info tool-tip-icon"
        [tooltip]="'Available signers are determined based on ownership structure of the business.'"
        placement="right"
        container="body"
      ></i>
    </div>
  </div>
</div>