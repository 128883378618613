<ng-container *ngIf="!isSent">
  <div class="modal-header row p-0">
    <div class="col-10 ps-0">
      <h2 class="send-app-title">
        Let's send the application to the
        <ng-container *ngIf="isAssisted; else signer">merchant</ng-container>
        <ng-template #signer>signer</ng-template>.
      </h2>
    </div>
    <div class="col-2 pe-0 d-flex justify-content-end">
      <button class="btn" (click)="close()">
        <i class="far fa-xmark-large"></i>
      </button>
    </div>
  </div>
  <div class="modal-body px-0 py-4">
    <div class="row pb-4">
      <div class="col-12 px-0">
        <p class="send-app-text">
          Select the appropriate
          <ng-container *ngIf="isAssisted; else signer">recipient</ng-container>
          <ng-template #signer>signer</ng-template>
          to send an email with the application link.
        </p>
      </div>
    </div>
    <div class="row">
      <div *ngIf="isLoadingSigners" class="col-12 px-0 d-flex justify-content-center">
        <div class="lds-dual-ring loading"></div>
      </div>
      <div *ngIf="!isLoadingSigners" class="col-12 px-0">
        <form #form>
          <app-signers-dropdown
            #signerDropdown
            [options]="options"
            [contact]="contact"
            [isAssisted]="isAssisted"
            [businessOwners]="businessOwners"
            hiddenTooltip="true"
            removeNoSigner="true"
            (dataEvent)="handleDataFromSignerForm($event)"
          ></app-signers-dropdown>
        </form>
      </div>
      <div *ngIf="errorMessage && errorMessage !== ''" class="col-12 px-0">
        <p class="send-app-text">
          <small class="text-danger">{{ errorMessage }}</small>
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button type="button" [disabled]="isLoading" class="xup-secondary-button me-3" (click)="close()">Cancel</button>
    <button
        type="button"
        class="xup-primary-button"
        [disabled]="!email || isLoading"
        [class.xup-button-loading]="isLoading"
        (click)="sendApplication()"
      >
        <label>Send Application</label>
        <i class="fas fa-spinner-third fa-spin loading-icon" *ngIf="isLoading"></i>
      </button>
  </div>
</ng-container>
<ng-container *ngIf="isSent">
  <div class="modal-header row p-0">
    <div class="col-12 px-0 d-flex justify-content-end">
      <button class="btn" (click)="close()">
        <i class="far fa-xmark-large"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 d-flex justify-content-center mb-4">
        <lottie-player
          src="/assets/keybank/green-check.json"
          background="transparent"
          speed="1"
          class="lottie-size"
          loop
          autoplay
        ></lottie-player>
      </div>
      <div class="col-12 text-center mb-2">
        <h2 class="send-app-title">Application Sent!</h2>
      </div>
      <div class="col-12 text-center">
        <p class="send-app-text mb-1">An application link was successfully sent to</p>
        <p class="send-app-text">
          <b>{{ email }}</b>
        </p>
        <p *ngIf="!isAssisted" class="send-app-text mt-1 mb-5">
          You can close the browser or have the signer review and sign the agreement on your device.
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="isAssisted" class="modal-footer d-flex justify-content-end">
    <button type="button" [disabled]="isLoading" class="xup-primary-button" (click)="close()">Continue</button>
  </div>
</ng-container>
