import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MessagesService } from '@eventhorizon/services/messages.service';

@Component({
  selector: 'app-field-error',
  templateUrl: './field-error.component.html',
  styleUrls: ['./field-error.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FieldErrorComponent implements OnInit {

  @Input()
  public control: AbstractControl;

  private _errors?: Record<string, any>;

  constructor(private errorService: MessagesService) { }

  ngOnInit() {
    this.errorService.load().then(() => this._errors = this.errorService.errors).catch(console.warn);
  }

  get errors() {
    if (!this._errors) this._errors = this.errorService.errors;
    return this._errors || {};
  }

  public getErrors() {
    if (!this.control) {
      console.error('Field Error control is undefined');
    } else if (this.control.errors) {
      return Object.keys(this.control.errors);
    }
    return {};

  }

}
