<div class="panel text-center">
  <div class="modal-body">
    <div class="row mb-2">
      <div class="col-sm-12">
        <h5 class="modal-title">
          {{ inactivePartnerMessage ? inactivePartnerMessage : 'Sorry, this is no longer an active partner' }}
        </h5>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-sm-12">
        <p class="modal-text">You can still continue to apply and start accepting payments as soon as today!</p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-4">
        <button class="xup-primary-button" (click)="handleContinue()">Continue</button>
      </div>
    </div>
  </div>
</div>
