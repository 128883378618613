<ng-container *mobxAutorun>
  <div class="e404">
    <div class="e404-code">404</div>
    <div class="e404-title">Sorry, we can't find the page you were looking for.</div>
    <div class="e404-subtitle">The link you followed may be broken, or the page may have been removed.</div>
    <div class="e404-link">
      Go back to
      <a
        class="e404-link-back"
        [href]="safeBankUrl(contentService?.bankInfoConfig?.returnLink)"
        tabindex="0"
        aria-label="Go Back"
      >
        {{ bankName }} </a
      >.
    </div>
  </div>
</ng-container>
