<!--
dirty: {{ control.dirty }}
touched: {{ control.touched }}
invalid: {{ control.invalid }}

errors: {{ getErrors() | json }}
-->
<div *ngIf="control?.touched && control?.invalid">
    <p *ngFor="let error of getErrors()" class="form-error-message" role="alert">
        {{ errors[error] }}
    </p>
</div>
