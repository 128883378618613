import { Injectable } from '@angular/core';
import { IPartnerSummaryDetails } from '@eventhorizon/models/partner-summary';
import { makeObservable, runInAction } from 'mobx';
import { observable } from 'mobx-angular';
import { PartnersService } from '@eventhorizon/services/partners.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PartnerStore {
  partner: IPartnerSummaryDetails;

  partnerIdRequested: string;

  get isDDALanguageEnabled(): boolean {
    return this.partner.isDDALanguageEnabled;
  }

  constructor(private partnerService: PartnersService) {
    makeObservable(this, {
      partner: observable,
    });
  }

  async getPartnerById(partnerId?: string): Promise<IPartnerSummaryDetails> {
    if (!this.partner || (partnerId && partnerId !== this.partnerIdRequested)) {
      this.partnerIdRequested = partnerId;
      const partner = await lastValueFrom(this.partnerService.getPartner(partnerId));
      runInAction(() => this.partner = partner);
    }
    return this.partner;
  }
}
