import { AbstractControl, UntypedFormControl, ValidatorFn, Validators, ValidationErrors } from '@angular/forms';
import { regexs } from '@eventhorizon/data/masks.data';
import moment from 'moment';
import { currencyToNumber } from '@eventhorizon/utils/util';

export const regexValidator = (
  control: AbstractControl,
  regex: RegExp,
  invalidError: string,
  requiredError?: string,
): ValidationErrors | null => {
  if (control.value) {
    if (!regex.test(control.value)) {
      const returnVal = {};
      returnVal[invalidError] = true;
      return returnVal;
    }
  } else {
    if (!requiredError) {
      return null;
    }

    const returnVal = {};
    returnVal[requiredError] = true;
    return returnVal;
  }

  return null;
};

export class CommonValidator {
  static phone(isRequired: boolean = true): ValidatorFn {
    const regex = /^[1-9]\d\d-[\d]{3}-[\d]{4}$/;
    return (control: UntypedFormControl): ValidationErrors | null =>
      regexValidator(control, regex, 'phone-number-invalid', isRequired ? 'phone-number-required' : undefined);
  }

  static email(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      if (!control.value && !isRequired) return null;

      const error = regexValidator(control, regexs.email, 'email-invalid', isRequired ? 'email-required' : undefined);
      return control.value && control.value.length > 70 ? { maxlength: true } : error;
    };
  }

  static website(control: UntypedFormControl): ValidationErrors | null {
    return regexValidator(control, regexs.website, 'website-invalid');
  }

  static websiteNoHttp(control: UntypedFormControl): ValidationErrors | null {
    const regex = new RegExp(
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i,
    ); // URL pattern from https://github.com/yuyang041060120/ng2-validation/blob/master/src/url/validator.ts
    const augmentedControl: UntypedFormControl = new UntypedFormControl(`http://${control.value}`, Validators.pattern(regex));
    if (augmentedControl.errors != null) {
      return {
        'website-invalid': true,
      };
    }
    return null;
  }

  static date(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      let validation;
      if (!control.value && !isRequired) return null;
      if (!(control.value instanceof Date)) {
        validation = regexValidator(control, regexs.date, 'date-invalid', isRequired ? 'date-required' : undefined);
      }
      if (!validation) {
        const timespan = Date.parse(control.value);
        if (isNaN(timespan)) validation = { 'date-invalid': true };
      }
      return validation;
    };
  }

  static dob(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      const validationFn = CommonValidator.date(isRequired);
      let validation = validationFn(control);
      if (!validation) {
        const dob = new Date(control.value);
        if (dob >= new Date()) validation = { 'dob-in-future': true };
      }
      return validation;
    };
  }

  static dateInPastWithin200Years(isRequired: boolean = true, dateInputFormat: string = 'MM/DD/YYYY'): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      const validationFn = CommonValidator.dob(isRequired);
      const validation = validationFn(control);
      if (validation) return validation;

      const date = moment(control.value, dateInputFormat);
      const now = moment();
      if (date.isBefore(now.subtract(200, 'years'))) {
        return { 'date-in-past': true };
      }
      return null;
    };
  }

  static dobOver18Under90(isRequired: boolean = true, dateInputFormat: string = 'MM/DD/YYYY'): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      const validationFn = CommonValidator.dob(isRequired);
      const validation = validationFn(control);
      if (validation) return validation;

      const date = moment(control.value, dateInputFormat);

      const now = moment();
      if (date.isAfter(now.subtract(18, 'years'))) {
        return { 'dob-over-18': true };
      }

      if (date.isBefore(now.subtract(90, 'years'))) {
        return { 'dob-under-90': true };
      }

      return null;
    };
  }

  static personalName(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null =>
      regexValidator(
        control,
        regexs.personalName,
        'personal-name-invalid',
        isRequired ? 'personal-name-required' : undefined,
      );
  }

  static tin(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      const regex = isRequired ? /^[\d]{9}$/ : /^\*{5}\d{4}|\d{9}$/;
      return regexValidator(control, regex, 'tin-invalid', isRequired ? 'tin-required' : undefined);
    };
  }

  static tinAlt(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      const regex = /^\*{5}\d{4}|\d{9}$/;
      return regexValidator(control, regex, 'tin-invalid', isRequired ? 'tin-required' : undefined);
    };
  }

  static address1(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      if (control && control.value && regexs.poBox.test(control.value)) return { 'address-no-pobox': true };
      return regexValidator(
        control,
        regexs.address1,
        'address1-invalid',
        isRequired ? 'address1-required' : undefined,
      );
    };
  }

  static address2(control: UntypedFormControl): ValidationErrors | null {
    if (control && control.value && regexs.poBox.test(control.value)) return { 'address-no-pobox': true };
    return regexValidator(control, regexs.address2, 'address2-invalid', undefined);
  }

  static city(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null =>
      regexValidator(control, regexs.city, 'city-invalid', isRequired ? 'city-required' : undefined);
  }

  static state(control: UntypedFormControl): ValidationErrors | null {
    return control.value ? null : { 'state-required': true };
  }

  static zip(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      if (control.value && control.value === '00000') return { 'zip-invalid': true };
      return regexValidator(control, regexs.zip, 'zip-invalid', isRequired ? 'zip-required' : undefined);
    };
  }

  static number(isRequired: boolean = true): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      const regex = /^(\d+([,\.\d]+)?)$/;
      return regexValidator(control, regex, 'number-invalid', isRequired ? 'required' : undefined);
    };
  }

  static alphanumeric(control: UntypedFormControl): ValidationErrors | null {
    const regex = /^[a-z\d\-_\s]+$/i;
    return regexValidator(control, regex, 'invalid', 'required');
  }

  static lt(lhs: UntypedFormControl, invalidError: string, requiredError?: string): ValidatorFn {
    return function ltValidate(rhs: UntypedFormControl): ValidationErrors | null {
      if (rhs.value && lhs) {
        const lhsValue = currencyToNumber(lhs.value);
        const rhsValue = currencyToNumber(rhs.value);
        if (rhsValue > lhsValue) {
          const returnVal = {};
          returnVal[invalidError] = true;
          return returnVal;
        }
      } else {
        if (!requiredError) {
          return null;
        }

        const returnVal = {};
        returnVal[requiredError] = true;
        return returnVal;
      }

      return null;
    };
  }

  static ltWithZero(lhs: UntypedFormControl, invalidError: string): ValidatorFn {
    return function ltValidate(rhs: UntypedFormControl): ValidationErrors | null {
      if (rhs.value) {
        const lhsValue = currencyToNumber(lhs.value);
        const rhsValue = currencyToNumber(rhs.value);
        if (rhsValue > lhsValue) {
          const returnVal = {};
          returnVal[invalidError] = true;
          return returnVal;
        }
      }

      return null;
    };
  }

  static gt(lhs: UntypedFormControl, invalidError: string, requiredError?: string): ValidatorFn {
    return function gtValidate(rhs: UntypedFormControl): ValidationErrors | null {
      if (rhs.value) {
        const rhsValue = currencyToNumber(rhs.value);
        const lhsValue = currencyToNumber(lhs.value);

        if (rhsValue < lhsValue) {
          const returnVal = {};
          returnVal[invalidError] = true;
          return returnVal;
        }
      } else {
        if (!requiredError) {
          return null;
        }

        const returnVal = {};
        returnVal[requiredError] = true;
        return returnVal;
      }

      return null;
    };
  }

  static twoFactorAuthCode(control: UntypedFormControl): ValidationErrors | null {
    const regex = /^\d{6}$/;
    return regexValidator(control, regex, 'two-factor-auth-code-smaller');
  }

  static locationType(control: UntypedFormControl): ValidationErrors | null {
    if (control.value === null || control.value === 'null' || control.value === '') {
      const validationError: ValidationErrors = {
        required: true,
      };
      return validationError;
    }

    return null;
  }

  static amexMemberId(isRequired: boolean = false): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      const regex = isRequired ? /^[\d]{10}$/ : /^\*{6}\d{4}|\d{10}$/;
      return regexValidator(control, regex, 'amex-id-invalid', undefined);
    };
  }

  static checkDeliveryOptionsPercentage(isRequired: boolean = false): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      if (control.value) {
        const percentage = +control.value;

        if (!percentage || percentage !== 100) {
          return {
            'delivery-options-invalid': true,
          };
        }
      } else if (isRequired) {
        return {
          'delivery-options-required': true,
        };
      }
      return null;
    };
  }

  static duplicatedValue(valueList: any[], valueName: string): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      if (control.value) {
        const found = valueList.find(element => {
          return element[valueName] == control.value;
        });
        return found ? { 'duplicated-item-error': true } : null;
      }
      return null;
    };
  }
}
