import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartStore } from '@eventhorizon/stores/cart.store';

@Component({
  selector: 'app-tg-product-navigation',
  templateUrl: './product-navigation.component.html',
  styleUrls: ['./product-navigation.component.scss'],
})
export class ProductNavigationComponent {
  @Input()
    backPage: string;

  @Input()
    productName: string;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private cartStore: CartStore) {}
}
