<div class="d-flex justify-content-center">
  <div *ngIf="isLoading" class="lds-dual-ring"></div>
  <p *ngIf="errorMessage" class="text-danger">{{ errorMessage }}</p>
</div>
<div *ngIf="!errorMessage">
  <ng-container *ngFor="let row of rows">
    <div class="row app-table-row" (click)="itemClick(row)">
      <div class="col-6 app-table-title ps-0">{{row.title}}</div>
      <div class="col-6 app-table-column-value pe-0">{{row.value}}</div>
    </div>
  </ng-container>
</div>
