<div *ngIf="!quizLoaded" class="loading-container">
  <div class="lds-dual-ring"></div>
  Loading verification quiz...
</div>
<small class="text-danger">{{ errorMessage }}</small>
<ng-container [formGroup]="form">
  <ng-container formArrayName="questions">
    <div
      [formGroup]="form.controls.questions.controls[index]"
      *ngFor="let question of quizQuestions; let index = index"
      class="verification-quiz-question"
    >
      <p class="verification-quiz-container">
        <span class="verification-quiz-question-text">{{ question.value }}</span>
      </p>
      <ol *ngIf="question.valueDetails">
        <li *ngFor="let detail of question.valueDetails">- {{ detail.value }}</li>
      </ol>
      <ng-container *ngIf="question.answerType === ANSWER_TYPES.SINGLE">
        <div *ngFor="let answer of question.availableAnswers" class="verification-quiz-answer-container">
          <input
            [id]="question.questionId + '_' + answer.id"
            [value]="answer.id"
            (change)="setSingleAnswer(form.controls.questions.controls[index], answer.id)"
            [checked]="isOptionSelected(form.controls.questions.controls[index], answer.id)"
            type="radio"
          />
          <label [for]="question.questionId + '_' + answer.id">{{ answer.value }}</label>
        </div>
      </ng-container>
      <ng-container *ngIf="question.answerType === ANSWER_TYPES.MULTIPLE">
        <div *ngFor="let answer of question.availableAnswers" class="verification-quiz-answer-container">
          <input
            [id]="question.questionId + '_' + answer.id"
            [value]="answer.id"
            (change)="setMultipleAnswer(form.controls.questions.controls[index], answer.id)"
            [checked]="isOptionSelected(form.controls.questions.controls[index], answer.id)"
            type="checkbox"
          />
          <label [for]="question.questionId + '_' + answer.id" class="ms-3">{{ answer.value }}</label>
        </div>
      </ng-container>
      <ng-container
        *ngIf="question.answerType === ANSWER_TYPES.SINGLE_DROPDOWN"
        class="verification-quiz-answer-container"
      >
        <div class="btn-group w-100" dropdown>
          <button dropdownToggle type="button" class="dropdown-toggle verification-quiz-answer-container-dropdown">
            <p class="text-start ms-2">
              {{
                getSelectedAnswerValue(form.controls.questions.controls[index], question.availableAnswers)[0]?.value ||
                  '...'
              }}
            </p>
            <span class="caret"></span>
          </button>
          <ul *dropdownMenu class="dropdown-menu w-100" role="menu" aria-labelledby="button-basic">
            <li
              role="menuitem"
              *ngFor="let answer of question.availableAnswers"
              (click)="setSingleAnswer(form.controls.questions.controls[index], answer.id)"
            >
              <a class="dropdown-item">
                {{ answer.value }}
              </a>
            </li>
          </ul>
        </div>
      </ng-container>
      <ng-container *ngIf="question.answerType === ANSWER_TYPES.MULTIPLE_DROPDOWN">
        <app-multiple-selection-dropdown
          [dropdownOptions]="question.availableAnswers"
          [selectedOptions]="getSelectedAnswerValue(form.controls.questions.controls[index], question.availableAnswers)"
          (removeElement)="setMultipleAnswer(form.controls.questions.controls[index], $event)"
          (optionClick)="setMultipleAnswer(form.controls.questions.controls[index], $event)"
        ></app-multiple-selection-dropdown>
      </ng-container>
      <ng-container *ngIf="question.answerType === ANSWER_TYPES.FREE_FORM">
        <textarea class="form-control" rows="3" formControlName="answerValue"></textarea>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
