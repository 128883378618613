import { Component, OnInit } from '@angular/core';
import { merchantEmailTypes } from '@eventhorizon/constants/general.constants';
import { ApplicationService } from '@eventhorizon/services/application.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ApplicationSaveReturnRequest, EmailType } from '@eventhorizon/models/application.model';
import { ModalInterface } from '@eventhorizon/interfaces/modal-interface';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-send-application-modal',
  templateUrl: './send-application-modal.component.html',
  styleUrls: ['./send-application-modal.component.scss'],
})
export class SendApplicationModalComponent implements ModalInterface, OnInit {
  loadSignersData = false;

  applicationId: string;

  email: string;

  isSent = false;

  isLoading = false;

  isLoadingSigners = false;

  errorMessage: string;

  isAssisted = false;

  options: any;

  contact: any;

  businessOwners: any;

  constructor(
    private modalRef: BsModalRef,
    private appService: ApplicationService,
    protected fb: FormBuilder,
  ) {}

  ngOnInit() {
    if (this.loadSignersData) {
      this.isLoadingSigners = true;
      this.loadSigners();
    }
  }

  handleDataFromSignerForm(data) {
    this.email = data.email;
  }

  sendApplication(): void {
    this.isLoading = true;
    const req: ApplicationSaveReturnRequest = {
      email: this.email,
      type: EmailType.assistedOnboardingUpdate,
    };
    this.appService.sendReturningEmail(this.applicationId, req, merchantEmailTypes.UPDATE_APPLICATION).subscribe({
      next: () => {
        this.isLoading = false;
        this.isSent = true;
      },
      error: () => {
        this.errorMessage = 'Unable to send application.';
        this.isLoading = false;
      },
    });
  }

  private loadSigners() {
    this.appService.getSigners(this.applicationId, false).subscribe({
      next: result => {
        this.options = result;
        this.contact = result.contactInfo;
        this.businessOwners = result.businessOwners;
        this.isLoadingSigners = false;
      },
      error: () => {
        this.isLoadingSigners = false;
      },
    });
  }

  public close(): void {
    this.modalRef.hide();
  }
}
