<div class="modal-header justify-content-end p-0">
  <button type="button" class="btn pull-right" aria-label="Close" (click)="close(false)">
    <i class="far fa-xmark-large"></i>
  </button>
</div>
<div class="modal-body pt-0">
  <div class="text-center">
    <div class="row justify-content-center">
      <h1 id="modal-title" class="fw-bold text-dark">{{ title }}</h1>
    </div>
    <div class="fw-bold m-2 price">${{ price }}/mo</div>
    <small *ngIf="priceDescription" class="price-description">
      <p>&#43; {{ priceDescription }}/mo for each additional device</p>
    </small>
    <p class="m-4">{{ description }}</p>
    <div class="d-flex justify-content-center my-3">
      <button class="xup-primary-button" [class.xup-button-loading]="isLoading"
      (click)="close(true)" [disabled]="disableButton || isLoading">
        <label>Add to Cart</label>
        <i *ngIf="isLoading" class="fas fa-spinner-third fa-spin loading-icon"></i>
      </button>
    </div>
  </div>
</div>