<div *ngIf="!form.controls.quizLoaded.valid" class="loading-container">
  <div class="lds-dual-ring"></div>
  Loading verification quiz...
</div>
<form [formGroup]="form">
  <ng-container formArrayName="questions">
    <fieldset
      [formGroup]="form.controls.questions.controls[index]"
      *ngFor="let question of quizQuestions; let index = index"
      class="verification-quiz-question"
    >
      <legend class="verification-quiz-container">
        <span class="verification-quiz-question-number">{{ question.questionNumber }}. </span>
        <span class="verification-quiz-question-text">{{ question.questionText }}</span>
        <p
          class="form-error-message"
          role="alert"
          *ngIf="form.controls.questions.controls[index].invalid && form.controls.questions.controls[index].touched"
        >
          Required
        </p>
      </legend>
      <div
        *ngFor="let answer of question.questionChoices; let answerIndex = index"
        class="verification-quiz-answer-container"
      >
        <input
          formControlName="answer"
          [attr.name]="'group_' + index"
          [id]="question.questionNumber + '_' + answerIndex"
          [value]="answer.answerNumber"
          type="radio"
        />
        <label [for]="question.questionNumber + '_' + answerIndex">{{ answer.answerText }}</label>
      </div>
    </fieldset>
  </ng-container>
</form>
