<div *ngIf="isLoading" class="col-sm-12">
  <div class="lds-dual-ring"></div>
</div>
<div class="appconfirm" *ngIf="!isLoading && !errorMessage">
  <img src="assets/images/order-summary.svg" width="64" class="mb-4">
  <h1 class="appconfirm-title mb-2">Your order and application have been submitted!</h1>
  <p class="appconfirm-description mb-4">
    Your application is under review. Our compliance team will be in touch soon regarding the status of your merchant application. 
    Your order will be processed once your application is approved. 
    If you have any questions, contact 
    <a href="mailto:keymerchantservices@keybank.com">keymerchantservices&#64;keybank.com</a> 
    or call 1-800-537-6571 to speak with a Merchant Specialist from Monday through Friday, 9 a.m. to 5 p.m. ET.
  </p>
  <div *ngIf="!isOrderStatus">
    <button
      (click)="downloadMpa()"
      [disabled]="isLoading || errorMessage"
      class="xup-primary-button xup-button-small mb-4"
    >
      Download Signed MPA
    </button>
  </div>
  <div class="appconfirm-cart">
    <div class="d-flex flex-row align-items-center mb-2">
      <h1 class="appconfirm-title text-uppercase me-2">ORDER #{{orderDetails().orderId || '00000'}}</h1>
      <app-badge [showIcon]="true" [type]="orderStatusBadgeType()" [label]="orderStatusLabel()"></app-badge>
      <div class="toggle-btn" (click)="toggleDetails()">
        <i *ngIf="expandDetails" class="fa-solid fa-angle-down"></i>
        <i *ngIf="!expandDetails" class="fa-solid fa-angle-right"></i>
      </div>
    </div>
    <div class="appconfirm-cart-subheading">
      You will be auto charged on either your first or second month’s statement
    </div>
    <div *ngIf="expandDetails" class="mt-4">
      <app-tg-cart [locations]="orderDetails().businessLocations" [locationCarts]="orderDetails().carts"[allowTaxEstimation]="false"></app-tg-cart>
    </div>
  </div>
</div>
<div *ngIf="errorMessage" class="col-sm-12 form-error-message error-message">
  {{ errorMessage }}
</div> 
