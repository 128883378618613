<form [formGroup]="form">
  <div class="filter-container">
    <div class="filter-container-name">
      <div class="mb-3 position-relative">
        <input
          id="product-search-input"
          formControlName="name"
          class="form-control"
          type="text"
          (keyup)="handleNameChange($event)"
        />
        <label class="form-label" for="product-search-input"> Search Products By Name </label>
        <i class="fal fa-search input-icon"></i>
      </div>
    </div>
    <div class="filter-container-dropdowns row justify-content-end" [ngClass]="bigButtons ? 'col-4 p-0' : ''">
      <div class="filter-container-dropdown col-6 col-md-4 p-0 pe-1" [ngClass]="bigButtons ? 'col-md-6 p-0' : ''">
        <div class="btn-group dropdown-container" dropdown [insideClick]="true" placement="bottom right">
          <button id="filter-button-animated" dropdownToggle type="button" class="dropdown-toggle">
            Filter By <span class="caret brand-caret"></span>
          </button>
          <div
            id="filter-dropdown-animated"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right"
            role="menu"
            aria-labelledby="filter-button-animated"
          >
            <div class="mb-3 position-relative search-filters-categories">
              <div *ngFor="let type of productTypes; &quot;search-filter-checkboxes&quot;">
                <input
                  #typeCheckBox
                  (change)="handleProductTypeChange(type.id, $event.target.checked)"
                  id="{{ type.id }}"
                  class="form-option-input"
                  type="checkbox"
                  role="menuitemcheckbox"
                  (keyup)="handleKeyUp($event, typeCheckBox, type.id, 'type')"
                  [checked]="toggleCheck(type.id)"
                />
                <label [for]="type.id" class="search-filters-categories-label">
                  {{ type.name | titlecase }}
                </label>
              </div>
            </div>
            <button
              class="dropdown-item clear-all"
              (click)="clearAll()"
              [disabled]="productTypesChips.length === 0"
              role="menuitem"
            >
              Clear All
            </button>
          </div>
        </div>
      </div>
      <div class="filter-container-dropdown col-6 col-md-4 p-0 ps-1" [ngClass]="bigButtons ? 'col-md-6 p-0' : ''">
        <div class="btn-group dropdown-container" dropdown placement="bottom right">
          <button id="sort-button-animated" dropdownToggle type="button" class="dropdown-toggle">
            Sort By <span class="caret brand-caret"></span>
          </button>
          <ul
            id="sort-dropdown-animated"
            *dropdownMenu
            class="dropdown-menu dropdown-menu-right"
            role="menu"
            aria-labelledby="sort-button-animated"
          >
            <li *ngFor="let filter of sortByFilters; let index = index" role="menuitem">
              <button
                class="dropdown-item"
                [ngClass]="{ selected: isSelected(index) }"
                (click)="setSortFilter(filter.id)"
              >
                {{ filter.name }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 text-end">
      <div class="chips-container">
        <div *ngFor="let productTypeChip of productTypesChips; let index = index" class="chip">
          <i class="far fa-circle-xmark chip-icon" (click)="removeChip(productTypeChip.id)"></i>
          {{ productTypeChip.name | titlecase }}
        </div>
      </div>
    </div>
  </div>
</form>
