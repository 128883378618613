import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'zipCode' })
export class ZipCodePipe implements PipeTransform {
  transform(zipCode: string) {
    if (!zipCode) {
      return '';
    }

    const regex = new RegExp('^d{3}-d{3}-d{4}$');

    if (regex.test(zipCode)) {
      return zipCode;
    } 
    try {
      return zipCode.replace(/(\d{5})(\d{4})/, '$1-$2');
    } catch {
      return zipCode;
    }
    
  }
}
