import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentBase } from '../base-content/content.base';

@Component({
  selector: 'app-tg-page-hero',
  templateUrl: './page-hero.component.html',
  styleUrls: ['./page-hero.component.scss'],
})
export class PageHeroComponent extends ContentBase {
  @Input() heroData;

  constructor(protected sanitizer: DomSanitizer) {
    super();
  }

  setContent(content) {
    let backgroundImage = this.sanitizer.bypassSecurityTrustStyle(
      'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(' + content.backgroundImageUrl + ')',
    );
    if (content.removeBackgroundOverlay) {
      backgroundImage = this.sanitizer.bypassSecurityTrustStyle('url(' + content.backgroundImageUrl + ')');
    }
    this.heroData = {
      contentType: content.contentType,
      backgroundImageUrl: backgroundImage,
      heading: content.heading,
      text: content.text,
      ctaDisplayText: content.ctaDisplayText,
      ctaAction: content.ctaAction,
      ctaUrl: content.ctaUrl,
      removeButtonRadius: content.removeButtonRadius,
    };
  }
}

export default PageHeroComponent;
