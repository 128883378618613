<div id="page-container">
    <a
            tabindex="0"
            class="skip-navigation"
            (click)="skipToContent()"
            (keyup.space)="skipToContentSpacebar($event)"
            (keyup.enter)="skipToContent()"
    >Skip Navigation</a
    >
    <app-header></app-header>
    <div class="main-content">
        <app-error-timeout></app-error-timeout>
        <router-outlet *ngIf="loaded"></router-outlet>
    </div>

    <iframe
            id="threatmetrix"
            #threatmetrix
            name="threatmetrix"
            height="0px"
            width="10%"
            class="d-none"
            [src]="threatMetrixUrl"
    ></iframe>

    <app-disclosure></app-disclosure>

    <app-footer></app-footer>
</div>
