<div *ngIf="heroData" class="page-hero has-background" [style.background-image]="heroData?.backgroundImageUrl">
  <div class="content-page-border"></div>
  <h1 *ngIf="heroData?.heading" class="content-page-heading page-hero-heading">
    {{ heroData?.heading }}
  </h1>
  <h2 *ngIf="heroData?.text" class="content-page-subheading-primary page-hero-subheading">
    {{ heroData?.text }}
  </h2>
  <app-tg-cta-button
    *ngIf="heroData?.ctaDisplayText && (heroData?.ctaAction || heroaData?.ctaUrl)"
    [ctaDisplayText]="heroData?.ctaDisplayText"
    [ctaAction]="heroData?.ctaAction"
    [url]="heroData?.ctaUrl"
    [removeButtonRadius]="heroData?.removeButtonRadius"
  ></app-tg-cta-button>
</div>
