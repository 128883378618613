<div class="modal-body text-center">
  <div class="row">
    <p class="modal-text">{{ body }}</p>
  </div>

  <div class="row mt-2">
    <p class="modal-text">{{ body2 }}</p>
  </div>

  <div class="row mt-3">
    <span class="modal-heading">Please share the following information with your client:</span>
  </div>

  <div class="row mt-2">
    <span *ngIf="!clientMsg2" class="modal-text modal-highlight">"{{ clientMsg }}"</span>
    <span *ngIf="clientMsg2" class="modal-text modal-highlight">"{{ clientMsg }} <br />{{ clientMsg2 }}"</span>
  </div>

  <div class="row mt-3">
    <div class="col-sm-6 mx-auto">
      <button class="primary-button" (click)="close()">Close</button>
    </div>
  </div>
</div>
