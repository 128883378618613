// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  onboardingApi: false,
  turnOffBoomtown: false,
  api: {
    url: '/api',
  },
  auth: {
    url: '',
  },
  reCaptchaSiteKey: '6LdsQsoZAAAAAGboCtPfavPAxOS07B2YA1n22ii7',
  customerServiceUrl: 'https://www.key.com/about/customer-service.jsp',
  error404BackLink: 'https://www.key.com/small-business/services/merchant-services/pos-payment-solutions.jsp',
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
