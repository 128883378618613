<div class="modal-body">

  <div class="spinner" *ngIf="isLoading">
    <div class="lds-dual-ring"></div>
  </div>

  <div class="empty-cart-wrapper" *ngIf="!isLoading && totalProductCount < 1">
    <div class="empty-cart">
      <i role="button" aria-label="Shopping Cart" class="fa-duotone fa-solid fa-cart-shopping cart-icon"></i>
      <h1>Your cart is empty.</h1>
    </div>
    <button class="xup-secondary-button" (click)="closeAndGoToProducts()">Continue Shopping</button>
  </div>

  <div class="cart-header">
    <h1 class="cart-header-title">Shopping Cart <span class="cart-header-title-qty">{{totalProductCount}}</span></h1>
    <button class="btn cart-header-close" (click)="closeModal()" aria-label="Close Shopping Cart" #focus>
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="locations-summary">
    <lib-locations-list #locationsList
      [locations]="locations"
      [showPrimaryIndicator]="true"
      [activeIndex]="locationIndexQuery"
      (loading)="isLoading = $event;"
    ></lib-locations-list>
  </div>
  <div class="cart-footer">
    <ng-container *ngIf="carts.length">
      <app-cart-summary
        [readonly]="false"
        [showExtendedDisplay]="true"
        [carts]="carts"
      ></app-cart-summary>
    </ng-container>
    <div class="cart-footer-buttons">
      <button class="xup-secondary-button" (click)="closeAndGoToProducts()">Add More Products</button>
      <div class="btn-divider">
        <div class="btn-divider-or">
          <span>OR</span>
        </div>
      </div>
      <button class="xup-primary-button" [disabled]="disableContinue" (click)="onContinue()">Continue</button>
    </div>
  </div>
</div>