<app-container *mobxAutorun>
  <app-application-page
    [index]="3"
    [validate]="false"
    [applicationInfo]="true"
    [orderInfo]="true"
    [showSaveAndContinue]="false"
    [showProgressWizard]="false"
  >
    <app-tg-application-confirmation #innerSlide></app-tg-application-confirmation>
  </app-application-page>
</app-container>
