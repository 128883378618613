<div>
  <div class="row mb-3">
    <div class="col-12 ps-0 mb-3">
      <div class="ob-location-title">Location Information</div>
    </div>
  </div>
  <div [formGroup]="form">
    <div class="row mb-3">
      <div class="col-12 col-md-6 position-relative ps-0 mb-3 mb-md-0">
        <input
          [id]="id + 'dba'"
          type="text"
          formControlName="locationDBA"
          class="form-control"
          [maskito]="masks.companyName"
        />
        <label [for]="id + 'dba'" class="form-label">Location DBA</label>
        <app-field-error [control]="locationDBA"></app-field-error>
        <app-tg-tooltip
          class="ob-location-tooltip"
          [tooltipText]="
            'DBA stands for “doing business as.” This is also known as the assumed, trade, or primary name your business operates under at this location. DBA names can be the same for multiple locations.'
          "
        >
        </app-tg-tooltip>
      </div>
      <div class="col-12 col-md-6 position-relative ps-0" *ngIf="config.displayMccConfiguration">
        <lib-location-business-type [id]="id + 'mcc'" [location]="selectedLocation"></lib-location-business-type>
      </div>
    </div>
    <div class="row mb-3" *ngIf="config.displayPhoneNumber && form.controls.businessPhone">
      <div class="col-12 ps-0 position-relative">
        <input
          [id]="id + 'phone'"
          formControlName="businessPhone"
          class="form-control"
          type="text"
          autocomplete="tel"
          [maskito]="masks.phone"
        />
        <label class="form-label" [for]="id + 'phone'">Business Phone </label>
        <app-field-error [control]="form.controls.businessPhone"></app-field-error>
      </div>
    </div>
    <div class="row mb-3" *ngIf="config.displayLocationCategory && form.controls.businessCategory">
      <div class="col-12 ps-0 position-relative">
        <select [id]="id + 'category'" formControlName="businessCategory" class="form-control form-select">
          <option *ngFor="let selectedLocation of businessCategories" [value]="selectedLocation.name">
            {{ selectedLocation?.name }}
          </option>
        </select>
        <label class="form-label" [for]="id + 'category'">Location Category</label>
        <app-field-error [control]="form.controls.businessCategory"></app-field-error>
      </div>
    </div>

    <div class="p-0 mb-3">
      <div class="row mb-3 ob-location-title">Location Address</div>
      <app-tg-address #address [isAssisted]="false" [address]="selectedLocation.address" class="p-0"></app-tg-address>
      <div class="d-flex mb-4 p-0">
        <div class="position-relative">
          <label class="checkbox-label" [for]="id + 'address'">
            <input
              [id]="id + 'address'"
              formControlName="sameAsLegal"
              class="form-option-input"
              type="checkbox"
              (change)="changeSameAsLegal($event.target.checked)"
            />&nbsp;Same as Legal Business Address
          </label>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <div class="row mb-3 p-0">
        <div class="col-12 p-0">
          <div class="ob-location-title">Timeframe from transaction to delivery</div>
          <div class="ob-location-subtitle pb-1">
            Provide the percentage of goods or services delivered within the timeframe. Your total must equal 100%.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 ps-0" *ngFor="let option of deliveryOptions; let i = index">
          <div class="left-inner-addon mb-3 position-relative">
            <input
              [id]="option.controlName"
              class="form-control"
              type="text"
              size="3"
              autocomplete="off"
              step="10"
              [formControlName]="option.controlName"
              [maskito]="masks.percentageSymbol"
              (change)="onInputChange($event.target.value, option.controlName, true)"
              (input)="onInputChange($event.target.value, option.controlName, true)"
            />
            <label class="form-label" [for]="option.controlName">
              {{ option.heading }}
            </label>
          </div>
        </div>
        <input [id]="id + 5" type="hidden" formControlName="deliveryPercentageValid" />
        <div class="col-12 text-start" *ngIf="hideTotalOptionsError">
          <app-field-error [control]="form.controls.deliveryPercentageValid"></app-field-error>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <div class="row mb-3 p-0">
        <div class="col-12 p-0 mb-2">
          <div class="ob-location-title">Payment Channels</div>
          <div class="ob-location-subtitle">
            How do you currently, or how do you expect to receive payments at this location? All channels must add up to
            100%.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 ps-0" *ngFor="let paymentType of paymentCategories; let i = index">
          <div class="left-inner-addon mb-3 position-relative">
            <input
              [id]="paymentType.controlName"
              class="form-control"
              type="text"
              size="3"
              autocomplete="off"
              step="10"
              [formControlName]="paymentType.controlName"
              [maskito]="masks.percentageSymbol"
              (change)="onInputChange($event.target.value, paymentType.controlName, false)"
              (input)="onInputChange($event.target.value, paymentType.controlName, false)"
            />
            <label class="form-label" [for]="paymentType.controlName">
              {{ paymentType.heading }}
            </label>
            <app-tg-tooltip class="tooltip-icon" [tooltipText]="getPaymentTypeToolTip(paymentType)"> </app-tg-tooltip>
          </div>
        </div>
        <input type="hidden" [id]="id + 5" formControlName="totalChannels" />
        <div class="col-12 text-start" *ngIf="hideTotalChannelsError">
          <app-field-error [control]="form.controls.totalChannels"></app-field-error>
        </div>
      </div>
    </div>

    <div>
      <div class="row mb-3">
        <div class="col-md-12 ps-0 mb-2">
          <div class="ob-location-title">Bank Account</div>
          <div class="ob-location-subtitle">
            Add the bank account where you would like funds to be deposited for transactions processed at this location.
            You may also assign the bank account for this location automatically by linking your account.
          </div>
        </div>
      </div>
      <div class="col-12 ps-0">
        <app-tg-bank-info
          #bankInfo
          [location]="selectedLocation"
          [primary]="primaryLocation"
          #bankForm
        ></app-tg-bank-info>
      </div>
    </div>
  </div>
</div>
