<app-container *mobxAutorun>
  <app-application-page
    [index]="0"
    [subIndex]="2"
    [text]="messages.paymentInfoSlide"
    (prev)="prev()"
    (next)="next()"
  >
    <app-tg-payment-info #innerSlide (prev)="prev()" (next)="next()"></app-tg-payment-info>
  </app-application-page>
</app-container>
