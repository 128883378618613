/* eslint-disable @typescript-eslint/dot-notation */
import { Inject, Injectable } from '@angular/core';
import { WINDOW_TOKEN } from '@eventhorizon/injection-keys/injection-keys';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type NetworkStatus = 'online' | 'offline';
export type NetworkType = 'slow-2g' | '2g' | '3g' | '4g';


@Injectable({
  providedIn: 'root',
})
export class NetworkMonitorService {
  /**
   * We can use this source to monitor the network for online/offline events and prevent the user from submitting
   * more information to the backend and leaving the UI in a broken state if errors are not handled correctly
   */
  public networkStatus$;

  public isOffline$: Observable<boolean> ;

  /**
   * In supported browsers (chromium based and firefox behind a flag) we can determine which is the current network speed
   * and if we have delicate operations warn the user to move to a better network if possible
   */
  public networkType$ = new BehaviorSubject<NetworkType | 'unknown'>('unknown');

  constructor(@Inject(WINDOW_TOKEN) private window: Window) {
    this.networkStatus$ = new BehaviorSubject<NetworkStatus>(this.window?.navigator?.onLine ? 'online' : 'offline');
    this.isOffline$ = this.networkStatus$.pipe(map((value: NetworkStatus) => value === 'offline'));
    fromEvent(this.window, 'online').subscribe(() => this.networkStatus$.next('online'));

    fromEvent(this.window, 'offline').subscribe(() => this.networkStatus$.next('offline'));

    this.setupNetworkType();
  }

  /**
   * ensure that the connection object exists in navigator first before doing anything
   * @private
   */
  private setupNetworkType(): void {
    if (!this.window?.navigator['connection']) {
      return;
    }

    this.networkType$.next((this.window.navigator['connection'] as any).effectiveType);

    fromEvent(this.window.navigator['connection'], 'change').subscribe(() =>
      this.networkType$.next((this.window.navigator['connection'] as any).effectiveType),
    );
  }
}
