<div id="mainContent" tabindex="0" class="main-container container has-header">
  <div class="row container-inner">
    <div class="col-sm-12">
      <app-application-page
        [index]="0"
        [validate]="false"
        [text]="messages.applicationStatusSlide"
        [applicationInfo]="true"
        [orderInfo]="true"
        [showSaveAndContinue]="false"
        [showProgressWizard]="false"
      >
        <app-tg-application-confirmation #innerSlide [isOrderStatus]="true"></app-tg-application-confirmation>
      </app-application-page>
    </div>
  </div>
</div>
