import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentLoaderComponent } from './content-loader/content-loader.component';
import { ContentHostDirective } from './content-host.directive';
import { contentComponents } from './content-components';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CtaButtonComponent } from './content-components/cta-button/cta-button.component';

@NgModule({
  declarations: [ContentLoaderComponent, ContentHostDirective, ...contentComponents, CtaButtonComponent],
  exports: [ContentLoaderComponent, ...contentComponents, CtaButtonComponent],
  imports: [CommonModule, SlickCarouselModule],
})
export class ContentModule {}
