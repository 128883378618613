<div class="application-slide row">
  <ng-container *ngIf="showCart">
    <div class="cart-wrapper text-end">
      <app-tg-cart-icon #cartIcon (continue)="onNext()" (updatedCarts)="onCartRefresh($event)"></app-tg-cart-icon>
    </div>
  </ng-container>

  <div *ngIf="showProgressWizard" class="col-12 col-lg-4">
    <div class="app-slide-progress" [ngClass]="{ 'progress-cart-margin': showCart }">
      <app-vertical-progress-wizard #progressCtrl></app-vertical-progress-wizard>
      <button 
        *ngIf="showSaveAndContinue && isNotPrefilled()" type="button" 
        class="xup-secondary-button mobile-btn-width d-none d-lg-flex" (click)="onSecondaryAction()" tabindex="0"
      >
        Save & Continue Later
      </button>
    </div>
  </div>

  <div
    class="col-12 col-lg-8 px-0 mx-auto"
    id="appSlide"
    tabindex="0"
    [ngClass]="{ 'app-container': !showProgressWizard }"
  >
    <div class="app-slide-flex-container">
      <div class="row app-slide-flex-container-row">
        <div *ngIf="hasHeadline()" class="app-slide-heading-container">
          <h1 class="app-slide-subheading">
            {{ smallHeadline }}
            <app-tg-tooltip *ngIf="tooltipTitleText !== ''" [tooltipText]="tooltipTitleText"></app-tg-tooltip>
          </h1>
          <div *ngIf="text.subText" class="app-slide-heading">{{ text.subText }}</div>
          <div *ngIf="text.noteText" class="app-slide-heading app-slide-heading-note">{{ text.noteText }}</div>
          <div *ngIf="orderInfo" class="app-slide-heading app-slide-order-number">Order #{{ orderId() }}</div>
        </div>

        <div class="app-slide-step-tracker" *ngIf="stepTrackerConfig">
          <app-step-tracker [config]="stepTrackerConfig"></app-step-tracker>
        </div>

        <div class="app-slide-content">
          <ng-content></ng-content>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col col-offset-6 text-danger fs-6 text-center" *ngIf="network.isOffline$ | async">
        <p>You are currently offline, please check your device's network status.</p>
      </div>
    </div>

    <div class="d-none d-lg-flex align-items-top mb-3" [ngClass]="{ 'justify-content-between': hasBackButton(), 'justify-content-end': !hasBackButton()}">
      <button
        [tooltip]="getBackButtonTooltipText()"
        [tooltipEnable]="hasBackTootltip()"
        triggers="mouseenter:mouseleave"
        *ngIf="hasBackButton()"
        class="xup-secondary-button"
        [disabled]="isBackDisabled()"
        (click)="onPrev()"
      >
        {{ text.backText }}
      </button>

      <button
        *ngIf="hasNextButton()"
        type="button"
        class="xup-primary-button"
        [disabled]="isNextDisabled()"
        [class.xup-button-loading]="loading"
        (click)="onNext()"
      >
        <label>{{ continueText ? continueText : text.nextText }}</label>
        <i class="fas fa-spinner-third fa-spin loading-icon" *ngIf="loading"></i>
      </button>
    </div>
    <div class="d-flex d-lg-none flex-column align-items-center align-items-top mb-3">
      <button
        *ngIf="hasNextButton()"
        type="button"
        class="xup-primary-button mobile-btn-width mb-3"
        [disabled]="isNextDisabled()"
        [class.xup-button-loading]="loading"
        (click)="onNext()"
      >
        <label>{{ continueText ? continueText : text.nextText }}</label>
        <i class="fas fa-spinner-third fa-spin" *ngIf="loading"></i>
      </button>
      <button
        [tooltip]="getBackButtonTooltipText()"
        [tooltipEnable]="hasBackTootltip()"
        triggers="mouseenter:mouseleave"
        *ngIf="hasBackButton()"
        class="xup-secondary-button mobile-btn-width mb-3"
        [disabled]="isBackDisabled()"
        (click)="onPrev()"
      >
        {{ text.backText }}
      </button>
      <button 
        *ngIf="!loading && showSaveAndContinue && isNotPrefilled()" type="button" 
        class="xup-secondary-button mobile-btn-width" (click)="onSecondaryAction()" tabindex="0"
      >
        Save & Continue Later
      </button>
    </div>
    <div class="row d-md-flex justify-content-between align-items-top" *ngIf="hasSubmitError">
      <div class="col-sm-12">
        <div class="col col-sm-12">
          <p class="app-slide-submit-error">{{ text?.submitError }}</p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="hasFootNote()" class="row app-slide-footer-disclosure">
    <div class="col-sm-12">
      <p [innerHTML]="text.footNote"></p>
    </div>
  </div>
</div>
