import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormCarouselSlide } from '@eventhorizon/components/form-carousel-slide';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SavePopupComponent } from '@eventhorizon/components/save-popup/save-popup.component';
import { ApplicationPageComponent } from '../application-page/application-page.component';
import { ProductShippingLocationsBaseComponent } from '../product-shipping-locations-base/product-shipping-locations-base.component';
import { IProductShippingLocationForm } from '@eventhorizon/controllers/product-shipping-locations.controller';

@Component({
  selector: 'app-tg-product-shipping-locations',
  templateUrl: './product-shipping-locations.component.html',
  styleUrls: ['./product-shipping-locations.component.scss'],
})
export class ProductShippingLocationsComponent extends FormCarouselSlide<FormGroup<IProductShippingLocationForm>> {
  @ViewChild('shippingLocationsBase')
  protected shippingLocationsBase: ProductShippingLocationsBaseComponent;

  constructor(
    protected router: ActivatedRoute,
    protected cd: ChangeDetectorRef,
    protected bsModalService: BsModalService,
    protected fb: FormBuilder,
    private applicationPageComponent: ApplicationPageComponent,
  ) {
    super(bsModalService, cd);
  }

  public refreshForm(form: FormGroup<IProductShippingLocationForm>): void {
    this.form = form;
  }

  public async onSecondaryAction() {
    if (this.form.valid) {
      await this.shippingLocationsBase.save();
      this.applicationPageComponent.loading = false;
    }
    this.bsModalService.show(SavePopupComponent, {
      backdrop: 'static',
      ariaLabelledBy: 'modal-title modal-subtitle',
    });
  }

  public async preOnNext(): Promise<boolean> {
    return this.shippingLocationsBase.save();
  }
}
