<div class="row">
  <div *ngIf="isLoading" class="col-sm-12">
    <div class="lds-dual-ring"></div>
  </div>
  <ng-container *ngIf="!isLoading">
    <div class="row p-0">
      <div class="col-12 col-md-4" *ngFor="let product of products">
        <app-software-option [product]="product"></app-software-option>
      </div>
      <div class="col-12">
        <small *ngIf="form?.invalid && form?.touched" class="software-error">Required</small>
      </div>
    </div>
    <div *ngIf="contentService?.bankInfoConfig?.showSoftwareLinks" class="d-flex justify-content-center">
      <span class="link">
        <a class="link-a" target="_blank" href="./assets/images/KMS-software-solutions-payments.png"
          >View Software Comparison ></a
        >
      </span>
    </div>
  </ng-container>
</div>
