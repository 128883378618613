<app-container *mobxAutorun>
  <app-application-page
    [index]="1"
    [subIndex]="0"
    [showCart]="true"
    (prev)="prev()"
    (next)="next()"
  >
    <app-tg-product-search #innerSlide></app-tg-product-search>
  </app-application-page>
</app-container>
