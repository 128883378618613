<div class="row transaction-container" *ngIf="!keepRatesOpen">
  <div class="d-none d-md-block col-md-1 transaction-image">
    <i class="far fa-credit-card"></i>
  </div>
  <div class="col-6 product-name">
    <span>{{ title }} </span>
    <app-tg-tooltip [tooltipText]="tooltipText" class="tool-tip-icon"></app-tg-tooltip>
  </div>
  <div class="col-6 col-md-5 text-end">
    <button class="transparent-button rates-button" (click)="toggleShowRates()">
      View all rates and fees
      <i class="fas rates-button-icon" [ngClass]="{ 'fa-caret-right': !showRates, 'fa-caret-down': showRates }"></i>
    </button>
  </div>
</div>
<app-rates-fees
  #ratesNFees
  [showRates]="showRates"
  [isSwiped]="isSwiped"
  [ratesAndFees]="ratesAndFees"
></app-rates-fees>
<div class="row">
  <div class="col-12 rates-container" *ngIf="showRates || keepRatesOpen">
    <table ng-table="rfTable" class="table table-striped table-sm table-hover" *ngIf="ratesAndFees.length > 0">
      <tbody>
        <th *ngFor="let header of headers">
          {{ header }}
        </th>
        <tr
          *ngFor="let rf of ratesAndFees; let i = index"
          [tooltip]="rf.longDescription"
          placement="top"
          container="body"
          containerClass="tool-tip-text"
        >
          <td>{{ ratesAndFeesGroup[i].groupDisplayName }}</td>
          <td>{{ rf.rateDefault }}%</td>
          <td>
            {{ rf.feeDefault | currency: 'USD':'symbol-narrow':'1.2-10' }}<span *ngIf="showMonth(rf)">/month</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row transaction-container" *ngIf="!isSwiped && !appStore.hasGroupedAuthFees">
  <div class="col-1 transaction-image">
    <i class="fab fa-cc-visa"></i>
  </div>
  <div class="col-11 product-name">
    <span>Payment Types </span>
    <app-tg-tooltip  class="tool-tip-icon"
      [tooltipText]="'All payments and card type your business will be able to accept with your merchant solution.'"
    ></app-tg-tooltip>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col-11 offset-1 p-0">
        <ul class="payments-list">
          <li class="payments-list-details" *ngFor="let ap of acquiringProducts">
            <i class="far fa-circle-plus"></i>{{ ap.productName }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="errorMessage" class="col-12">
    <p>{{ errorMessage }}</p>
  </div>
</div>
