import {
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Directive,
  OnDestroy,
} from '@angular/core';
import { FormGroup, FormRecord, UntypedFormGroup } from '@angular/forms';

@Directive()
//Eventualy UntypedFormGroup should be replaced for a FormGroup type
export class CarouselSlide<FormType extends FormTypes = UntypedFormGroup> implements AfterViewInit, OnDestroy {
  public isOpen = false;

  public form: FormType;

  public tooltipBackButtonText: string | null = null;

  @ViewChild('focus') public focus: ElementRef;

  @ViewChild('formElem') public formElem: ElementRef;

  private destroyed: boolean = false;

  constructor(protected cd: ChangeDetectorRef) {}

  public validate() {}

  public onSubmit(): boolean {
    return true;
  }

  public onOpen() {
    this.onDetectChanges();
    this.isOpen = true;
  }

  ngAfterViewInit() {
    if (this.focus) {
      this.focus.nativeElement.focus();
    }
    this.onDetectChanges();
  }

  public ngOnDestroy(): void {
    this.destroyed = true;
  }

  /**
   * Check if the view hasn't been destroyed and detect changes.
   */
  private onDetectChanges(): void {
    if (!this.destroyed) {
      this.cd.detectChanges();
    }
  }

  /**
   * Slides an override any pre-processing for the prev button
   *
   * @returns FALSE to _STOP_ propogation of the next event
   */
  public async preOnPrev(): Promise<boolean> {
    return true;
  }

  /**
   * Slides an override any pre-processing for the next button
   *
   * @returns FALSE to _STOP_ propogation of the next event
   */
  public async preOnNext(): Promise<boolean> {
    return true;
  }

  // Not normally overridden but needed in some cases where
  //  the inner component of a slide needs to control the action
  // TODO: SlideComponent also has this shit.. can we consolidate
  @Output()
  public prev = new EventEmitter<void>();

  @Output()
  public next = new EventEmitter<void>();

  public onPrev() {
    this.prev.emit();
  }

  public onNext() {
    if (this.onSubmit()) {
      this.next.emit();
    }
    this.isOpen = false;
  }

  public onSecondaryAction() {}

  public isNextDisabled(): boolean {
    return false;
  }

  public hasBackTootltip(): boolean {
    return false;
  }

  public isBackDisabled(): boolean {
    return false;
  }
}

export type FormTypes = UntypedFormGroup | FormGroup | FormRecord;
