<div class="modal-dialog">
  <div class="text-center prefill-content">
    <div class="modal-body">
      <h1 class="base-h1 mb-3 mt-4">We pre-filled your application</h1>
      <p class="mb-5">
        To speed things up, we filled in some of your application ahead of time. Please confirm what we have input is
        accurate and enter any missing information to complete your application and start accepting payments.
      </p>
      <div class="row justify-content-center mb-4">
        <div class="col-sm-4">
          <button class="xup-primary-button" (click)="close()">Continue</button>
        </div>
      </div>
    </div>
  </div>
</div>
