<div class="tg-ocd-container">
  <div class="tg-ocd-details">
    <div class="row mb-4">
      <div class="tg-ocd-number col-sm-5">{{ strings.orderNumber + orderDetails.orderId }}</div>
      <div class="col-sm-7 tg-ocd-msg" [ngClass]="{ 'tg-ocd-msg-error': !!cancelledOrder }">{{ title }}</div>
    </div>
    <div *ngIf="!cancelledOrder" class="tg-ocd-details-expand row">
      <button class="col btn btn-link right-align" (click)="expandDetails()">
        {{ strings.viewDetails }}&nbsp;&nbsp;<i
          class="tg-ocd-details-expand-icon fas fa-caret-right"
          [ngClass]="{ 'tg-ocd-details-expand-icon-active': showOrder }"
        ></i>
      </button>
    </div>
  </div>
  <div [hidden]="!showOrder" class="tg-ocd-order">
    <app-tg-cart 
      [locations]="orderDetails.businessLocations" 
      [locationCarts]="orderDetails.carts"
      [allowTaxEstimation]="false">
    </app-tg-cart>
  </div>
</div>
