import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';
import { ApplicationStore } from '@eventhorizon/stores/application.store';
import { Page } from '@eventhorizon/models/page.model';
import { CampaignInformation } from '@eventhorizon/models/referral.model';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-content-page',
  templateUrl: './content-page.component.html',
  styleUrls: ['./content-page.component.scss'],
})
export class ContentPageComponent implements OnInit {
  public page: Page;

  public pageBackground: SafeStyle;

  private template: string;

  constructor(
    private appStore: ApplicationStore,
    private contentService: ContentServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
  ) {
    this.saveCampaignInfo();
  }

  private saveCampaignInfo() {
    let url = new URL(decodeURIComponent(window.location.origin + this.router.url));
    const campaignInformation: CampaignInformation = {
      externalCustomerId: url.searchParams.get('externalCustomerId'),
      sourceType: url.searchParams.get('sourceType'),
      sourceId: url.searchParams.get('sourceId'),
      utmMedium: url.searchParams.get('utm_medium'),
      utmSource: url.searchParams.get('utm_source'),
      utmCampaign: url.searchParams.get('utm_campaign'),
      utmTerm: url.searchParams.get('utm_term'),
    };
    this.appStore.updateCampaignInformation(campaignInformation);
  }

  ngOnInit() {
    this.template = this.route.snapshot.url[0].path;
    this.loadPage();
  }

  private loadPage(): void {
    lastValueFrom(this.contentService.getLandingPage(this.template))
      .then((res: Page) => {
        if (res) {
          this.page = res;
          if (this.page.backgroundImageUrl) {
            this.pageBackground = this.sanitizer.bypassSecurityTrustStyle(`url(${this.page.backgroundImageUrl})`);
          }
        } else {
          this.router.navigate(['404']);
        }
      })
      .catch(() => {
        this.router.navigate(['404']);
      });
  }
}
