<app-container *mobxAutorun>
  <app-application-page [text]="text" [stepTrackerConfig]="stepTrackerConfig" (prev)="prev()" (next)="next()">
    <app-onboarding-location-information
      #innerSlide
      (prev)="prev()"
      (next)="next()"
    ></app-onboarding-location-information>
  </app-application-page>
</app-container>

<ng-template #unsavedChangesRef>
  <p class="unsaved-changes-info">
    Are you sure? This action will not save any changes made in this form.
  </p>
</ng-template>