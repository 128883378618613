<app-container *mobxAutorun>
  <app-application-page
    [index]="3"
    [subIndex]="2"
    [width]="'col-md-6'"
    [text]="messages.identityVerificationSlide"
    (prev)="prev()"
    (next)="next()"
  >
    <app-tg-verification #innerSlide [accordionConfig]="accordionConfig" (prev)="prev()" (next)="next()"></app-tg-verification>
  </app-application-page>
</app-container>
