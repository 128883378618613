<app-container *mobxAutorun>
  <app-application-page
    [index]="1"
    [subIndex]="0"
    [validate]="true"
    [text]="pageMessage"
    [showCart]="true"
    (prev)="prev()"
    (next)="next()"
  >
    <app-tg-additional-products
      #innerSlide
      (prev)="prev()"
      (next)="next()"
      (onPinpadDetected)="setText($event)"
    ></app-tg-additional-products>
  </app-application-page>
</app-container>
