import { Component, ViewEncapsulation } from '@angular/core';
import { ContentServiceService } from '@eventhorizon/services/content-service.service';

@Component({
  selector: 'app-disclosure',
  templateUrl: './disclosure.component.html',
  styleUrls: ['./disclosure.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DisclosureComponent {
  constructor(public contentService: ContentServiceService) {}
}
