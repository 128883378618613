import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',

})
export class MessagesService {
  public errors: Record<string, string>;

  private status: 'Idle' | 'Loading' | 'Loaded' | 'Error' = 'Idle';

  constructor(private http: HttpClient) { }

  public async load(force = false) {
    if (this.status === 'Idle' || force) {
      this.status = 'Loading';
      try {
        this.errors = await lastValueFrom(this.http.get<Record<string, string>>('/assets/errors.json'));
        this.status = 'Loaded';
      } catch (error) {
        this.status = 'Error';
        return Promise.reject(error);
      }
    }
  }

}
