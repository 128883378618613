<app-container *mobxAutorun>
  <app-application-page
    id="contact-info"
    [index]="2"
    [subIndex]="0"
    [text]="messages"
    [showCart]="showCart"
    [showSaveAndContinue]="false"
    (prev)="prev()"
    (next)="next()"
  >
    <app-contact-info #innerSlide (prev)="prev()" (next)="next()"></app-contact-info>
  </app-application-page>
</app-container>
