import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormErrorService {
  removeErrorFromControl(error: string, form: UntypedFormGroup, control: string) {
    const { errors } = form.controls[control];
    if (!errors) return;
    delete errors[error];
    const numberOfErrors = Object.keys(errors).length;
    form.controls[control].setErrors(numberOfErrors ? errors : null);
  }

  addErrorToControl(error: string, form: UntypedFormGroup, control: string) {
    const errors = form.controls[control].errors || {};
    errors[error] = true;
    form.controls[control].setErrors(errors);
    // Force errors refresh in UI
    setTimeout(() => {
      form.updateValueAndValidity();
    }, 100);
  }
}
