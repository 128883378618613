<div class="row">
  <div *ngIf="isLoading" class="col-sm-12">
    <div class="lds-dual-ring"></div>
  </div>
  <div
    *ngFor="let product of products"
    class="product-search-product-list col-md-4 mb-3"
    [class.product-search-product-list-error]="
      form?.controls?.additionalProduct?.invalid && form?.controls?.additionalProduct?.touched
    "
  >
    <app-tg-product [product]="product"></app-tg-product>
  </div>
</div>
