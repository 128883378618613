export const contentComponentsConfig = {
  iconList: 'icon-list/icon-list.component',
  markdownBlock: 'markdown/markdown.component',
  carousel: 'slide-carousel/slide-carousel.component',
  hero: 'page-hero/page-hero.component',
  iconTextBlock: 'icon-text-block/icon-text-block.component',
  structuredTextBlock: 'structured-text-block/structured-text-block.component',
  link: 'link/link.component',
  image: 'image/image.component',
  buttonSet: 'button-set/button-set.component',
  interactiveImage: 'interactive-image/interactive-image.component',
};
