<div *ngIf="isLoading" class="text-center">
  <p class="searching">Verifying <span>.</span><span>.</span><span>.</span></p>
</div>
<div *ngIf="!isLoading && !errorMessage">
  <app-merchant-summary
    [applicationId]="applicationId"
    [config]="summaryConfig"
    [enableItemClick]="isItemClickEnabled()"
    [accordionConfig]="accordionConfig"
  ></app-merchant-summary>
</div>
<div *ngIf="errorMessage" class="form-error-message error-message text-center">
  {{ errorMessage }}
</div>
